import { Box, Typography } from '@mui/material'
import { StartHome } from '../../../assets/svgs/StartHome'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { ArrowRightAlt } from '@mui/icons-material'
import { AnnualPlanStatus } from '../../../models/AnnualPlanStatus'
import { useState } from 'react'
import { AnnualPlanningTeamModal } from './AnnualPlanningTeamModal'
import { StartAnnualPlan } from '../../../services/AnnualPlanService'

interface Props {
  annualPlanId: number
  annualPlanStatus: AnnualPlanStatus
  rcnId: number
  onStartPlan: () => void
}

export const AnnualPlanGettingStarted = (props: Props): JSX.Element => {
  const [showPlanTeamModal, setShowPlanTeamModal] = useState(false)

  const handleStartAnnualPlan = (): void => {
    setShowPlanTeamModal(true)
  }

  const onCloseModal = (): void => {
    setShowPlanTeamModal(false)
  }

  const onPlanTeamSubmit = async (): Promise<void> => {
    await StartAnnualPlan(props.annualPlanId, props.rcnId)
    setShowPlanTeamModal(false)
    props.onStartPlan()
  }

  return (
    <Box data-testid="annualPlanGettingStarted">
      <StartHome
        style={{
          position: 'relative',
          width: '100%',
          height: 'fit-content',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px'
        }}
      />

      <Box sx={{ padding: '32px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography variant='h5' mb='8px' fontWeight='700'>Getting Started</Typography>

        <Typography variant='h6'>
          RCN Purpose
        </Typography>
        <Typography variant='body1'>
          The RCN are the mechanism for promoting systems-level change in education for students with autism and related disabilities in Michigan. By collaborating with stakeholders in their region and linking with the START project, RCN offer locally accessible information, training, resources, and targeted support that meet the needs defined in that region of the state.
        </Typography>
        <Typography variant='body1'>
          The primary focus of each START RCN Contract is to build training, coaching, and peer to peer support capacity within each region to establish a statewide infrastructure for supporting students with ASD in their schools and communities. Completion of the RCN application provides the structure to guide and monitor progress towards meeting these goals.
        </Typography>
        <Typography variant='body1' fontWeight={600}>
          Connection and collaboration is the goal, not paperwork and compliance. We can achieve more together than we can on our own.
        </Typography>
      </Box>

      <Box sx={{
        m: '0 32px',
        p: '32px',
        border: '1px solid var(--blue-300)',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
      }}
      >
        <Box display='flex' flexDirection='column' gap='8px'>
          <Typography variant='h6' fontWeight='600' color='var(--blue-700)'>
            START's Compelling Why
          </Typography>
          <Typography variant='body1'>
            Ensuring equity, because all students need to be taught in inclusive settings with peers by caring staff prepared to deliver evidence-based educational and behavioral supports.
          </Typography>
        </Box>

        {props.annualPlanStatus === AnnualPlanStatus.NotStarted && (
          <BlueButton sx={{ width: 'fit-content' }} onClick={handleStartAnnualPlan} dataTestId='startPlanButton'>
            Start Annual Plan <ArrowRightAlt />
          </BlueButton>
        )}
      </Box>

      { showPlanTeamModal &&
        <AnnualPlanningTeamModal
          closeModal={onCloseModal}
          onSubmit={onPlanTeamSubmit}
          annualPlanId={props.annualPlanId}
          requireAllRoles={true}
        />
      }
    </Box>
  )
}
