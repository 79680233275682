import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { type RCNPD } from '../../../models/RCNPD'
import { Delete, Edit, ExpandCircleDown } from '@mui/icons-material'
import { useState } from 'react'

export interface BasicTableColumn {
  id: string
  label: string
  minWidth?: number
  align?: 'right' | 'left' | 'center'
}

interface Props {
  columns: BasicTableColumn[]
  rows: any[]
  isLoading: boolean
  onEdit: (pd: RCNPD) => void
  onDelete: (pd: RCNPD) => void
  dataTestId?: string
}

export const BasicTable = (props: Props): JSX.Element => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  const handleExpandClick = (rowId: number): void => {
    setExpandedRow(rowId === expandedRow ? null : rowId)
  }

  return (
    <TableContainer data-testid={props.dataTestId ?? 'basicTable'} sx={{ overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            {props.columns.map(column => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map(row => {
            const isExpanded = expandedRow === row.id
            return <TableRow data-testid={props.dataTestId != null ? `${props.dataTestId}Row` : 'basicTableRow'} hover={expandedRow === row.id} tabIndex={-1} key={row.id}>
              {props.columns.map(column => {
                const value = row[column.id]
                return <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    whiteSpace: isExpanded ? 'normal' : 'nowrap',
                    overflow: 'hidden',
                    textOverflow: isExpanded ? 'clip' : 'ellipsis',
                    maxWidth: column.minWidth
                  }}
                >
                  {value}
                </TableCell>
              })}
              <TableCell>
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    data-testid='editTrainingButton'
                    sx={{ color: 'var(--grey-700)' }}
                    onClick={() => { props.onEdit(row) }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    data-testid='deleteTrainingButton'
                    sx={{ color: 'var(--grey-700)' }}
                    onClick={() => { props.onDelete(row) }}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    data-testid='expandTrainingButton'
                    sx={{
                      color: 'var(--grey-700)',
                      transform: expandedRow === row.id ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out'
                    }}
                    onClick={() => { handleExpandClick(row.id) }}
                  >
                    <ExpandCircleDown />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
