import { type Response, sendGet, sendPost } from '../hooks/use-fetch'
import { CommentParentType, type NewCommentValues, type UpdateCommentValues, type CommentModel, type NewPlanOrgTaskCommentValues, type RecentCommentsResponseModel } from '../models/Comment'

export async function GetComments (parentId: number, parentType: CommentParentType): Promise<CommentModel[]> {
  let url = ''

  switch (parentType) {
    case CommentParentType.PlanOrganizationTask:
      url = `Comment/GetCommentsForPlanOrgTask?planOrganizationTaskId=${parentId}`
      break
    case CommentParentType.Comment:
      url = 'Comment/GetComments'
      break
  }
  const { response } = await sendGet(url)
  return response
}

export async function GetCommentDetails (commentId: string): Promise<CommentModel> {
  const { response } = await sendGet(`Comment/GetCommentDetails?commentId=${commentId}`)
  return response
}

export async function AddComment (comment: NewCommentValues): Promise<Response> {
  let planOrgTaskComment: NewPlanOrgTaskCommentValues

  switch (comment.parentType) {
    case CommentParentType.PlanOrganizationTask:
      planOrgTaskComment = {
        planOrganizationTaskId: comment.parentId,
        commentText: comment.commentText,
        taggedUserEmails: comment.taggedUserEmails
      }
      return await sendPost('Comment/AddPlanOrgTaskComment', planOrgTaskComment)
    case CommentParentType.Comment:
      return await sendPost('Comment/AddComment', comment)
  }
}

export async function UpdateComment (comment: UpdateCommentValues): Promise<Response> {
  return await sendPost('Comment/UpdateComment', comment)
}

export async function DeleteComment (commentId: number): Promise<Response> {
  return await sendPost(`Comment/DeleteComment?commentId=${commentId}`, {})
}

export async function GetRecentCommentsForAnnualPlan (annualPlanId: number): Promise<RecentCommentsResponseModel> {
  const { response } = await sendGet(`Comment/GetRecentCommentsForAnnualPlan?annualPlanId=${annualPlanId}`)
  return response
}
