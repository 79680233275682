import { useState } from 'react'
import { type PlanOrganizationTaskStatus, type PlanOrganizationTask } from '../models/PlanOrganizationTask'
import { UpdatePlanOrganizationTaskStatus } from '../services/AnnualPlanService'
import { toast } from 'react-toastify'

interface UserManagementProps {
  intitialPlanOrgTask: PlanOrganizationTask
  onPlanOrgTaskUpdate?: (updatedTask: PlanOrganizationTask) => void
}

interface PlanOrgTaskManagementType {
  task: PlanOrganizationTask
  isUpdating: boolean
  hasChanges: boolean
  setHasChanges: (hasChanges: boolean) => void
  updatePlanOrgTaskStatus: (newStatus: PlanOrganizationTaskStatus) => void
}

export const usePlanOrgTaskManagement = (props: UserManagementProps): PlanOrgTaskManagementType => {
  const [planOrganizationTask, setPlanOrganizationTask] = useState<PlanOrganizationTask>(props.intitialPlanOrgTask)
  const [isUpdating, setIsUpdating] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  const updatePlanOrgTaskStatus = (newStatus: PlanOrganizationTaskStatus): void => {
    const asyncUpdatePlanOrgTaskStatus = async (newStatus: PlanOrganizationTaskStatus): Promise<void> => {
      setIsUpdating(true)

      const { success, errors } = await UpdatePlanOrganizationTaskStatus(planOrganizationTask.id, newStatus)

      if (!success) {
        if (errors.length > 0) errors.forEach((e) => toast.error(e))
        else toast.error('An error occurred while updating the status.')
      } else {
        setHasChanges(false)

        const updatedTask = { ...planOrganizationTask, status: newStatus }
        setPlanOrganizationTask(updatedTask)
        setHasChanges(false)
        props.onPlanOrgTaskUpdate?.(updatedTask)
      }

      setIsUpdating(false)
    }

    void asyncUpdatePlanOrgTaskStatus(newStatus)
  }

  return {
    task: planOrganizationTask,
    isUpdating,
    hasChanges,
    setHasChanges,
    updatePlanOrgTaskStatus
  }
}
