import { Box, InputAdornment, TextField } from '@mui/material'
import { Modal } from '../../customComponents/Modal'
import { type SyntheticEvent, useEffect, useState } from 'react'
import { type FieldError } from '../../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { AddMeeting, UpdateMeeting } from '../../../services/AnnualPlanService'
import type { AddOrEditAnnualPlanMeetingRequest } from '../../../models/requestObjects/AnnualPlanRequestObjects'
import { LocationSuggestBox } from '../../customComponents/bingmaps/LocationSuggestBox'
import { MapStateName, type SelectOption } from '@oaisd/michdev.components.react'
import type { Address } from '../../../models/Address'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'
import type { AnnualPlanTeamMember } from '../../../models/AnnualPlanTeamMember'
import { DatePickerWithLabel } from '../../customComponents/DatePickerWithLabel'

interface Props {
  orgId: number
  annualPlanId: number
  editMeeting?: AddOrEditAnnualPlanMeetingRequest
  teamMembers: AnnualPlanTeamMember[]
  onClose: (closeReason: string) => void
}

export function AddMeetingModal (props: Props): JSX.Element {
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [contactOptions, setContactOptions] = useState<Array<{ id: string | number, name: string }>>([])
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState<SelectOption<number> | null>()
  const [isLoading, setIsLoading] = useState(true)

  const [selectedLocation, setSelectedLocation] = useState<Address>()
  const emptyAddress: Address = {
    id: 0,
    street: '',
    city: '',
    state: '',
    zip: '',
    apartment: undefined
  }
  const [editingAddress, setEditingAddress] = useState<Address>(emptyAddress)

  const [formValues, setFormValues] = useState<AddOrEditAnnualPlanMeetingRequest>(props.editMeeting ?? {
    annualPlanId: props.annualPlanId,
    orgId: props.orgId,
    title: '',
    description: '',
    meetingDate: undefined,
    budgetRequestAmount: '',
    primaryContactId: undefined,
    location: emptyAddress
  })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const teamOptions: Array<{ id: string | number, name: string }> = []
      props.teamMembers.forEach(tm => {
        if (tm.annualPlanRole === AnnualPlanRole.PlanAdministrator || tm.annualPlanRole === AnnualPlanRole.PlanContributor) {
          teamOptions.push({ id: tm.userId, name: `${tm.firstName} ${tm.lastName}` })
        }
      })
      setContactOptions(teamOptions)
      if (props.editMeeting != null) {
        setEditingAddress(props.editMeeting.location)
        setSelectedLocation(props.editMeeting.location)
        const primaryContact: number = Number(props.editMeeting.primaryContactId)
        const selected = teamOptions.find(filt => filt.id === primaryContact)
        if (selected != null) {
          setSelectedPrimaryContact({ id: Number(selected.id), name: selected.name })
        }
      }
      setIsLoading(false)
    }

    void fetchData()
  }, [props.editMeeting])

  const handleSave = async (): Promise<void> => {
    if (!validateForm()) {
      toast.error('Please complete all required fields.')
      return
    }

    const completedForm: AddOrEditAnnualPlanMeetingRequest = { ...formValues, location: selectedLocation ?? emptyAddress }
    if (props.editMeeting != null) {
      await saveEditedMeeting(completedForm)
    } else {
      await saveAddMeeting(completedForm)
    }
  }

  async function saveAddMeeting (completedForm: AddOrEditAnnualPlanMeetingRequest): Promise<void> {
    const { success, errors } = await AddMeeting(completedForm)
    if (!success) {
      errors.forEach((error) => {
        toast.error(error)
      })
      return
    }
    props.onClose('saved')
  }

  async function saveEditedMeeting (completedForm: AddOrEditAnnualPlanMeetingRequest): Promise<void> {
    const { success, errors } = await UpdateMeeting(completedForm)
    if (!success) {
      errors.forEach((error) => {
        toast.error(error)
      })
      return
    }
    props.onClose('updated')
  }

  function validateForm (): boolean {
    const formFieldErrors: FieldError[] = []

    if (formValues.title === '') {
      formFieldErrors.push({ fieldName: 'title', message: '' })
    }

    if (formValues.meetingDate == null) {
      formFieldErrors.push({ fieldName: 'meetingDate', message: '' })
    }

    if (selectedLocation == null || (selectedLocation.street === '' && selectedLocation.zip === '')) {
      formFieldErrors.push({ fieldName: 'address', message: '' })
    }

    if (formValues.primaryContactId == null) {
      formFieldErrors.push({ fieldName: 'primaryContactId', message: '' })
    }

    if (formValues.budgetRequestAmount == null) {
      formFieldErrors.push({ fieldName: 'budgetRequestAmount', message: '' })
    }

    setFieldErrors(formFieldErrors)
    if (formFieldErrors.length > 0) {
      return false
    }

    return true
  }

  const handleFieldUpdate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const handleContactChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    const contact = contactOptions.find(d => d.id === value?.id) ?? null

    if (contact == null) {
      return
    }

    setSelectedPrimaryContact({ id: Number(contact.id), name: contact.name })

    const selected: { id: number, name: string } = { id: Number(contact?.id), name: contact?.name }
    setFormValues({
      ...formValues,
      primaryContactId: selected.id
    })
  }

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: Address = {
      id: 0,
      street: place.address.addressLine,
      city: place.address.locality,
      apartment: '',
      state: MapStateName(place.address.adminDistrict),
      zip: place.address.postalCode
    }
    setEditingAddress(newplace)
    setSelectedLocation(newplace)
  }

  const handleLocChange = (e: any): void => {
    setEditingAddress({ ...editingAddress, street: e.target.value })
  }

  const content = <Box minWidth='200px' >
    <TextField
      data-testid='meetingTitle'
      fullWidth
      value={formValues.title}
      onChange={handleFieldUpdate}
      placeholder='Name of the meeting'
      label={'Meeting Title'}
      name={'title'}
      required
      error={fieldErrors.some((error) => error.fieldName === 'title')}
    />

    <TextField
      data-testid='meetingDescription'
      fullWidth
      multiline
      value={formValues.description}
      minRows={3}
      name='description'
      label='Meeting Description'
      onChange={handleFieldUpdate}
      sx={{ mt: '.75em' }}
    />

    <DatePickerWithLabel
      includeTime
      disablePast
      FormControlProps={{ variant: 'outlined', sx: { marginTop: '.75em', width: '250px' } }}
      name='meetingDate'
      label='Meeting Date'
      required
      error={fieldErrors.some((error) => error.fieldName === 'meetingDate')}
      dataTestId='meetingDate'
      onChange={(dt) => { setFormValues({ ...formValues, meetingDate: dt ?? undefined }) } }
      sx={{ mt: '.75em' }}
      value={formValues.meetingDate ?? null}
    />

    <Box sx={{ my: '.75em' }}>
      <LocationSuggestBox editData={editingAddress}
        integratedLabel={true}
        error={fieldErrors.some((error) => error.fieldName === 'address')}
        required
        onChange={handleLocChange}
        onPlaceSelected={handlePlaceSelected}
      />
    </Box>

    <MUIAutoComplete
      required
      label='Primary Contact'
      name='memberSelect'
      value={selectedPrimaryContact ?? undefined}
      onChange={handleContactChange}
      options={contactOptions}
      error={fieldErrors.some((error) => error.fieldName === 'primaryContactId')}
      sx={{ width: '100%' }}
    />

    <TextField
      sx={{ mt: '.75em', width: '250px' }}
      onChange={handleFieldUpdate}
      label={'Amount'}
      data-testid='budgetRequestAmount'
      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
      name={'budgetRequestAmount'}
      required
      error={fieldErrors.some((error) => error.fieldName === 'budgetRequestAmount')}
      value={formValues.budgetRequestAmount}
    />

  </Box>

  if (isLoading) {
    return <></>
  }

  return <Modal
    open={true}
    maxWidth='md'
    title={props.editMeeting != null ? 'Edit Meeting' : 'Add Meeting'}
    confirmButtonText='Save'
    bodyContent={content}
    onClose={() => { props.onClose('canceled') }}
    onConfirm={handleSave}
  />
}
