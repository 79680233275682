import {
  FormControl,
  type FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SelectProps,
  Box
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export interface SelectOption<T> { id: T, name: string }

interface Props<T extends string | number | boolean> {
  options: Array<SelectOption<T>>
  value: '' | T | undefined
  onChange: (event: SelectChangeEvent<T>) => void
  name: string
  label?: string
  labelContent?: JSX.Element
  displayEmpty?: boolean
  renderValue?: (value: T) => JSX.Element
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  includeAllOption?: boolean
  allOptionText?: string
  width?: string
  minWidth?: string
  maxWidth?: string
  selectBackgroundColor?: string
  selectProps?: Omit<SelectProps<T>, 'onChange' | 'value' | 'name' | 'required'>
  leftMargin?: string
  integratedLabel?: boolean
}

export function SelectWithLabel<T extends string | number | boolean> (props: Props<T>): JSX.Element {
  const { value, name, options, label, labelContent, onChange } = props
  const currentValue = value
  let boxStyle
  if (props.leftMargin !== undefined && props.leftMargin !== null) {
    boxStyle = { marginLeft: props.leftMargin }
  }

  return (
    <Box sx={boxStyle}>
      { props.integratedLabel !== true && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <InputLabel
          sx={{ color: 'black' }}
          id={name}
          required={props.required === true && props.showRequiredText !== true}
          disableAnimation
          variant="standard"
        >
          {labelContent ?? label}
        </InputLabel>
        {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
        {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}
      </Box>
      }
      <FormControl
        variant="filled"
        {...props.FormControlProps}
        disabled={props.disabled}
        sx={{ pt: 0, width: props.width, minWidth: props.minWidth, maxWidth: props.maxWidth }}
      >
        { props.integratedLabel === true && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <InputLabel
            id={name}
            required={props.required === true && props.showRequiredText !== true}
          >
            {labelContent ?? label}
          </InputLabel>
          {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
          {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}
        </Box>
        }
        <Select
          data-testid={name}
          {...props.selectProps}
          sx={{ backgroundColor: props.selectBackgroundColor }}
          labelId={name}
          id={`${name}-select`}
          name={name}
          value={currentValue}
          displayEmpty={props.displayEmpty}
          renderValue={props.renderValue}
          onChange={onChange}
          required={props.required}
          variant={props.integratedLabel === true ? 'filled' : 'outlined' }
          IconComponent={KeyboardArrowDownIcon}
          error={props.error}
        >
          {props.includeAllOption != null && props.includeAllOption &&
            <MenuItem data-testid='select-item-none' value={''}>{props.allOptionText ?? 'All'}</MenuItem>
          }
          {options?.map((opt) => (
            <MenuItem key={opt.id.toString()} value={opt.id.toString()} role="option" data-testid={'select-item-' + opt.id.toString()}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
