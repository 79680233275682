import { Box, Card, IconButton, Typography } from '@mui/material'
import type { CETATeamMember } from '../../../../models/CETATeamMember'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { AddTeamMemberModal } from '../AddTeamMemberModal'
import { EditOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { LabelWithToolTip } from '../../../customComponents/LabelWithTooltip'

interface Props {
  classroomId: string
  team: CETATeamMember[]
  readonly: boolean
  onTeamChangeSubmitted: () => void
}

export const CETATeamCard = (props: Props): JSX.Element => {
  const purpleBackground: string = 'var(--purple-500)'
  const [showAddTeamMember, setshowAddTeamMember] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editingMember, setEditingMember] = useState<CETATeamMember | undefined>(undefined)

  const onTeamChangeSubmitted = async (): Promise<void> => {
    setshowAddTeamMember(false)
    setEditMode(false)
    props.onTeamChangeSubmitted()
  }

  const editMemberClick = (member: CETATeamMember): void => {
    setshowAddTeamMember(true)
    setEditMode(true)
    setEditingMember(member)
  }

  const initiateAddMember = (): void => {
    setshowAddTeamMember(true)
    setEditMode(false)
  }

  return <>
    <Card key='displayCard' sx={{ backgroundColor: '#F3F3F380', minHeight: '3em', width: '300px', border: '1px solid var(--border)', p: '1em' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5' color='var(--grey-900)'fontWeight='600'>CETA Team</Typography>
        {
          !props.readonly && <AddCircleIcon
            sx={{ fontSize: '2em', color: 'var(--blue-700)', cursor: 'pointer', ml: '1em' }}
            onClick={initiateAddMember}
            data-testid='addMember'
          />
        }
      </Box>
      { !(props.team.length === 0) && props.team.sort((a, b) => Number(b.isLead) - Number(a.isLead)).map((mem) => (
        <Box key={`${mem.firstName}${mem.lastName}boxcontainer`} sx={{ display: 'flex', alignItems: 'center', my: '1em', width: '100%' }}>
          <Box sx={{ width: '75%', display: 'flex', alignItems: 'center' }} data-testid={`${mem.userId}-chip${mem.isLead.toString()}`}>
            <ProfileImage name={`${mem.firstName}${mem.lastName}image`} imageKey={mem.imageKey} backgroundColor={mem.isLead ? purpleBackground : null} />
            <LabelWithToolTip data-testid={`${mem.userId}-namedisplay`} sx={{ marginLeft: '.5em' }} labelText={`${mem.firstName} ${mem.lastName}`}></LabelWithToolTip>
          </Box>

          {!props.readonly &&
            <Box>
              <IconButton sx={{ justifySelf: 'right' }} aria-label="settings" data-testid={`${mem.userId}-editUserInfo`} onClick={() => { editMemberClick(mem) }}>
                <EditOutlined sx={{ fill: 'black' }} />
              </IconButton>
            </Box>
          }
        </Box>
      ))
      }
    </Card>

    {showAddTeamMember && <AddTeamMemberModal editMode={editMode} key='addModal' classroomId={props.classroomId} onSubmit={onTeamChangeSubmitted} closeModal={() => { setshowAddTeamMember(false) } } memberToEdit={editingMember}/>}
  </>
}
