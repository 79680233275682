import { Typography, Box, Card } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { type UserOrganization } from '../../../models/UserOrganization'
import { UserProfileAccordion } from './UserProfileAccordion'
import { type SelectableOrganization } from '../../../models/SelectableOrganization'

interface Props {
  organizations: UserOrganization[]
  orgOptions: SelectableOrganization[]
  readOnly: boolean
  handleDefaultOrgClicked: (orgId: number) => Promise<void>
  handleAddNewOrg: () => void
  handleEditOrg: (org: UserOrganization) => void
  handleRemoveOrg: (org: UserOrganization) => void
  handleBuildingsEdit: (org: UserOrganization) => void
  handleSubscriptionsEdit: (org: UserOrganization) => void
}

export function MemberOrganizationsList (props: Props): JSX.Element {
  return <Card sx={{ p: '1.25em', backgroundColor: '#F3F3F380', border: '1px solid var(--border)' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant='h4' sx={{ fontWeight: '600', textAlign: 'center', fontSize: '1.5em' }}>Organizations</Typography>

      {!props.readOnly && <AddCircleIcon
        sx={{ ml: '0.5em', cursor: 'pointer', color: 'var(--blue-700)' }}
        onClick={props.handleAddNewOrg}
        data-testid='addOrg'
      />}
    </Box>

    {props.organizations.map((org) => (
      <Box key={org.id} sx={{ my: '.625em' }}>
        <UserProfileAccordion
          org={org}
          readOnly={props.readOnly || !props.orgOptions.map(o => o.id).includes(org.id)}
          handleDefaultOrgClicked={props.handleDefaultOrgClicked}
          handleEditOrg={props.handleEditOrg}
          handleRemoveOrg={props.handleRemoveOrg}
          handleBuildingsEdit={props.handleBuildingsEdit}
          handleSubscriptionsEdit={props.handleSubscriptionsEdit}
        />
      </Box>
    ))}

    {props.organizations.length === 0 && <Typography sx={{ textAlign: 'center' }}>No organizations</Typography>}
  </Card>
}
