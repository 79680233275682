import { Switch, type SwitchProps } from '@mui/material'

export const SquareSwitch = (props: SwitchProps): JSX.Element => {
  return <Switch
    {...props}
    inputProps={{ 'aria-label': props.name }}
    sx={{
      ...props.sx,
      width: '76px',
      height: '46px',
      padding: '8px',
      '& .MuiSwitch-thumb': {
        width: '30px',
        height: '30px',
        borderRadius: '0',
        boxShadow: 'none',
        color: 'var(--blue-500)',
        textAlign: 'center',
        alignContent: 'center',
        lineHeight: '1em',
        fontSize: '.75em'
      },
      '& .MuiSwitch-track': {
        borderRadius: '0',
        backgroundColor: 'var(--blue-300)'
      },
      '& .MuiSwitch-switchBase': {
        padding: '8px',
        '&.Mui-disabled': {
          opacity: 0.5
        },
        '&.Mui-checked': {
          transform: 'translateX(30px)',
          '& .MuiSwitch-thumb': {
            '&::before': {
              content: '"On"',
              color: 'white'
            }
          }
        },
        '&:not(.Mui-checked)': {
          '& .MuiSwitch-thumb': {
            '&::before': {
              content: '"Off"',
              color: 'white'
            }
          }
        }
      }
    }}
  />
}
