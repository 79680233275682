export class AppRoutes {
  static readonly HOME = '/'
  static readonly ADMINISTRATION = '/Administration/:id'
  static readonly PLANNING = '/Planning'
  static readonly PLANNING_INSTANCE = '/Planning/:rcnId'
  static readonly PLANNING_DETAILS = '/Planning/:rcnId/:planId/:tab'
  static readonly IMPLEMENTATION = '/Implementation'
  static readonly IMPLEMENTATION_INSTANCE = '/Implementation/:timeSpanId'
  static readonly ORGANIZATION_DETAILS = '/Organization/:id/:tab'
  static readonly RESOURCES = '/Resources'
  static readonly REPORTS = '/Reports'
  static readonly USER_DETAILS = '/User/:userId/:orgId?'
  static readonly CETA_CLASSROOMS = '/CETAClassrooms/:timeSpanId/:classroomId?'
  static readonly CETA_OVERVIEW = '/CETAOverview/:classroomId/:tab'
  static readonly CETA_SECTION_PRINT = '/CETAPlan/PrintSections/:classroomId/:tab/:sectionIds'
  static readonly CETA_SNAPSHOT = '/CETA/:classroomId/Snapshot/:snapshotId'
  static readonly CETA_RECONCILE = '/CETA/:classroomId/Reconcile/:snapshotId'
}

export function getRouteFromString (name: string): string {
  switch (name) {
    case 'HOME': {
      return AppRoutes.HOME
    }
    case 'PLANNING': {
      return AppRoutes.PLANNING
    }
    case 'IMPLEMENTATION': {
      return AppRoutes.IMPLEMENTATION
    }
    case 'RESOURCES': {
      return AppRoutes.RESOURCES
    }
    case 'REPORTS': {
      return AppRoutes.REPORTS
    }
    default: {
      return AppRoutes.HOME
    }
  }
}
