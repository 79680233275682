import { PlanOrganizationSelection } from './PlanOrganizationSelection'
import { PriorityActivityOverview } from './PriorityActivityOverview'
import { type AnnualPlanRole } from '../../../models/AnnualPlanRole'
import { CircularProgress, Typography } from '@mui/material'
import { type PlanOrganization } from '../../../models/PlanOrganization'
import { type PlanOrganizationTask } from '../../../models/PlanOrganizationTask'

interface Props {
  annualPlanId: number
  error: string | null
  isLoading: boolean
  selectedPlanOrg: PlanOrganization | undefined
  showPlanOrgSelection: boolean
  userRole: AnnualPlanRole | undefined
  handlePlanOrgSelected: (planOrg: PlanOrganization) => void
  onSelectedTaskChange: (selectedTask: PlanOrganizationTask | null) => void
}

export const PriorityActivitiesTab = (props: Props): JSX.Element => {
  if (props.isLoading || props.userRole == null) {
    return <CircularProgress />
  }

  if (props.error != null) {
    return <Typography>{props.error}</Typography>
  }

  if (props.showPlanOrgSelection) {
    return <PlanOrganizationSelection annualPlanId={props.annualPlanId} onPlanOrgSelect={props.handlePlanOrgSelected} />
  }

  return (
    <>
      {(props.selectedPlanOrg != null) &&
        <PriorityActivityOverview
          planOrganization={props.selectedPlanOrg}
          userRole={props.userRole}
          onSelectedTaskChange={props.onSelectedTaskChange}
        />}
    </>
  )
}
