import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppRoutes } from '../../Routes'
import { TabDisplay } from '@oaisd/michdev.components.react'
import { CETADashboard } from './Dashboard/CETADashboard'
import { CETAClassGettingStarted } from './CETAClassGettingStarted'
import { GetClassroomDetails, GetNameAndTeamMemberInfo, SetUserStartedCeta } from '../../../services/CETAClassroomService'
import LockIcon from '@mui/icons-material/Lock'
import type { CETATeamMember } from '../../../models/CETATeamMember'
import { generatePath } from 'react-router'
import { CETAPlanOverview } from './Plan/CETAPlanOverview'
import { CETAClassroomStage } from '../../../models/CETAClassroomStage'
import type { AvailableCETAProcesses } from '../../../models/AvailableCETAProcesses'
import { GetAvailableCETAProcesses } from '../../../utils/cetaUtils'
import { CETAProgressUpdatesOverview } from './ProgressUpdates/CETAProgressUpdatesOverview'
import { CETAProgressReport } from './CETAProgressReport'
import { CETAResourceList } from './CETAResourceList'

export class CETAOverviewTabs {
  static readonly GettingStarted = '0'
  static readonly Dashboard = '1'
  static readonly Plan = '2'
  static readonly ProgressUpdates = '3'
  static readonly ProgressReport = '4'
  static readonly Resources = '5'
}

export interface CetaTeamMemberAndTeacherName {
  teacherName: string
  teamMember: CETATeamMember
}

export function CETAOverview (): JSX.Element {
  const nav = useNavigate()
  const { classroomId, tab: tabString } = useParams()
  const tab = parseInt(tabString ?? '0')

  const [timeSpanId, setTimeSpanId] = useState<number | null>(null)
  const [teacherName, setTeacherName] = useState<string | null>(null)
  const [teamMember, setTeamMember] = useState<CETATeamMember | null>(null)
  const [planLocked, setPlanLocked] = useState<boolean>(false)
  const [progressUpdateLocked, setProgressUpdateLocked] = useState<boolean>(false)
  const [progressReportLocked, setProgressReportLocked] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(Number(CETAOverviewTabs.GettingStarted))
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [viewingPlanDetails, setViewingPlanDetails] = useState(false)
  const [viewingProgressUpdateDetails, setViewingProgressUpdateDetails] = useState(false)
  const [statusCanceled, setStatusCanceled] = useState<boolean>(false)
  const [availableProcesses, setAvailableProcesses] = useState<AvailableCETAProcesses>()

  const fetchData = async (): Promise<void> => {
    if (classroomId == null) {
      return
    }

    setIsLoading(true)

    const info: CetaTeamMemberAndTeacherName = await GetNameAndTeamMemberInfo(classroomId)
    const classroom = await GetClassroomDetails(classroomId)
    const processesAvail = GetAvailableCETAProcesses(classroom.status, classroom.stage)

    setAvailableProcesses(processesAvail)
    setTeacherName(info.teacherName)
    setTeamMember(info.teamMember)
    setPlanLocked(classroom.planLocked || processesAvail.planningTabLocked)
    setProgressUpdateLocked(classroom.progressUpdateLocked || processesAvail.progressUpdatesTabLocked)
    setProgressReportLocked(classroom.progressReportLocked || processesAvail.progressReportsLocked)
    setTimeSpanId(classroom.timeSpanId)
    setStatusCanceled(classroom.stage === CETAClassroomStage.InvitationDeclined)

    setIsLoading(false)
  }

  useEffect(() => {
    void fetchData()

    setSelectedTab(tab)
  }, [classroomId, tab])

  const goBack = (): void => {
    if (viewingPlanDetails) {
      setViewingPlanDetails(false)
    } else if (viewingProgressUpdateDetails) {
      setViewingProgressUpdateDetails(false)
    } else {
      const route = generatePath(AppRoutes.CETA_CLASSROOMS, { timeSpanId: timeSpanId?.toString() ?? '', classroomId: null })
      nav(route)
    }
  }

  const getStarted = async (): Promise<void> => {
    if (classroomId != null) {
      await SetUserStartedCeta(classroomId)
      if (teamMember != null) {
        const updatedMember = { ...teamMember }
        updatedMember.hasStarted = true
        setTeamMember(updatedMember)
      }
      setSelectedTab(Number(CETAOverviewTabs.Dashboard))
    }
  }

  const updateTab = (tab: number): void => {
    setSelectedTab(tab)
    setViewingPlanDetails(false)
    setViewingProgressUpdateDetails(false)
  }

  const getLockedLabel = (tabName: string): JSX.Element => {
    return <Box sx={{ display: 'flex' }}><>{tabName}<LockIcon sx={{ marginLeft: '.5em', fontSize: '.875rem' }}/></></Box>
  }

  const hasStarted = teamMember?.hasStarted ?? false

  if (classroomId == null || isLoading) {
    return <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
  }

  if (teacherName == null) {
    return <Typography>Classroom Not Found</Typography>
  }

  if (teamMember == null) {
    return <Typography>You are not authorized to view this classroom.</Typography>
  }

  return <Box>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack} data-testid='backToClassrooms'>
        <ArrowBackIcon sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to {viewingPlanDetails || viewingProgressUpdateDetails ? 'Sections' : 'CETA Classrooms'}
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header'>{teacherName}</Typography>
    <Typography variant='h4' className='page-subheader' data-testid='subPageHeader' mt='.5em'>CETA</Typography>

    <Tabs value={selectedTab} onChange={(e, val) => { updateTab(val) }} sx={{ mb: '2em', mt: '1em' }}>
      <Tab label='Getting Started' value={0} sx={{ textTransform: 'none' }} data-testid='gettingStartedTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Dashboard' : getLockedLabel('Dashboard')} value={1} sx={{ textTransform: 'none' }} data-testid='dashboardTab' />
      <Tab disabled={!hasStarted || planLocked} label={hasStarted && !planLocked ? 'Plan' : getLockedLabel('Plan')} value={2} sx={{ textTransform: 'none' }} data-testid='planTab' />
      <Tab disabled={!hasStarted || progressUpdateLocked} label={hasStarted && !progressUpdateLocked ? 'Progress Updates' : getLockedLabel('Progress Updates')} value={3} sx={{ textTransform: 'none' }} data-testid='progressUpdatesTab' />
      <Tab disabled={!hasStarted || progressReportLocked} label={hasStarted && !progressReportLocked ? 'Progress Report' : getLockedLabel('Progress Report')} value={4} sx={{ textTransform: 'none' }} data-testid='progressReportTab' />
      <Tab label='Resources' value={5} sx={{ textTransform: 'none' }} data-testid='resourcesTab' />
    </Tabs>

    <TabDisplay index={0} value={selectedTab}>
      <CETAClassGettingStarted readonly={statusCanceled} startClicked={getStarted} hasStarted={teamMember?.hasStarted ?? false} classroomId={classroomId} />
    </TabDisplay>
    <TabDisplay index={1} value={selectedTab}>
      {hasStarted &&
        <CETADashboard
          planLocked={planLocked}
          dashboardReadonly={availableProcesses?.dashboardReadonly ?? false}
          classroomId={classroomId}
          refreshData={fetchData}
          updateTab={updateTab}
        />
      }
    </TabDisplay>
    <TabDisplay index={2} value={selectedTab}>
      {hasStarted && !planLocked &&
        <CETAPlanOverview
          readOnly={availableProcesses?.planningReadonly ?? false}
          classroomId={classroomId}
          viewingPlanDetails={viewingPlanDetails}
          setViewingPlanDetails={setViewingPlanDetails}
          onFinalizeSectionPlan={fetchData}
        />
      }
    </TabDisplay>
    <TabDisplay index={3} value={selectedTab}>
      {hasStarted && !progressUpdateLocked &&
        <CETAProgressUpdatesOverview
          readOnly={availableProcesses?.progressUpdatesReadonly ?? false}
          classroomId={classroomId}
          viewingProgressUpdateDetails={viewingProgressUpdateDetails}
          setViewingProgressUpdateDetails={setViewingProgressUpdateDetails}
        />
      }
    </TabDisplay>
    <TabDisplay index={4} value={selectedTab}>
      {hasStarted && !progressReportLocked &&
        <CETAProgressReport classroomId={classroomId} timeSpanId={timeSpanId} />
      }
    </TabDisplay>
    <TabDisplay index={5} value={selectedTab}>
      <CETAResourceList />
    </TabDisplay>
  </Box>
}
