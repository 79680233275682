import { Box, TextField, Typography } from '@mui/material'
import { MapModule, useBingMaps } from '../../../hooks/use-bingMaps'
import { useState } from 'react'
import type { Address } from '../../../models/Address'
import './bingmap.css'
import { TextFieldWithLabel } from '@oaisd/michdev.components.react'

interface LocationDetailsProps {
  onChange: (e: any) => void
  onPlaceSelected?: (place: Microsoft.Maps.ISuggestionResult) => Promise<void>
  editData: Address
  onBlur?: (dat: Address) => void
  hidePrimary?: boolean
  integratedLabel?: boolean
  error?: boolean
  required?: boolean
}

export function LocationSuggestBox (props: LocationDetailsProps): JSX.Element {
  const [isError] = useState(false)

  const handleBlur = (): void => {
    try {
      validateLocation(props.editData)
    } catch {}
  }

  const doNothing = (): void => {}

  const locationValidated = (isValid: boolean): void => {
    // TODO : I am unable to get the bing maps address validation to function correctly. I will circle back to this at a later time.
    if (props.onBlur === undefined) return
    props.onBlur(props.editData)
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const { validateLocation } = useBingMaps({ modules: [MapModule.AutoSuggest], selectFunction: props.onPlaceSelected ?? doNothing, inputID: 'address', onResultVerified: locationValidated })

  return (<Box>
    {isError && (
      <Typography>This location could not be found.</Typography>
    )}
    { props.integratedLabel === true &&
      <TextField
        fullWidth
        autoComplete='off'
        id='address'
        name='address'
        label='Address'
        value={props.editData.street ?? ''}
        required={props.required}
        error={props.error}
        onChange={props.onChange}
        minRows={2}
        onBlur={handleBlur}
        sx={{
          border: 'none',
          boxShadow: 'none',
          pt: 0
        }}
        inputProps={{
          shrink: 'false',
          role: 'combobox',
          'aria-expanded': true,
          'aria-controls': 'as_containerSearch_address'
        }}
        data-testid='addressSearch'
      />
    }
    { props.integratedLabel !== true &&
      <TextFieldWithLabel
        ariaRole='combobox'
        ariaExpanded={true}
        ariaControls='as_containerSearch_address'
        label='Address'
        name='address'
        data-testid="address"
        autoComplete={'off'}
        className='pt-0 pb-0'
        textFieldClassName='pb-0'
        onBlur={handleBlur}
        onChange={props.onChange}
        value={props.editData.street ?? ''}
      />
    }
    <div id='searchBoxContainer' style={{ width: '100%' }}></div>
  </Box>)
}
