import { Box, Typography } from '@mui/material'
import { Modal } from '../../customComponents/Modal'
import { PriorityStatusButton } from './PriorityStatusButton'
import { type PlanOrganizationTaskStatus } from '../../../models/PlanOrganizationTask'
import { useState } from 'react'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: (newStatus: PlanOrganizationTaskStatus) => void
  currentStatus: PlanOrganizationTaskStatus
  disabled: boolean
  isReviewer: boolean
}

export const StatusSelectionReminiderModal = (props: Props): JSX.Element => {
  const [selectedStatus, setSelectedStatus] = useState<PlanOrganizationTaskStatus>(props.currentStatus)

  return <Modal
    title='Status Selection Reminder'
    open={props.open}
    data-testid='statusSelectionReminderModal'
    maxWidth='xs'
    confirmButtonText='Save'
    cancelButtonText='Cancel'
    bodyContent={<>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '27px' }}>
        <Typography>It looks as if you made some changes. Please select the current status of your work. </Typography>

        <PriorityStatusButton
          currentStatus={selectedStatus}
          disabled={props.disabled}
          isReviewer={props.isReviewer}
          onChange={setSelectedStatus}
        />
      </Box>
    </>}
    onClose={props.onClose}
    onConfirm={() => { props.onConfirm(selectedStatus) }}
  />
}
