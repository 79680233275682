import { Box, Card, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { GetRecentCommentsForAnnualPlan } from '../../../services/CommentService'
import { TabDisplay } from '../../customComponents/TabDisplay'
import { type CommentModel, type OrganizationCommentGroupModel, type RecentCommentsResponseModel } from '../../../models/Comment'
import { OrganizationTasksRecentComments } from './OrganizationTasksRecentComments'

interface Props {
  annualPlanId: number
  rcnId: number
}

export const AnnualPlanRecentCommentsCard = (props: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [recentComments, setRecentComments] = useState<RecentCommentsResponseModel>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.annualPlanId == null) return

      const response = await GetRecentCommentsForAnnualPlan(props.annualPlanId)

      setRecentComments(response)
      setIsLoading(false)
    }

    void fetchData()
  }, [props.annualPlanId, props.rcnId])

  const getFilteredOrganizations = (showAll: boolean): OrganizationCommentGroupModel[] => {
    if (recentComments == null) return []

    return recentComments.organizations
      .map(org => ({
        ...org,
        taskGroups: org.taskGroups
          .map(task => ({
            ...task,
            comments: task.comments.filter(comment => showAll || !comment.isResolved)
          }))
          .filter(task => task.comments.length > 0)
      }))
      .filter(org => org.taskGroups.length > 0)
  }

  const handleCommentUpdate = (updatedComment: CommentModel): void => {
    if (recentComments == null) return

    const updatedOrganizations = recentComments.organizations.map(org => ({
      ...org,
      taskGroups: org.taskGroups.map(task => ({
        ...task,
        comments: task.comments.map(comment => comment.id === updatedComment.id ? updatedComment : comment)
      }))
    }))

    setRecentComments({
      ...recentComments,
      organizations: updatedOrganizations
    })
  }

  return (
    <Card data-testid='recentCommentsCard' sx={{ backgroundColor: 'var(--grey-100)', minHeight: '3em', border: '1px solid var(--border)' }}>
      <Typography variant='h5' color='#021E40' fontWeight='600' textAlign='center' marginTop='8.5px'>Recent Comments</Typography>

      <Tabs value={selectedTab} onChange={(e, val) => { setSelectedTab(val) }}>
        <Tab data-testid='unresolvedTab' label='Unresolved' value={0} sx={{ textTransform: 'none' }} />
        <Tab data-testid='allTab' label='All' value={1} sx={{ textTransform: 'none' }} />
      </Tabs>

      <TabDisplay index={0} value={selectedTab}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
          {isLoading
            ? <Box sx={{ textAlign: 'center' }}><CircularProgress /></Box>
            : <>
              {getFilteredOrganizations(false).length > 0
                ? getFilteredOrganizations(false).map(org => (
                  <OrganizationTasksRecentComments
                    key={org.organizationId}
                    organizationCommentModel={org}
                    onCommentUpdate={handleCommentUpdate}
                  />
                ))
                : <Typography padding='12px'>No unresolved comments</Typography>}
            </>
              }
        </Box>
      </TabDisplay>

      <TabDisplay index={1} value={selectedTab}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
          {isLoading
            ? <Box sx={{ textAlign: 'center' }}><CircularProgress /></Box>
            : <>
              {getFilteredOrganizations(true).length > 0
                ? getFilteredOrganizations(true).map(org => (
                  <OrganizationTasksRecentComments
                    key={org.organizationId}
                    organizationCommentModel={org}
                    onCommentUpdate={handleCommentUpdate}
                  />
                ))
                : <Typography padding='12px'>No comments</Typography>}
            </>
          }
        </Box>
      </TabDisplay>
    </Card>
  )
}
