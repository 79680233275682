import { ArrowForward } from '@mui/icons-material'
import { Box, Button, type SxProps, type Theme, Typography } from '@mui/material'

interface Props {
  name: string
  onClick: () => void
}

export const CoreActivityCard = (props: Props): JSX.Element => {
  const styles: Record<string, SxProps<Theme>> = {
    card: {
      backgroundColor: 'var(--blue-500)',
      width: '270px',
      height: '200px',
      borderRadius: '12px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      backgroundColor: 'var(--white)',
      color: 'var(--grey-700)',
      marginTop: 'auto',
      padding: '12px 20px'
    }
  }

  return <Box data-testid='coreActivityCard' sx={styles.card}>
    <Typography variant="h5" color='var(--white)' fontWeight='700'>
      {props.name}
    </Typography>

    <Button data-testid='startActivityButton' sx={styles.button}
      onClick={props.onClick}
    >
      Start this Activity <ArrowForward />
    </Button>
  </Box>
}
