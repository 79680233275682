export class Colors {
  static readonly ACTIVE_BACKGROUND = 'var(--lightGreenChipBackground)'
  static readonly ACTIVE_TEXT = 'var(--lightGreenChipForeground)'
  static readonly ACTIVE_BORDER = 'var(--lightGreenChipBackground)'
  static readonly INACTIVE_BACKGROUND = 'var(--lightRedChipBackground)'
  static readonly INACTIVE_TEXT = 'var(--lightRedChipForeground)'
  static readonly INACTIVE_BORDER = 'var(--lightRedChipBackground)'
  static readonly PENDING_BACKGROUND = 'var(--blue-300)'
  static readonly PENDING_TEXT = 'var(--blue-900)'
  static readonly PENDING_BORDER = 'var(--blue-300)'
}

export const CARD_COLORS = {
  light: ['var(--purple-50)', 'var(--green-50)', 'var(--light-blue-50)', 'var(--yellow-50)', 'var(--blue-50)'],
  dark: ['var(--purple-500)', 'var(--green-600)', 'var(--light-blue-700)', 'var(--yellow-500)', 'var(--blue-500)']
}
