import { Autocomplete, type SxProps, TextField, Box, InputLabel } from '@mui/material'
import type { SyntheticEvent } from 'react'

interface Props {
  options: Array<{ id: string | number, name: string }>
  onChange: (e: SyntheticEvent, value: { id: string | number, name: string } | null) => void
  separateLabel?: boolean
  label?: string
  labelContent?: JSX.Element
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  value?: { id: string | number, name: string }
  name: string
  placeholder?: string
  sx?: SxProps
  disabled?: boolean
  dataTestId?: string
  showLabelAndPlaceHolder?: boolean
  groupBy?: (option: any) => string
}

export default function MUIAutoComplete (props: Props): JSX.Element {
  return <>
    {props.separateLabel === true && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <InputLabel
        sx={{ color: 'black' }}
        required={props.required === true && props.showRequiredText !== true}
      >
        {props.labelContent ?? props.label}
      </InputLabel>

      {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
      {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}
    </Box>}

    {props.separateLabel !== true && props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}

    <Autocomplete
      disabled={props.disabled ?? false}
      sx={props.sx}
      options={props.options}
      value={props.value ?? null}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      renderOption={props.groupBy == null
        ? undefined
        : (props, option) => (
          <Box
            component='li'
            sx={{ ml: '.5em' }}
            {...props}
          >
            {option.name}
          </Box>)
      }
      onChange={props.onChange}
      groupBy={props.groupBy}
      data-testid={props.dataTestId ?? props.name}
      isOptionEqualToValue={(option, value) => { return option.id === value.id }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.separateLabel === true ? undefined : props.label}
          required={props.separateLabel === true ? undefined : props.required}
          error={props.error}
          placeholder={props.placeholder}
          InputLabelProps={props.separateLabel === true ? { shrink: true } : {}}
          variant={props.separateLabel === true ? 'outlined' : 'filled' }
          inputProps={{
            ...params.inputProps,
            'aria-label': props.label,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  </>
}
