import { Box, Grid, TextField } from '@mui/material'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { type SyntheticEvent, useState, useEffect } from 'react'
import { type SelectOption } from '../../customComponents/SelectWithLabel'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SearchIcon from '@mui/icons-material/Search'
import useDebounce from '../../../hooks/use-debounce'
import { CETAClassroomStatus } from '../../../models/CETAClassroomStatus'
import { CETAClassroomStage } from '../../../models/CETAClassroomStage'

const statusOptions: Array<SelectOption<string>> = []
Object.values(CETAClassroomStatus).forEach((key) => {
  statusOptions.push({ id: key, name: key })
})

const stageOptions: Array<SelectOption<string>> = []
Object.values(CETAClassroomStage).forEach((key) => {
  stageOptions.push({ id: key, name: key })
})

export interface CETAClassroomSearchValues {
  timeSpanId: number
  districtId: number | null
  buildingId: number | null
  stage: string | null
  status: string | null
  name: string | null
}

interface DropDownSearchValues {
  district: { id: string | number, name: string } | null
  building: { id: string | number, name: string } | null
  stage: { id: string | number, name: string } | null
  status: { id: string | number, name: string } | null
}

interface Props {
  timeSpanId: number
  districtList: Array<SelectOption<string>>
  buildingList: Array<SelectOption<string>>
  onDistrictChanged: (orgId: number | null) => Promise<void>
  handleAddNewClassroom: () => void
  filtersUpdated: (searchValues: CETAClassroomSearchValues) => void
}

export const CETAClassroomSearch = (props: Props): JSX.Element => {
  const [ddlValues, setDDLValues] = useState<DropDownSearchValues>({ district: null, building: null, stage: null, status: null })
  const [searchStringValue, setSearchStringValue] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  const debouncedSearchValue = useDebounce(searchStringValue, 500)

  const districtOptions: Array<SelectOption<string>> = []
  props.districtList.forEach((dist) => {
    districtOptions.push({ id: dist.id, name: dist.name })
  })

  const buildingOptions: Array<SelectOption<string>> = []
  props.buildingList.forEach((build) => {
    buildingOptions.push({ id: build.id, name: build.name })
  })

  useEffect(() => {
    if (isLoaded) {
      filtersUpdated()
    } else {
      setIsLoaded(true)
    }
  }, [ddlValues, debouncedSearchValue])

  const onStatusChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setDDLValues({ ...ddlValues, status: value })
  }

  const onStageChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setDDLValues({ ...ddlValues, stage: value })
  }

  const onDistrictChange = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value === null) {
      await props.onDistrictChanged(null)
    } else {
      await props.onDistrictChanged(value.id as number)
    }
    setDDLValues({ ...ddlValues, building: null, district: value })
  }

  const onBuildingChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setDDLValues({ ...ddlValues, building: value })
  }

  const onSearchStringChanged = (e: any): void => {
    setSearchStringValue(e.target.value)
  }

  const filtersUpdated = (): void => {
    const selectedDistrictId = (ddlValues.district == null ? null : Number(ddlValues.district.id))
    const selectedBuildingId = (ddlValues.building == null ? null : Number(ddlValues.building.id))
    const selectedStageId = (ddlValues.stage == null ? '' : ddlValues.stage.id as string)
    const selectedStatusId = (ddlValues.status == null ? '' : ddlValues.status.id as string)
    const nameSearchValue = debouncedSearchValue

    props.filtersUpdated({ timeSpanId: props.timeSpanId, districtId: selectedDistrictId, buildingId: selectedBuildingId, stage: selectedStageId, status: selectedStatusId, name: nameSearchValue })
  }

  return <Box>
    <Grid container spacing={1} rowSpacing={1} justifyContent='space-between'>
      <Grid item container spacing={1} rowSpacing={1} width='fit-content'>
        <Grid item>
          <MUIAutoComplete
            label='District'
            name='districtSearch'
            value={ddlValues.district != null ? ddlValues.district : undefined}
            onChange={onDistrictChange}
            options={districtOptions}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <MUIAutoComplete
            disabled={ddlValues.district === null || ddlValues.district === undefined}
            label='Building'
            name='buildingSearch'
            value={ddlValues.building != null ? ddlValues.building : undefined}
            onChange={onBuildingChange}
            options={buildingOptions}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <MUIAutoComplete
            label='Stage'
            name='stageSearch'
            value={ddlValues.stage != null ? ddlValues.stage : undefined}
            onChange={onStageChange}
            options={stageOptions}
            sx={{ width: '200px' }}
          />
        </Grid>
        <Grid item>
          <MUIAutoComplete
            label='Status'
            name='statusSearch'
            value={ddlValues.status != null ? ddlValues.status : undefined}
            onChange={onStatusChange}
            options={statusOptions}
            sx={{ width: '200px' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} item justifyContent='flex-end' width='fit-content'>
        <Grid item>
          <Box sx={{ position: 'relative' }}>
            <TextField
              label={'Search'}
              name='search'
              onChange={onSearchStringChanged}
              value={searchStringValue}
              data-testid='searchName'
            />
            <SearchIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em' }} />
          </Box>
        </Grid>
        <Grid item>
          <BlueButton onClick={props.handleAddNewClassroom} sx={{ height: '4em' }} dataTestId='add-class'>
            Add CETA Classroom
            <AddCircleIcon sx={{ ml: '0.5em' }} />
          </BlueButton>
        </Grid>
      </Grid>
    </Grid>
  </Box>
}
