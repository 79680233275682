import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { TrainingSeriesList } from './TrainingSeriesList'
import { SingleTrainingList } from './SingleTrainingList'
import { TrainingsProvider } from '../../../contextProviders/TrainingProvider'

interface Props {
  onBack?: () => void
  rcnId: number
  priorityId: number
}

export const RCNProvidedProfessionalDevelopment = (props: Props): JSX.Element => {
  return (
    <TrainingsProvider priorityId={props.priorityId}>
      <Box data-testid='rcnProvidedProfessionalDevelopment' sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}
      >
        <Button
          data-testid="backPdrsButton"
          onClick={props.onBack}
          sx={{
            padding: '5px 10px',
            backgroundColor: 'var(--grey-50)',
            color: 'var(--grey-500)',
            width: 'fit-content'
          }}
        >
          <ArrowBack sx={{ mr: '10px' }} />
          Professional Development & Resource Dissemination
        </Button>

        <Typography variant='h4' fontWeight='800'>RCN Provided Professional Development</Typography>
        <p style={{ margin: '0' }}>List all professional development planned by the RCN to address Priority 1...Bacon ipsum dolor amet bacon tongue strip steak, meatloaf drumstick porchetta doner ground round capicola boudin corned beef rump landjaeger chicken. Pork loin pork porchetta short loin flank short ribs pancetta shank bacon.</p>

        <TrainingSeriesList rcnId={props.rcnId} priorityId={props.priorityId} />

        <SingleTrainingList rcnId={props.rcnId} priorityId={props.priorityId} />
      </Box>
    </TrainingsProvider>
  )
}
