import { type CommentParentType, type CommentModel } from '../../../models/Comment'
import { CommentField } from './CommentField'

interface NewCommentProps {
  onSubmit: (comment: CommentModel, taggedUserEmails: string[]) => void
  disabled?: boolean
  parentId: number
  parentType: CommentParentType
}

export const NewComment = (props: NewCommentProps): JSX.Element => {
  return (
    <CommentField
      onSubmit={props.onSubmit}
      disabled={props.disabled}
      parentId={props.parentId}
      parentType={props.parentType}
    />
  )
}
