import { Typography } from '@mui/material'
import { Modal } from '@oaisd/michdev.components.react'
import { RichTextEditor } from '../../../customComponents/RichTextEditor'
import { useState } from 'react'

interface Props {
  textKey: string
  initialText: string
  onClose: () => void
  onSubmit: (newText: string) => void
}

export const TemplateInstructionsEditModal = (props: Props): JSX.Element => {
  const [text, setText] = useState<string>(props.initialText)

  const handleSubmit = (): void => {
    props.onSubmit(text)
    props.onClose()
  }

  return <Modal
    title={`Edit ${props.textKey}`}
    open={true}
    maxWidth='sm'
    cancelButtonText='Cancel'
    confirmButtonText='Save Description'
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    contentSx={{ borderBottom: '1px solid var(--border)' }}
    data-testid='templateInstructionsEditModal'
    onClose={props.onClose}
    onConfirm={handleSubmit}
    confirmationContent={
      <>
        <Typography variant='h6'>{props.textKey}</Typography>
        <RichTextEditor content={text} setContent={setText} />
      </>
    }
  />
}
