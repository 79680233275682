import { type SyntheticEvent, useEffect, useState } from 'react'
import { AuthorizedFunctionView } from '../authorizedFunctions/AuthorizedFunctionView'
import { type UserPermission, type SecurityLevel } from '../../../../utils/genericTypes'
import { GetPermissionsForRole, GetUserFunctionsForOrg } from '../../../../services/AuthorizedFunctionService'
import { Box, Grid, Input, Typography } from '@mui/material'
import { type User } from '../../../../models/User'
import { UserDetails } from '../../../customComponents/user/Details'
import { type SelectableOrganization } from '../../../../models/SelectableOrganization'
import { Modal } from '../../../customComponents/Modal'
import { BannerModal } from '../../../customComponents/BannerModal'
import MUIAutoComplete from '../../../customComponents/MUIAutoComplete'

export interface AuthFunctionsForm {
  orgId: number
  securityLevel: string
  functions: string[]
  note: string
  approved: boolean
}

interface Props {
  member: User
  org?: SelectableOrganization
  orgOptions?: SelectableOrganization[]
  initialSecurityLevel?: SecurityLevel
  initialFunctions?: string[]
  isAccessRequest: boolean
  requestDetails?: string
  closeModal: () => void
  handleSave: (form: AuthFunctionsForm) => void
}

export function AuthorizedFunctionsEditModal (props: Props): JSX.Element {
  const [selectedSecurityLevel, setSelectedSecurityLevel] = useState<SecurityLevel | undefined>(props.initialSecurityLevel)
  const [permissionOptions, setPermissionOptions] = useState<UserPermission[]>([])
  const [selectedFunctions, setSelectedFunctions] = useState<string[]>(props.initialFunctions ?? [])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isApprovingAccessRequest, setIsApprovingAccessRequest] = useState<boolean>(false)
  const [isDenyingAccessRequest, setIsDenyingAccessRequest] = useState<boolean>(false)
  const [responseNote, setResponseNote] = useState<string>('')
  const [selectedOrg, setSelectedOrg] = useState<SelectableOrganization | undefined>(props.org)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      await loadPermissions(selectedSecurityLevel)
    }

    void loadData()
  }, [props.member.id])

  useEffect(() => {
    if (!isLoading && !props.isAccessRequest) {
      void loadSelectedFunctions()
    }
  }, [isLoading])

  const loadPermissions = async (securityLevel: SecurityLevel | undefined): Promise<UserPermission[]> => {
    if (securityLevel != null) {
      const permissions = await GetPermissionsForRole(securityLevel)
      setPermissionOptions(permissions)
      setIsLoading(false)

      return permissions
    }

    return []
  }

  const loadSelectedFunctions = async (): Promise<void> => {
    if (props.member.id != null && selectedOrg != null) {
      const functions = await GetUserFunctionsForOrg(props.member.id, selectedOrg.id)
      updateSelectedFunctions(functions, permissionOptions)
    }
  }

  // set the selected functions to the implicit functions plus the new functions
  const updateSelectedFunctions = (newFunctions: string[], allFunctionOptions: UserPermission[]): void => {
    const implicitFunctions = getAllImplicitFunctions(allFunctionOptions)
    const explicitFunctions = getAllExplicitFunctions(allFunctionOptions)
    const explicitSelectedFunctions = newFunctions.filter(f => !implicitFunctions.includes(f) && explicitFunctions.includes(f))
    setSelectedFunctions(implicitFunctions.concat(explicitSelectedFunctions))
  }

  const getAllImplicitFunctions = (functionOptions: UserPermission[]): string[] => {
    let implicitFunctions: string[] = []
    functionOptions.forEach(f => {
      if (f.readImplicit) {
        implicitFunctions.push(f.readPermissionName ?? '')
      }
      if (f.writeImplicit) {
        implicitFunctions.push(f.writePermissionName ?? '')
      }
      implicitFunctions = implicitFunctions.concat(getAllImplicitFunctions(f.subPermissions))
    })
    return implicitFunctions
  }

  const getAllExplicitFunctions = (functionOptions: UserPermission[]): string[] => {
    let explicitFunctions: string[] = []
    functionOptions.forEach(f => {
      if (!f.readImplicit) {
        explicitFunctions.push(f.readPermissionName ?? '')
      }
      if (!f.writeImplicit) {
        explicitFunctions.push(f.writePermissionName ?? '')
      }
      explicitFunctions = explicitFunctions.concat(getAllExplicitFunctions(f.subPermissions))
    })
    return explicitFunctions
  }

  const handleSubmit = (isApproved: boolean): void => {
    if (selectedOrg == null || selectedSecurityLevel == null) {
      return
    }

    const form = {
      orgId: selectedOrg.id,
      securityLevel: selectedSecurityLevel,
      functions: selectedFunctions,
      note: responseNote,
      approved: isApproved
    }

    props.handleSave(form)
  }

  const handleDenyClicked = (): void => {
    setIsDenyingAccessRequest(true)
  }

  const handleApproveClicked = (): void => {
    setIsApprovingAccessRequest(true)
  }

  const onAccessChanged = async (sl: SecurityLevel): Promise<void> => {
    setSelectedSecurityLevel(sl)
    const permissions = await loadPermissions(sl)
    updateSelectedFunctions([], permissions)
  }

  const onOrgChanged = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    let newOrg
    if (value != null && props.orgOptions != null) {
      newOrg = props.orgOptions.find(o => o.id === value.id)
    }

    if (newOrg?.type !== selectedOrg?.type) {
      setSelectedSecurityLevel(undefined)
      updateSelectedFunctions([], permissionOptions)
    }
    setSelectedOrg(newOrg)
  }

  let title = 'Manage Member Access - ' + (selectedOrg?.name ?? 'New Organization')
  let selectBoxTitle = 'Access Level'
  let cancelButtonText = 'Cancel'
  let confirmButtonText = 'Grant Member Access'
  let cancelClick = props.closeModal
  let centerButtonClick
  let confirmClick = (): void => { handleSubmit(false) }
  let cancelButtonClassName
  if (props.isAccessRequest) {
    title = `Access Request - ${props.member.firstName} ${props.member.lastName}`
    selectBoxTitle = 'Requested Access'
    cancelButtonText = 'Deny Request'
    confirmButtonText = 'Approve Request'
    cancelClick = handleDenyClicked
    centerButtonClick = props.closeModal
    confirmClick = handleApproveClicked
    cancelButtonClassName = 'modal-deny-button'
  }

  return <Modal
    open={true}
    maxWidth='xl'
    title={title}
    cancelButtonText={cancelButtonText}
    confirmButtonText={confirmButtonText}
    centerButtonText='Cancel'
    cancelButtonClassName={cancelButtonClassName}
    isUpdateDisabled={selectedOrg == null || selectedSecurityLevel == null}
    onClose={props.closeModal}
    onCancel={cancelClick}
    onConfirm={confirmClick}
    onCenterButtonClick={centerButtonClick}
    bodyContent={
      <>
        <Grid container spacing={3}>
          <Grid item>
            {!props.isAccessRequest &&
              <Box sx={{ mb: '.5em' }}>
                <MUIAutoComplete
                  separateLabel={true}
                  label='Organization'
                  name='organization'
                  value={selectedOrg}
                  options={props.orgOptions ?? []}
                  dataTestId='organizationSelect'
                  onChange={onOrgChanged}
                  groupBy={(option: SelectableOrganization) => option.type}
                />
              </Box>
            }

            <UserDetails
              users={[props.member]}
              imageKey={props.member.imageKey}
              detailName={props.member.firstName}
              isOrganizationView={false}
              isEditable={false}
            />
          </Grid>

          <Grid item>
            <AuthorizedFunctionView
              selectBoxTitle={selectBoxTitle}
              requestDetails={props.requestDetails}
              securityLevel={selectedSecurityLevel}
              permissionOptions={permissionOptions}
              selectedFunctions={selectedFunctions}
              selectedOrgType={selectedOrg?.type}
              onAccessChanged={onAccessChanged}
              onFunctionValuesChanged={setSelectedFunctions}
            />
          </Grid>
        </Grid>

        {isApprovingAccessRequest &&
          <BannerModal
            title='Approve Access Request'
            confirmButtonText='Confirm Approval'
            dataTestId='accessRequestApprovalModal'
            onClose={() => { setIsApprovingAccessRequest(false) }}
            onConfirm={() => { handleSubmit(true) }}
            bodyContent={
              <>
                <Typography>Confirm your approval with a comment below.</Typography>
                <Input
                  multiline={true}
                  disableUnderline={true}
                  minRows={3}
                  sx={{ width: '100%', border: '1px solid var(--border)', borderRadius: '4px', padding: '1em' }}
                  name='responseNote'
                  value={responseNote}
                  onChange={(e) => { setResponseNote(e.target.value) }}
                />
              </>
            }
          />
        }

        {isDenyingAccessRequest &&
          <BannerModal
            title='Deny Access Request'
            confirmButtonText='Confirm Denial'
            confirmButtonClassName='modal-deny-button'
            dataTestId='accessRequestDenialModal'
            onClose={() => { setIsDenyingAccessRequest(false) }}
            onConfirm={() => { handleSubmit(false) }}
            bodyContent={
              <>
                <Typography>Why are you denying this request?</Typography>
                <Input
                  multiline={true}
                  disableUnderline={true}
                  minRows={3}
                  name='responseNote'
                  value={responseNote}
                  data-testid='responseNote'
                  sx={{ width: '100%', border: '1px solid var(--border)', borderRadius: '4px', padding: '1em' }}
                  onChange={(e) => { setResponseNote(e.target.value) }}
                />
              </>
            }
          />
        }
      </>
    }
  />
}
