import { Box, CircularProgress, Typography } from '@mui/material'
import { UserDetails } from '../../customComponents/user/Details'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import type { User } from '../../../models/User'
import { type SyntheticEvent, useState, useEffect } from 'react'
import { AccessStatus, SecurityLevel, type SelectableOption, type ValidationResult } from '../../../utils/genericTypes'
import { GetUserDetails } from '../../../services/UserManagementService'
import { AddCETAClassroomMember, RemoveCETAClassroomMember, GetPossibleTeamMembers, UpdateCETAClassroomMember } from '../../../services/CETAClassroomService'
import { toast } from 'react-toastify'
import { SquareSwitch } from '../../customComponents/SquareSwitch'
import type { CETATeamMember } from '../../../models/CETATeamMember'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { BlockButton } from '../../customComponents/buttons/BlockButton'
import type { CETAClassroomMemberRequest } from '../../../models/CETAClassroomMemberRequest'
import { Modal } from '../../customComponents/Modal'
import { BannerModal } from '../../customComponents/BannerModal'
import { TextFieldWithLabel } from '@oaisd/michdev.components.react'
import type { FieldError } from '../../../hooks/use-fetch'

interface Props {
  classroomId: string
  editMode: boolean
  memberToEdit: CETATeamMember | undefined
  closeModal: () => void
  onSubmit: () => void
}

export const AddTeamMemberModal = (props: Props): JSX.Element => {
  const newMember: SelectableOption = { id: 0, name: 'Add New Member' }
  const emptyUser: User = {
    id: 0,
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    updatedAt: new Date(),
    updatedBy: '',
    accessStatus: AccessStatus.Active,
    isActive: true,
    organizations: [],
    buildings: [],
    securityLevel: SecurityLevel.DistrictUser
  }

  const [isLoading, setIsLoading] = useState(true)
  const [memberOptions, setMemberOptions] = useState<SelectableOption[]>([])
  const [selectedMember, setSelectedMember] = useState<User>()
  const [memberIsLead, setMemberIsLead] = useState<boolean>(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
  const [deleteMemberInfo, setDeleteMemberInfo] = useState<CETAClassroomMemberRequest | null>(null)
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [duplicateErrorMessage, setDuplicatErrorMessage] = useState<string>()
  const [showDuplicateUserModal, setShowDuplicateUserModal] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.editMode && props.memberToEdit != null) {
        setIsLoading(true)
        const ddlOptions: SelectableOption[] = [{ id: props.memberToEdit.userId, name: `${props.memberToEdit.firstName} ${props.memberToEdit.lastName}` }]
        setMemberOptions(ddlOptions)
        await fetchTeamMember()
        setMemberIsLead(props.memberToEdit.isLead)
        setIsLoading(false)
      } else {
        setIsLoading(true)
        const ddlOptions: SelectableOption[] = await GetPossibleTeamMembers(props.classroomId)
        const options = [newMember, ...ddlOptions]
        setMemberOptions(options)
        setIsLoading(false)
      }
    }

    void fetchData()
  }, [])

  const fetchTeamMember = async (): Promise<void> => {
    if (props.memberToEdit != null) {
      const selectedPerson = await GetUserDetails(props.memberToEdit.userId.toString())
      setSelectedMember(selectedPerson)
    }
  }

  const handleSubmit = async (): Promise<void> => {
    await handleSave(false)
  }

  const updateMember = async (): Promise<void> => {
    await handleSave(false)
  }

  const removeAccess = async (): Promise<void> => {
    await handleSave(true)
  }

  const handleSave = async (isDelete: boolean): Promise<void> => {
    if (selectedMember == null) {
      toast.error('No member selected')
      return
    }

    const memberInfo: CETAClassroomMemberRequest = {
      classroomId: parseInt(props.classroomId),
      memberUserId: selectedMember.id,
      memberFirstName: selectedMember?.firstName,
      memberLastName: selectedMember?.lastName,
      memberEmail: selectedMember?.email,
      isLead: memberIsLead,
      confirmAddExistingUser: false
    }

    if (isDelete) {
      setDeleteMemberInfo(memberInfo)
      setShowDeleteConfirmation(true)
    } else if (props.editMode) {
      await handleUpdate(memberInfo)
    } else {
      await handleAdd(memberInfo)
    }
  }

  async function handleUpdate (memberInfo: CETAClassroomMemberRequest): Promise<void> {
    const response = await UpdateCETAClassroomMember(memberInfo)
    handleResponse(response)
  }

  async function handleAdd (memberInfo: CETAClassroomMemberRequest): Promise<void> {
    const response: ValidationResult = await AddCETAClassroomMember(memberInfo)
    if (response.isDuplicate) {
      // if the error has enabled in it that means the user exists but is not active and can not be added
      if (response.errors[0].includes('enabled')) {
        toast.error(response.errors[0])
        return
      }
      setDuplicatErrorMessage(response.errors[0])
      setShowDuplicateUserModal(true)
    } else {
      handleResponse(response)
    }
  }

  function handleResponse (response: ValidationResult): void {
    if (response.errors.length > 0 || response.fieldErrors.length > 0) {
      response.errors.forEach((error) => {
        toast.error(error)
      })
      setFieldErrors(response.fieldErrors)
    } else {
      if (showDuplicateUserModal) {
        setShowDuplicateUserModal(false)
      }
      props.onSubmit()
    }
  }

  const confirmAddDuplicateUser = async (): Promise<void> => {
    if (selectedMember != null) {
      const memberInfo: CETAClassroomMemberRequest = {
        classroomId: parseInt(props.classroomId),
        memberUserId: selectedMember.id,
        memberFirstName: selectedMember?.firstName,
        memberLastName: selectedMember?.lastName,
        memberEmail: selectedMember?.email,
        isLead: memberIsLead,
        confirmAddExistingUser: true
      }

      await handleAdd(memberInfo)
    }
  }

  const handleConfirmDelete = async (): Promise<void> => {
    if (deleteMemberInfo !== null) {
      const response: ValidationResult = await RemoveCETAClassroomMember(deleteMemberInfo)
      handleResponse(response)
    }
  }

  const onMemberChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value == null) {
      setSelectedMember(emptyUser)
      return
    }

    if (value.id === 0) {
      setSelectedMember(emptyUser)
    } else {
      const selectedPerson = await GetUserDetails(value?.id as string)
      setSelectedMember(selectedPerson)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setMemberIsLead(checked)
  }

  const handleTeamMemberFieldUpdate = (e: SyntheticEvent): void => {
    const target = e.target as HTMLInputElement
    setSelectedMember({ ...selectedMember ?? emptyUser, [target.name]: target.value })
  }

  return <Modal
    title={props.editMode ? 'Edit Team Member' : 'Add Team Member'}
    open={true}
    maxWidth='md'
    onClose={props.closeModal}
    onConfirm={handleSubmit}
    buttonClassName='modal-button'
    confirmButtonText='Save Changes'
    confirmButtonClassName='modal-confirm-button'
    cancelButtonText='Cancel'
    noButtons={props.editMode}
    bodyContent={ isLoading
      ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
      : <>
        <Box sx={{ marginBottom: '.5em', marginTop: '.5em' }}>
          <MUIAutoComplete
            label='Select Member'
            name='memberSelect'
            value={selectedMember != null ? { id: selectedMember.id, name: `${selectedMember.firstName} ${selectedMember.lastName}` } : undefined }
            onChange={onMemberChanged}
            options={memberOptions}
            disabled={props.editMode}
            sx={{ width: '100%', marginBottom: '1em' }}
          />

          { selectedMember != null && selectedMember.id === 0 && <>
            <TextFieldWithLabel
              name='firstName'
              label='First Name'
              value={selectedMember.firstName}
              required={fieldErrors.some((error) => error.fieldName === 'firstName')}
              showRequiredText={fieldErrors.some((error) => error.fieldName === 'firstName')}
              error={fieldErrors.some((error) => error.fieldName === 'firstName')}
              dataTestId='firstName'
              textFieldClassName='pb-0'
              onChange={handleTeamMemberFieldUpdate}
            />
            <TextFieldWithLabel
              name='lastName'
              label='Last Name'
              value={selectedMember.lastName}
              required={fieldErrors.some((error) => error.fieldName === 'lastName')}
              showRequiredText={fieldErrors.some((error) => error.fieldName === 'lastName')}
              error={fieldErrors.some((error) => error.fieldName === 'lastName')}
              dataTestId='lastName'
              textFieldClassName='pb-0'
              onChange={handleTeamMemberFieldUpdate}
            />
            <TextFieldWithLabel
              name='email'
              label='Email'
              value={selectedMember.email}
              required={fieldErrors.some((error) => error.fieldName === 'email')}
              showRequiredText={fieldErrors.some((error) => error.fieldName === 'email')}
              error={fieldErrors.some((error) => error.fieldName === 'email')}
              dataTestId='addTeamEmail'
              textFieldClassName='pb-0'
              onChange={handleTeamMemberFieldUpdate}
            />
          </>}

          { showDuplicateUserModal &&
            <BannerModal
              title='User Already Exists'
              cancelButtonText='Close'
              confirmButtonText='Confirm'
              bodyContent={<Typography data-testid='duplicate-message'>{duplicateErrorMessage} Are you sure you want to continue?</Typography>}
              dataTestId='duplicate-user-modal'
              onClose={() => { setShowDuplicateUserModal(false) }}
              onConfirm={confirmAddDuplicateUser}
            />}

          { selectedMember?.id !== 0 &&
            <UserDetails
              maxWidth='100%'
              imageKey={selectedMember?.imageKey}
              detailName={selectedMember?.firstName ?? ''}
              isOrganizationView={true}
              infoDisplay={'Member Information'}
              users={selectedMember === undefined ? [] : [selectedMember]}
              isEditable={false}
              onUpdate={() => {}}
            />
          }

          <Box display='flex' alignItems='center' sx={{ marginTop: '1em', marginBottom: '1em' }}>
            <SquareSwitch
              name={'isLeadSwitch'}
              checked={memberIsLead}
              onChange={handleChange}
              data-testid={'memberIsLeadSwitch'}
            />
            <Typography variant='body1' sx={{ ml: '1em', fontWeight: 600 }}>CETA Lead For This Classroom</Typography>
          </Box>
        </Box>
        {
          props.editMode
            ? <Box sx={{ marginTop: '1em', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <BlockButton onClick={async () => { await removeAccess() }} dataTestId='removeButton' hoverColor='white' backgroundColor='var(--red-500)' hoverBackgroundColor='var(--red-500)' color='white' >Remove Access</BlockButton>
              <BlockButton backgroundColor='white' onClick={props.closeModal} dataTestId='closeButton'>Cancel</BlockButton>
              <BlueButton onClick={async () => { await updateMember() }} dataTestId='updateButton'>Save Changes</BlueButton>
            </Box>
            : <></>
        }
        { showDeleteConfirmation &&
          <BannerModal
            title='Confirm Delete'
            cancelButtonText='Cancel'
            confirmButtonText='Delete Team Member'
            bodyContent={<Typography>This will remove the team member from this classroom.  Their responses to archived snapshots will be preserved but any outstanding instances will be discarded.  Do you want to continue?</Typography>}
            dataTestId='confirm-delete-modal'
            onClose={() => { setShowDeleteConfirmation(false) }}
            onConfirm={handleConfirmDelete}
          />}
      </>
    }
  />
}
