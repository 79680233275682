import { Accordion, AccordionSummary, Box, Typography, AccordionDetails, IconButton, Menu, MenuItem } from '@mui/material'
import { WarningCard } from '../../../customComponents/WarningCard'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { type AccessRequest } from '../../../../models/requestObjects/AccessRequest'
import { OrgTabs } from '../OrganizationDetails'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../../Routes'
import { useContext, useRef, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../../../contextProviders/BreadcrumbProvider'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'

interface Props {
  primaryOrg: OrganizationDetailsModel
  accessRequest: AccessRequest
}

export const AccessRequestAccordion = (props: Props): JSX.Element => {
  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const anchorEl = useRef<HTMLButtonElement>(null)

  const goToTab = (): void => {
    const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.Overview })
    setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

    const route = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.accessRequest.organizationId.toString(), tab: OrgTabs.Users })
    nav(route)
    setOpenMenu(false)
  }

  return <Accordion>
    <AccordionSummary
      data-testid='accessRequestAccordionSummary'
      expandIcon={
        <KeyboardArrowDownIcon sx={{ color: 'white', bgcolor: '#726F74', width: '1.125em', height: '1.125em', borderRadius: '50%' }} />
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box><AccountCircleOutlinedIcon /></Box>
        <Typography sx={{ fontSize: '1.25em', ml: '1em' }}>Access Request - {props.accessRequest.firstName} {props.accessRequest.lastName}</Typography>
        <ErrorOutlineIcon sx={{ mx: '.5em' }} />
      </Box>
    </AccordionSummary>

    <AccordionDetails>
      <WarningCard message={props.accessRequest.details} />

      <IconButton aria-label="menu" data-testid="ellipsesMenu" ref={anchorEl} onClick={() => { setOpenMenu(true) }} sx={{ float: 'right' }}>
        <MoreHorizIcon fontSize='large' sx={{ fill: 'black' }} />
      </IconButton>

      <Menu open={openMenu} anchorEl={anchorEl.current} onClose={() => { setOpenMenu(false) }}>
        <MenuItem
          data-testid='menuItemView'
          onClick={goToTab}
        >
          View
        </MenuItem>
      </Menu>
    </AccordionDetails>
  </Accordion>
}
