import { ArrowForward, RadioButtonUnchecked } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'
import { CARD_COLORS } from '../../../utils/colors'

interface Props {
  handlePriorityContinue: (taskName: string) => void
  taskName: string
  num: number
  title: string
  items: string[]
}

export const PriorityActivityCard = (props: Props): JSX.Element => {
  const lightCardColor = CARD_COLORS.light[String(props.num).charCodeAt(0) % CARD_COLORS.light.length]
  const cardColor = CARD_COLORS.dark[String(props.num).charCodeAt(0) % CARD_COLORS.dark.length]

  return <Card
    data-testid='priorityActivityCard'
    key={props.num}
    sx={{
      backgroundColor: lightCardColor,
      width: '285px',
      height: '500px',
      borderRadius: '12px',
      padding: '20px 27px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    }}
  >
    <Box sx={{
      width: '115px',
      height: '115px',
      backgroundColor: cardColor,
      borderRadius: '12px',
      display: 'grid',
      placeContent: 'center',
      color: 'white',
      fontSize: '40px',
      alignSelf: 'center'
    }}
    >{props.num.toString().padStart(2, '0')}</Box>

    <Typography variant='h5'>{props.title}</Typography>

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    }}
    >
      {props.items.map(item => (
        <Box key={item} sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <RadioButtonUnchecked sx={{ color: 'var(--grey-900)' }} />

          <Typography variant='body1'>{item}</Typography>
        </Box>
      ))}
    </Box>

    <Button
      data-testid='continueButton'
      onClick={() => { props.handlePriorityContinue(props.taskName) }}
      sx={{
        padding: '12px 24px',
        backgroundColor: 'var(--white)',
        color: 'var(--grey-700)',
        marginTop: 'auto'
      }}
    >
      Continue this Priority <ArrowForward sx={{ ml: '10px' }} />
    </Button>
  </Card>
}
