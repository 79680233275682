import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Checkbox, Menu, MenuItem, Typography } from '@mui/material'
import { type SnapshotMember, type SnapshotDashboardModel } from '../../../../models/SnapshotDashboardModel'
import { ArrowForward, CameraAltOutlined, KeyboardArrowDown, MoreHoriz, PersonOutlined, PlagiarismOutlined, RocketLaunchOutlined, SendOutlined, SportsFootballOutlined } from '@mui/icons-material'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import { useRef, useState } from 'react'
import { CancelSnapshot, CompleteOutstandingAssessments, CreateSnapshot, FinalizeSnapshot, SendReminder, SendReminders, StartCETAAssessment } from '../../../../services/CETAClassroomService'
import { Modal } from '../../../customComponents/Modal'
import { toast } from 'react-toastify'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../../Routes'
import { WarningCard } from '../../../customComponents/WarningCard'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

interface Props {
  classroomId: string
  readonly: boolean
  isLead: boolean
  activeSnapshot: SnapshotDashboardModel | null
  completedSnapshots: SnapshotDashboardModel[]
  cetaUpdateBegin: Date
  refreshData: () => void
}

export const SnapshotsCard = (props: Props): JSX.Element => {
  const anchorEl = useRef<SVGSVGElement>(null)
  const nav = useNavigate()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [isCreatingSnapshot, setIsCreatingSnapshot] = useState<boolean>(false)
  const [isFinalizingSnapshot, setIsFinalizingSnapshot] = useState<boolean>(false)
  const [isCancelingSnapshot, setIsCancelingSnapshot] = useState<boolean>(false)
  const [treatAsUpdate, setTreatAsUpdate] = useState<boolean>(props.activeSnapshot?.isCETAUpdate ?? false)

  const lockSnapshot = async (snapshotId: number): Promise<void> => {
    await CompleteOutstandingAssessments(snapshotId.toString())
    goToReconcileScreen(snapshotId)
  }

  const goToReconcileScreen = (snapshotId: number): void => {
    const route = generatePath(AppRoutes.CETA_RECONCILE,
      { classroomId: props.classroomId, snapshotId: snapshotId.toString() })
    nav(route)
  }

  const goToSnapshot = (): void => {
    const route = generatePath(AppRoutes.CETA_SNAPSHOT,
      { classroomId: props.classroomId, snapshotId: props.activeSnapshot?.id.toString() ?? '' })
    nav(route)
  }

  const startCETAAssessment = async (): Promise<void> => {
    await StartCETAAssessment(props.activeSnapshot?.id ?? 0)
    goToSnapshot()
  }

  const sendReminder = async (member: SnapshotMember): Promise<void> => {
    await SendReminder(props.classroomId, member.id)
    toast.success('Reminder sent')
    setOpenMenu(false)
  }

  const sendReminders = async (): Promise<void> => {
    await SendReminders(props.classroomId)
    toast.success('Reminders sent')
    setOpenMenu(false)
  }

  const finalizeSnapshot = async (): Promise<void> => {
    await FinalizeSnapshot(props.activeSnapshot?.id.toString() ?? '', treatAsUpdate)
    toast.success('Snapshot finalized')
    props.refreshData()
  }

  const cancelSnapshot = async (): Promise<void> => {
    await CancelSnapshot(props.activeSnapshot?.id ?? 0)
    toast.success('Snapshot removed')
    props.refreshData()
  }

  const createSnapshot = async (): Promise<void> => {
    await CreateSnapshot(props.classroomId)
    toast.success('Snapshot created')
    props.refreshData()
  }

  const ellipsesClicked = (e: React.MouseEvent): void => {
    setOpenMenu(!openMenu)
    e.stopPropagation()
  }

  const startFinalizingSnapshot = (): void => {
    setTreatAsUpdate(props.activeSnapshot?.isCETAUpdate ?? false)
    setIsFinalizingSnapshot(true)
    setOpenMenu(false)
  }

  const incompleteMembersNames = (): string => {
    const members = props.activeSnapshot?.members.filter(member => !member.hasCompleted)
    if (members == null) {
      return ''
    }

    if (members.length === 1) {
      return members[0].name
    }

    const names = members.map(member => member.name)
    const last = names.pop() ?? ''
    return `${names.join(', ')} and ${last}`
  }

  const hasCompletedUpdate = props.completedSnapshots.some(s => s.isCETAUpdate)
  const canAddCETAUpdate = props.cetaUpdateBegin < new Date() && !hasCompletedUpdate
  let newSnapshotNumber = props.completedSnapshots.length
  if (hasCompletedUpdate) {
    newSnapshotNumber--
  }

  return <Card sx={{ backgroundColor: '#F3F3F380', minHeight: '3em', width: '300px', border: '1px solid var(--border)', p: '1em' }}>
    <Typography variant='h5' color='var(--grey-900)' textAlign='center' fontWeight='600'>
      CETA Snapshots
    </Typography>

    {(props.activeSnapshot != null || (props.isLead && !props.readonly)) &&
      <Typography variant='body1' textAlign='center' my='1em'>
        Active Snapshot
      </Typography>
    }

    {props.activeSnapshot == null && props.isLead && !props.readonly &&
      <Accordion data-testid='placeholderSnapshot' defaultExpanded={true}>
        <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ color: 'white', bgcolor: '#726F74', width: '1.125em', height: '1.125em', borderRadius: '50%' }} data-testid='expand' />}>
          {props.completedSnapshots.length === 0 &&
            <Box sx={{ display: 'flex' }}>
              <SportsFootballOutlined />
              <Typography variant='body1' textAlign='center' ml='1em'>
                CETA Kickoff
              </Typography>
            </Box>
          }

          {props.completedSnapshots.length > 0 && !canAddCETAUpdate &&
            <Box sx={{ display: 'flex' }}>
              <CameraAltOutlined />
              <Typography variant='body1' textAlign='center' ml='1em'>
                Snapshot {newSnapshotNumber}
              </Typography>
            </Box>
          }

          {props.completedSnapshots.length > 0 && canAddCETAUpdate &&
            <Box sx={{ display: 'flex' }}>
              <CameraAltOutlined />
              <Typography variant='body1' textAlign='center' ml='1em'>
                CETA Update
              </Typography>
            </Box>
          }
        </AccordionSummary>

        <AccordionDetails>
          <BlueButton onClick={() => { setIsCreatingSnapshot(true) }} sx={{ width: '100%', justifyContent: 'space-between' }} dataTestId='createSnapshot'>
            Get Started
            <RocketLaunchOutlined />
          </BlueButton>
        </AccordionDetails>
      </Accordion>
    }

    {props.activeSnapshot != null &&
      <Accordion data-testid='activeSnapshot' defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <KeyboardArrowDown
              data-testid='expand'
              sx={{
                color: 'white',
                bgcolor: '#726F74',
                width: '1.125em',
                height: '1.125em',
                borderRadius: '50%'
              }}
            />
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {props.activeSnapshot.isKickoff &&
              <SportsFootballOutlined />
            }

            {!props.activeSnapshot.isKickoff &&
              <CameraAltOutlined />
            }

            <Typography variant='body1' textAlign='center' ml='1em'>
              {props.activeSnapshot.name}
            </Typography>

            {props.activeSnapshot.members.some(member => !member.hasCompleted) &&
              <ErrorOutlineIcon sx={{ ml: '1em' }} />
            }
          </Box>

          {props.isLead && !props.readonly &&
            <MoreHoriz
              aria-label="menu"
              ref={anchorEl}
              fontSize='large'
              data-testid="snapshotMenu"
              onClick={ellipsesClicked}
              sx={{ fill: 'black', ml: '.5em' }}
            />
          }
        </AccordionSummary>

        <AccordionDetails>
          {props.activeSnapshot.members.map(member =>
            <Box key={member.id}>
              { !member.hasCompleted &&
                <WarningCard message={`This team member still needs to contribute to ${props.activeSnapshot?.name ?? ''}.`} />
              }
              {(member.isCurrentMember || !member.hasCompleted) &&
                <Accordion sx={{ my: '.625em !important' }} data-testid='snapshotMember' defaultExpanded={true}>
                  <AccordionSummary expandIcon={<KeyboardArrowDown data-testid='expand' />}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PersonOutlined />

                      <Box ml='.625em'>
                        <Typography variant='body1' textAlign='center' sx={{ fontWeight: '500' }}>
                          {member.name}
                        </Typography>
                        <Typography variant='body1' textAlign='center' sx={{ fontSize: '.875em' }}>
                          {member.title}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>

                  {!props.readonly &&
                    <AccordionDetails>
                      {member.isCurrentMember && member.hasCompleted &&
                        <BlueButton onClick={goToSnapshot} sx={{ width: '100%', justifyContent: 'space-between' }} dataTestId='reviewMyResponses'>
                          Review My Responses
                          <ArrowForward />
                        </BlueButton>
                      }
                      {member.isCurrentMember && !member.hasCompleted && member.hasStarted &&
                        <BlueButton onClick={goToSnapshot} sx={{ width: '100%', justifyContent: 'space-between' }} dataTestId='continueAssessment'>
                          Continue
                          <ArrowForward />
                        </BlueButton>
                      }
                      {member.isCurrentMember && !member.hasStarted &&
                        <BlueButton onClick={startCETAAssessment} sx={{ width: '100%', justifyContent: 'space-between' }} dataTestId='startAssessment'>
                          Get Started
                          <ArrowForward />
                        </BlueButton>
                      }
                      {!member.isCurrentMember && !member.hasCompleted &&
                        <BlueButton onClick={async () => { await sendReminder(member) }} sx={{ width: '100%', justifyContent: 'space-between' }} dataTestId='sendReminder'>
                          Send Reminder
                          <SendOutlined />
                        </BlueButton>
                      }
                    </AccordionDetails>
                  }
                </Accordion>
              }
            </Box>
          )}

          <Menu open={openMenu} anchorEl={anchorEl.current} onClose={() => { setOpenMenu(false) }}>
            {props.activeSnapshot.members.some(member => member.hasCompleted) &&
              <MenuItem
                data-testid='menuItem-finalizeSnapshot'
                onClick={startFinalizingSnapshot}
              >
                Finalize Snapshot
              </MenuItem>
            }

            {props.activeSnapshot.members.some(member => member.isCurrentMember && member.hasCompleted) &&
              <MenuItem
                data-testid='menuItem-reviewMyResponses'
                onClick={goToSnapshot}
              >
                Review My Responses
              </MenuItem>
            }

            {props.activeSnapshot.members.some(member => !member.isCurrentMember && !member.hasCompleted) &&
              <MenuItem
                data-testid='menuItem-sendReminders'
                onClick={sendReminders}
              >
                Send Reminders
              </MenuItem>
            }

            <MenuItem
              data-testid='menuItem-cancelSnapshot'
              onClick={() => { setIsCancelingSnapshot(true) }}
            >
              Cancel Snapshot
            </MenuItem>
          </Menu>
        </AccordionDetails>
      </Accordion>
    }

    {props.completedSnapshots.length > 0 &&
      <Typography variant='body1' textAlign='center' mt='1em'>
        Completed Snapshots
      </Typography>
    }

    {props.completedSnapshots.map((snapshot) =>
      <Box
        key={snapshot.name}
        data-testid='completedSnapshots'
        sx={{
          backgroundColor: 'white',
          border: '1px solid var(--border)',
          display: 'flex',
          p: '16px',
          mb: '.625em',
          justifyContent: 'space-between'
        }}
      >
        <Box display='flex'>
          {snapshot.isKickoff &&
            <SportsFootballOutlined />
          }
          {!snapshot.isKickoff &&
            <CameraAltOutlined />
          }

          <Typography variant='body1' textAlign='center' ml='1em'>
            {snapshot.name}
          </Typography>
        </Box>

        {props.isLead &&
          <PlagiarismOutlined onClick={() => { goToReconcileScreen(snapshot.id) }} sx={{ float: 'right', cursor: 'pointer' }} data-testid='reviewCompletedSnapshot' />
        }
      </Box>
    )}

    {isCreatingSnapshot &&
      <Modal
        open={true}
        title='Create Snapshot'
        bodyContent='This will create a new CETA Snapshot and assign it to each of the team members in this CETA classroom. Are you ready to begin this process?'
        confirmButtonText='Yes, Continue'
        cancelButtonText='No, Cancel'
        onClose={() => { setIsCreatingSnapshot(false) }}
        onConfirm={createSnapshot}
      />
    }

    {isCancelingSnapshot &&
      <Modal
        open={true}
        title='Cancel Snapshot'
        bodyContent={'This action will remove the snapshot and any team members\' responses. Are you sure you want to continue?'}
        confirmButtonText='Yes, Cancel Snaphsot'
        cancelButtonText='No, Exit'
        onClose={() => { setIsCancelingSnapshot(false) }}
        onConfirm={cancelSnapshot}
      />
    }

    {isFinalizingSnapshot && props.activeSnapshot != null &&
      <>
        {props.activeSnapshot.members.length === 1 && props.activeSnapshot.members.some(member => member.isCurrentMember) &&
          <Modal
            open={true}
            title='Finalize Snapshot'
            maxWidth='sm'
            bodyContent={
              <>
                <Typography>This will finalize and lock this CETA Snapshot. Would you like to review your responses before continuing?</Typography>

                {/* Prompt to make this the ceta update, unless it has already been set as such on creation,
                  or is the kickoff, or has already been completed */}
                {props.cetaUpdateBegin < new Date() &&
                !props.activeSnapshot.isCETAUpdate &&
                !props.activeSnapshot.isKickoff &&
                !props.completedSnapshots.some(s => s.isCETAUpdate) &&
                  <Box sx={{ display: 'flex', mt: '1em' }}>
                    <Checkbox
                      checked={treatAsUpdate}
                      sx={{ color: 'var(--grey-500) !important' }}
                      onClick={() => { setTreatAsUpdate(!treatAsUpdate) }}
                      inputProps={{ 'aria-label': 'Treat as CETA Update' }}
                      data-testid='treatAsUpdate'
                    />
                    <Typography variant='body1' ml='.5em' alignContent='center'>Treat as CETA Update</Typography>
                  </Box>
                }
              </>
            }
            confirmButtonText='Yes, I would like to review first'
            cancelButtonText='No, I am ready to finalize'
            cancelButtonClassName='modal-confirm-button'
            onClose={() => { setIsFinalizingSnapshot(false) }}
            onCancel={finalizeSnapshot}
            onConfirm={() => { goToReconcileScreen(props.activeSnapshot?.id ?? 0) }}
          />
        }

        {props.activeSnapshot.members.length > 1 && props.activeSnapshot.members.every(member => member.hasCompleted) &&
          <Modal
            open={true}
            title='Finalize Snapshot'
            bodyContent={'This will finalize and lock this CETA Snapshot. Are you ready to reconcile the teams\' responses?'}
            confirmButtonText='Yes, Continue'
            cancelButtonText='No, Cancel'
            onClose={() => { setIsFinalizingSnapshot(false) }}
            onConfirm={() => { goToReconcileScreen(props.activeSnapshot?.id ?? 0) }}
          />
        }

        {props.activeSnapshot.members.length > 1 && props.activeSnapshot.members.some(member => !member.hasCompleted) &&
          <Modal
            open={true}
            title='Finalize Snapshot'
            bodyContent={`There are incomplete instances by ${incompleteMembersNames()}. By continuing, this will block them from completing their instance of the CETA. Are you sure you want to continue to reconcile the teams' responses?`}
            confirmButtonText='Yes, Continue'
            cancelButtonText='No, Cancel'
            onClose={() => { setIsFinalizingSnapshot(false) }}
            onConfirm={async () => { await lockSnapshot(props.activeSnapshot?.id ?? 0) }}
          />
        }
      </>
    }
  </Card>
}
