import { type Response, sendGet, sendPost } from '../hooks/use-fetch'
import type { AnnualPlanMeeting } from '../models/AnnualPlanMeeting'
import type { AnnualPlanStatus } from '../models/AnnualPlanStatus'
import type { AnnualPlanTeamMember } from '../models/AnnualPlanTeamMember'
import { type PlanOrganization } from '../models/PlanOrganization'
import type { PlanOrganizationTask } from '../models/PlanOrganizationTask'
import { type RCNPD } from '../models/RCNPD'
import { type UpdateTrainingFormValues, type AddTrainingFormValues } from '../pages/pageComponents/planning/AddEditTrainingModal'
import type { AddOrEditAnnualPlanMeetingRequest, AddAnnualPlanRequest, AnnualPlanTeamSaveRequest } from '../models/requestObjects/AnnualPlanRequestObjects'
import type { AnnualPlanNameAndOrgName, AnnualPlanOption, AnnualPlanTeamAndOptions } from '../models/requestObjects/AnnualPlanResponseObjects'
import type { OrganizationOption } from '../models/requestObjects/CommonObjects'

export async function GetAnnualPlanTeam (annualPlanId: number): Promise<AnnualPlanTeamMember[]> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanTeam?annualPlanId=${annualPlanId}`)
  return response
}

export async function GetAnnualPlanTeamAndOptions (annualPlanId: number): Promise<AnnualPlanTeamAndOptions> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanTeamAndOptions?annualPlanId=${annualPlanId}`)
  return response
}

export async function SaveAnnualPlanTeam (teamRequest: AnnualPlanTeamSaveRequest): Promise<void> {
  const { response } = await sendPost('AnnualPlan/SaveAnnualPlanTeam', teamRequest)
  return response
}

export async function SendPlanOrgTaskReminder (taskId: number): Promise<void> {
  const { response } = await sendPost('AnnualPlan/SendTaskReminder', { taskId })
  return response
}

export async function GetTasksForAnnualPlan (annualPlanId: number): Promise<PlanOrganizationTask[]> {
  const { response } = await sendGet(`AnnualPlan/GetTasksForAnnualPlan?annualPlanId=${annualPlanId}`)
  return response
}

export async function GetUsersPlanRole (annualPlanId: number): Promise<string> {
  const request = await sendGet(`AnnualPlan/GetUsersPlanRole?annualPlanId=${annualPlanId}`)
  const role = request.response
  return role
}

export async function GetAnnualPlanAndOrgName (annualPlanId: string, organizationId: string): Promise<AnnualPlanNameAndOrgName> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanAndOrgName?annualPlanId=${annualPlanId}&&orgId=${organizationId}`)
  return response
}

export async function GetAnnualPlanOptions (rcnId: number): Promise<AnnualPlanOption[]> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanOptions?rcnId=${rcnId}`)
  return response
}

export async function AddAnnualPlan (request: AddAnnualPlanRequest): Promise<Response> {
  return await sendPost('AnnualPlan/AddAnnualPlan', request)
}

export async function GetPlanOrganizationsForAnnualPlan (annualplanId: number): Promise<PlanOrganization[]> {
  const { response } = await sendGet(`AnnualPlan/GetISDsForAnnualPlan?annualPlanId=${annualplanId}`)
  return response
}

export async function GetAnnualPlanStatus (annualPlanId: number): Promise<AnnualPlanStatus | undefined> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanStatus?annualPlanId=${annualPlanId}`)
  return response
}

export async function StartAnnualPlan (annualPlanId: number, rcnId: number): Promise<void> {
  const { response } = await sendPost('AnnualPlan/StartAnnualPlan', { annualPlanId, rcnId })
  return response
}

export async function UpdatePlanOrganizationTaskStatus (planOrganizationTaskId: number, status: string): Promise<Response> {
  return await sendPost('AnnualPlan/UpdatePlanOrganizationTaskStatus', { planOrganizationTaskId, status })
}

export async function AddRCNPD (event: AddTrainingFormValues): Promise<Response> {
  return await sendPost('AnnualPlan/AddRCNPD', event)
}

export async function UpdateRCNPD (event: UpdateTrainingFormValues): Promise<Response> {
  return await sendPost('AnnualPlan/UpdateRCNPD', event)
}

export async function DeleteRCNPD (rcnPDId: number): Promise<Response> {
  return await sendPost(`AnnualPlan/DeleteRCNPD?rcnPDId=${rcnPDId}`, {})
}

export async function GetIsdsAndDistrictsForPlanOrganization (planOrgId: number): Promise<OrganizationOption[]> {
  const { response } = await sendGet(`AnnualPlan/GetIsdsAndDistrictsForPlanOrganization?planOrgId=${planOrgId}`)
  return response
}

export async function GetRCNPDsForPriority (priorityId: number): Promise<RCNPD[]> {
  const { response } = await sendGet(`AnnualPlan/GetRCNPDsForPriority?priorityId=${priorityId}`)
  return response
}

export async function AddMeeting (meetingRequest: AddOrEditAnnualPlanMeetingRequest): Promise<Response> {
  return await sendPost('AnnualPlan/AddMeeting', meetingRequest)
}

export async function UpdateMeeting (meetingRequest: AddOrEditAnnualPlanMeetingRequest): Promise<Response> {
  return await sendPost('AnnualPlan/UpdateMeeting', meetingRequest)
}

export async function GetAnnualPlanMeetings (annualPlanId: number, orgId: number): Promise<AnnualPlanMeeting[]> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanMeetings?annualPlanId=${annualPlanId}&&orgId=${orgId}`)
  return response
}

export async function GetPlanOrganizationTaskId (annualPlanId: number, orgId: number, taskName: string): Promise<number> {
  const { response } = await sendGet(`AnnualPlan/GetPlanOrganizationTaskId?annualPlanId=${annualPlanId}&&orgId=${orgId}&&taskName=${taskName.replaceAll(' ', '%20')}`)
  return response
}

export async function GetPlanOrganizationTask (annualPlanId: number, orgId: number, taskName: string): Promise<PlanOrganizationTask> {
  const { response } = await sendGet(`AnnualPlan/GetPlanOrganizationTask?annualPlanId=${annualPlanId}&&orgId=${orgId}&&taskName=${taskName.replaceAll(' ', '%20')}`)
  return response
}

export async function ChangeTaskStatus (annualPlanId: number, orgId: number, taskName: string, status: string): Promise<void> {
  const { response } = await sendPost('AnnualPlan/ChangeTaskStatus', { annualPlanId, orgId, taskName, status })
  return response
}

export async function DeleteMeeting (meetingId: number): Promise<void> {
  const { response } = await sendPost(`AnnualPlan/DeleteMeeting?meetingId=${meetingId}`, {})
  return response
}
