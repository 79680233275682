import { useEffect, useState } from 'react'
import { GetRCNOptionsForUser } from '../../../services/UserManagementService'
import { Box, CircularProgress, Typography } from '@mui/material'
import { OrgCard } from './OrgCard'
import type { OrganizationOption } from '../../../models/requestObjects/CommonObjects'

interface RCNSelectionProps {
  onRCNSelect: (rcn: OrganizationOption) => void
}

export const RCNSelection = (props: RCNSelectionProps): JSX.Element => {
  const [rcns, setRcns] = useState<OrganizationOption[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getRcns = async (): Promise<void> => {
      const rcnOrgs = await GetRCNOptionsForUser()

      setRcns(rcnOrgs)
      setIsLoading(false)
    }

    void getRcns()
  }, [])

  return (
    <Box data-testid="rcn-selection" sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '44px'
    }}
    >
      <Typography variant='h6'>RCNs</Typography>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '33px'
      }}
      >
        {isLoading
          ? <CircularProgress />
          : rcns?.map((rcn) =>
            <OrgCard
              key={rcn.id}
              name={rcn.name}
              imageKey={rcn.imageKey}
              buttonText='View Annual Plans'
              onClick={() => { props.onRCNSelect(rcn) }}
              dataTestId='rcn-card'
              buttonDataTestId='view-annual-plans-button'
            />)}
      </Box>
    </Box>
  )
}
