import { Modal, TextFieldWithLabel } from '@oaisd/michdev.components.react'
import { useEffect, useState } from 'react'
import { WarningCard } from '../../customComponents/WarningCard'
import { type SelectableOption } from '../../../utils/genericTypes'
import { AddRCN, GetAvailableISDsFromEEM } from '../../../services/OrganizationService'
import { Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import { type FieldError } from '../../../hooks/use-fetch'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'

interface Props {
  parentOrgId: number
  onClose: () => void
  onSubmit: () => void
}

export const AddRCNModal = (props: Props): JSX.Element => {
  const [rcnName, setRcnName] = useState<string>('')
  const [assignedIsds, setAssignedIsds] = useState<SelectableOption[]>([])
  const [availableIsds, setAvailableIsds] = useState<SelectableOption[]>([])
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [refreshToken, setRefreshToken] = useState<number>(0)

  useEffect(() => {
    const fetchAvailableIsds = async (): Promise<void> => {
      const isds = await GetAvailableISDsFromEEM()
      setAvailableIsds(isds)
    }

    void fetchAvailableIsds()
  }, [])

  const handleSubmit = async (): Promise<void> => {
    const { response, success, errors } = await AddRCN(props.parentOrgId.toString(), rcnName, assignedIsds.map(isd => isd.id))
    if (!success) {
      setFieldErrors(response.fieldErrors)
      errors.forEach((error) => {
        toast.error(error)
      })
      return
    }

    toast.success(`${rcnName} was added to the RCN List.`)
    props.onClose()
    props.onSubmit()
  }

  const onISDSelected = (selectedIsd: SelectableOption): void => {
    const tmpIsds = [...assignedIsds, selectedIsd].sort((a, b) => a.name.localeCompare(b.name))
    setAssignedIsds(tmpIsds)

    const tmpIsds2 = [...availableIsds].filter(isd => isd.id !== selectedIsd?.id)
    setAvailableIsds(tmpIsds2)

    setRefreshToken(refreshToken + 1)
  }

  const onISDRemoved = (removedIsd: SelectableOption): void => {
    const tmpIsds = [...assignedIsds].filter(isd => isd.id !== removedIsd.id)
    setAssignedIsds(tmpIsds)

    const tmpIsds2 = [...availableIsds, removedIsd].sort((a, b) => a.name.localeCompare(b.name))
    setAvailableIsds(tmpIsds2)
  }

  return <Modal
    title='Add New RCN'
    open={true}
    maxWidth='xs'
    cancelButtonText='Cancel'
    confirmButtonText='Add RCN'
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    contentSx={{ borderBottom: '1px solid var(--border)' }}
    onClose={props.onClose}
    onConfirm={handleSubmit}
    confirmationContent={
      <>
        <TextFieldWithLabel
          label='RCN Name'
          name='rcnName'
          dataTestId='rcnName'
          required={fieldErrors.some((error) => error.fieldName === 'rcnName')}
          showRequiredText={fieldErrors.some((error) => error.fieldName === 'rcnName')}
          error={fieldErrors.some((error) => error.fieldName === 'rcnName')}
          value={rcnName}
          onChange={(e) => { setRcnName(e.target.value) }}
        />

        <MUIAutoComplete
          key={refreshToken} // Force the field to clear after selecting an ISD
          label='Assigned ISDs'
          name='assignedIsds'
          separateLabel={true}
          placeholder={assignedIsds.length > 0 ? 'Select Another ISD' : 'Select An ISD'}
          required={fieldErrors.some((error) => error.fieldName === 'assignedIsds')}
          showRequiredText={fieldErrors.some((error) => error.fieldName === 'assignedIsds')}
          error={fieldErrors.some((error) => error.fieldName === 'assignedIsds')}
          options={availableIsds}
          onChange={(_e, value) => { onISDSelected(value as SelectableOption) }}
        />

        {assignedIsds.map((isd) => (
          <Chip
            key={isd.id}
            label={isd.name}
            sx={{ color: 'black', backgroundColor: 'var(--blue-300)', fontSize: '14px', my: '.25em', mr: '.25em' }}
            onDelete={() => { onISDRemoved(isd) }}
            deleteIcon={<CloseIcon sx={{ fill: 'black' }} data-testid={`remove-${isd.name}`} />}
          />
        ))}

        <WarningCard message='This RCN will be added to the system and must be configured further to enable their use of the system.' />
      </>
    }
  />
}
