import { Box } from '@mui/material'
import { type NewCommentValues, type UpdateCommentValues, type CommentModel, type CommentParentType } from '../../../models/Comment'
import { type AuthUser } from '../../../hooks/use-auth'
import { useState, type Dispatch, type SetStateAction } from 'react'
import { AddComment, DeleteComment, UpdateComment } from '../../../services/CommentService'
import { BannerModal } from '../BannerModal'
import { toast } from 'react-toastify'
import { NewComment } from './NewComment'
import { CommentItem } from './CommentItem'

interface CommentsListProps {
  comments: CommentModel[]
  setComments: Dispatch<SetStateAction<CommentModel[]>>
  currentUser: AuthUser
  parentId: number
  parentType: CommentParentType
}

export const CommentsList = (props: CommentsListProps): JSX.Element => {
  const [commentToDelete, setCommentToDelete] = useState<CommentModel | null>(null)
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null)

  const handleEditComment = async (comment: CommentModel): Promise<void> => {
    if (comment.userId !== props.currentUser.id) return

    const { success, errors } = await UpdateComment(comment)

    if (!success) {
      errors.forEach((e) => toast.error(e))
      return
    }

    props.setComments((comments) => {
      return comments.map((c) => c.id === comment.id ? comment : c)
    })
  }

  const handleDeleteComment = async (comment: CommentModel): Promise<void> => {
    if (comment.userId !== props.currentUser.id) return

    const { success, errors } = await DeleteComment(comment.id)

    if (!success) {
      errors.forEach((e) => toast.error(e))
      return
    }

    props.setComments((comments) => {
      return comments.filter((c) => c.id !== comment.id)
    })
    setCommentToDelete(null)
  }

  const handleResolveComment = async (comment: CommentModel): Promise<void> => {
    if (comment.isResolved) return

    const updateCommentValues: UpdateCommentValues = {
      id: comment.id,
      commentText: comment.commentText,
      isResolved: true
    }

    const { success, errors } = await UpdateComment(updateCommentValues)

    if (!success) {
      errors.forEach((e) => toast.error(e))
      return
    }

    comment.isResolved = true

    props.setComments((comments) => {
      return comments.map((c) => c.id === comment.id ? comment : c)
    })
  }

  const handleNewComment = async (comment: CommentModel, taggedUserEmails: string[]): Promise<void> => {
    const newCommentValues: NewCommentValues = {
      commentText: comment.commentText,
      taggedUserEmails,
      parentType: props.parentType,
      parentId: props.parentId
    }

    const { response, success, errors } = await AddComment(newCommentValues)

    if (!success || response?.id === null) {
      errors.forEach((e) => toast.error(e))
      return
    }

    comment.id = response.id

    props.setComments((comments) => [...comments, comment])
  }

  return (
    <Box sx={{
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 'inherit',
      paddingRight: '34px'
    }}
    >
      <NewComment
        onSubmit={handleNewComment}
        disabled={editingCommentId != null}
        parentId={props.parentId}
        parentType={props.parentType}
      />

      {props.comments.map((c) =>
        <CommentItem
          key={c.id}
          comment={c}
          isEditing={editingCommentId === c.id}
          disabled={editingCommentId != null}
          onEdit={handleEditComment}
          onDelete={setCommentToDelete}
          onResolve={handleResolveComment}
          currentUser={props.currentUser}
          orgId={props.currentUser.currentOrgId}
          setEditingCommentId={setEditingCommentId}
        />)}

      {(commentToDelete != null) && <BannerModal
        title='Are you sure?'
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        dataTestId='confirmDeleteCommentModal'
        bodyContent={'Are you sure you want to delete this comment?'}
        onClose={() => { setCommentToDelete(null) }}
        onConfirm={() => { void handleDeleteComment(commentToDelete) }}
      />}
    </Box>
  )
}
