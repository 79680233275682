import { Modal, type SelectOption } from '@oaisd/michdev.components.react'
import { type SyntheticEvent, useState, useEffect } from 'react'
import { type EEMOrganization } from '../../../models/EEMOrganization'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { Box, Typography } from '@mui/material'
import { IconWithLabel } from '../../customComponents/IconWithLabel'
import { Fingerprint, LocalPhoneOutlined, LocationOnOutlined } from '@mui/icons-material'
import { AddBuilding, GetAvailableBuildingsFromEEM } from '../../../services/OrganizationService'

interface Props {
  orgId: number
  onClose: () => void
  onSubmit: () => void
}

export const AddBuildingModal = (props: Props): JSX.Element => {
  const [availableBuildings, setAvailableBuildings] = useState<EEMOrganization[]>([])
  const [selectedBuilding, setSelectedBuilding] = useState<EEMOrganization | null>(null)
  const [selectedBuildingOption, setSelectedBuildingOption] = useState<SelectOption<string | number> | null>()

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const buildings = await GetAvailableBuildingsFromEEM(props.orgId.toString())
      setAvailableBuildings(buildings)
    }

    void loadData()
  }, [])

  const handleSubmit = async (): Promise<void> => {
    if (selectedBuilding == null) {
      return
    }

    await AddBuilding(props.orgId.toString(), selectedBuilding.id.toString())

    props.onClose()
    props.onSubmit()
  }

  const selectBuilding = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    const building = availableBuildings.find(d => d.id === value?.id) ?? null

    setSelectedBuildingOption(value)
    setSelectedBuilding(building)
  }

  return <Modal
    title='Add Building'
    open={true}
    cancelButtonText='Cancel'
    confirmButtonText='Add Building'
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    isUpdateDisabled={selectedBuilding == null}
    contentSx={{ borderBottom: '1px solid var(--border)' }}
    onClose={props.onClose}
    onConfirm={handleSubmit}
    confirmationContent={
      <>
        <MUIAutoComplete
          label='Building'
          name='building'
          options={availableBuildings}
          value={selectedBuildingOption ?? undefined}
          sx={{ mt: '1em' }}
          onChange={selectBuilding}
        />

        {selectedBuilding != null &&
          <Box sx={{ mx: '1em' }}>
            <Typography sx={{ fontWeight: '600', mt: '1em' }}>{selectedBuilding?.name} EEM Details</Typography>
            <IconWithLabel icon={<Fingerprint />} sx={{ mt: '.5em' }} labelText={selectedBuilding.eemCode} dataTestId='eemCode' />
            <IconWithLabel icon={<LocalPhoneOutlined />} sx={{ mt: '.5em' }} labelText={selectedBuilding.phone} dataTestId='phone' />

            <IconWithLabel
              icon={<LocationOnOutlined />}
              sx={{ mt: '.5em' }}
              whiteSpace='normal'
              labelText={selectedBuilding.address}
              dataTestId='address'
            />

            <Typography
              sx={{ ml: '2.125em', mt: '.5em' }}
              data-testid='cityAndState'
            >
              {`${selectedBuilding.city}, ${selectedBuilding.state}`}
            </Typography>

            <Typography sx={{ ml: '2.125em', mt: '.5em' }} data-testid='zip'>{selectedBuilding.zip}</Typography>
          </Box>
        }
      </>
    }
  />
}
