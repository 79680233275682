import { Box, CircularProgress, IconButton, LinearProgress, Link, type SxProps, Typography } from '@mui/material'
import { CheckCircle, PanoramaFishEye } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import { useEffect, useState } from 'react'
import { type PlanOrganizationTask, PlanOrganizationTaskName, PlanOrganizationTaskStatus } from '../../../models/PlanOrganizationTask'
import IconAccordion from '../../customComponents/IconAccordion'
import SendIcon from '@mui/icons-material/Send'
import { SendPlanOrgTaskReminder } from '../../../services/AnnualPlanService'
import { TranslateDate } from '../../../utils/dateUtils'
import { toast } from 'react-toastify'
import { AnnualPlanTabs } from './PlanningDetails'

interface Props {
  organizationName: string
  organizationTasks: PlanOrganizationTask[]
  changeTab: (tab: number) => void
}

export function OrganizationTaskProgress (props: Props): JSX.Element {
  const currentDate: Date = new Date()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pastDue, setPastDue] = useState<PlanOrganizationTask[]>([])
  const [dueSoon, setDueSoon] = useState<PlanOrganizationTask[]>([])
  const [dueLater, setDueLater] = useState<PlanOrganizationTask[]>([])
  const [statusColor, setStatusColor] = useState('')
  const [cardIcon, setCardIcon] = useState<JSX.Element>(<></>)
  const [percentProgress, setPercentProgress] = useState(0)
  const [orgKey, setOrgKey] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    const past: PlanOrganizationTask[] = []
    const soon: PlanOrganizationTask[] = []
    const later: PlanOrganizationTask[] = []
    let completedTasks: number = 0

    props.organizationTasks.forEach(ot => {
      if (orgKey === 0) {
        setOrgKey(ot.organizationId)
      }

      const taskDue: Date = TranslateDate(ot.dueDate)
      if (ot.status === PlanOrganizationTaskStatus.Complete) {
        completedTasks++
      }
      if (taskDue < currentDate) {
        past.push(ot)
      } else if (taskDue < get2WeeksDate()) {
        soon.push(ot)
      } else {
        later.push(ot)
      }
    })

    setPercentProgress(Math.round((completedTasks / props.organizationTasks.length) * 100))
    setPastDue(past)
    setDueSoon(soon)
    setDueLater(later)
    getIconAndColor()
    setIsLoading(false)
  }, [props.organizationTasks])

  const taskIcon = (task: PlanOrganizationTask): JSX.Element => {
    const taskDue: Date = TranslateDate(task.dueDate)
    if (task.status === PlanOrganizationTaskStatus.Complete) {
      return <CheckCircle style={{ color: 'var(--green-500)', alignSelf: 'center' }} data-testid={`${task.id}-taskcomplete`} />
    }

    if (taskDue < currentDate) {
      return <ErrorIcon style={{ color: 'var(--red-700)', alignSelf: 'center' }} data-testid={`${task.id}-taskpastdue`} />
    } else if (taskDue < get2WeeksDate()) {
      return <ErrorIcon style={{ color: 'var(--yellow-500)', alignSelf: 'center' }} data-testid={`${task.id}-taskduesoon`} />
    } else {
      return <PanoramaFishEye style={{ color: 'var(--blue-700)', alignSelf: 'center' }} data-testid={`${task.id}-taskduelater`} />
    }
  }

  const get2WeeksDate = (): Date => {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 14)
    return newDate
  }

  const getIconAndColor = (): void => {
    let pastDue: boolean = false
    let allComplete: boolean = true
    let dueInTwoWeeks: boolean = false
    let orgKeyForIcon: number = 0

    props.organizationTasks.forEach(task => {
      const taskDue: Date = TranslateDate(task.dueDate)

      if (orgKeyForIcon === 0) {
        orgKeyForIcon = task.organizationId
      }

      if (task.status !== PlanOrganizationTaskStatus.Complete) {
        allComplete = false
      }

      if (taskDue < currentDate && task.status !== PlanOrganizationTaskStatus.Complete) {
        pastDue = true
      }

      if (taskDue < get2WeeksDate() && task.status !== PlanOrganizationTaskStatus.Complete) {
        dueInTwoWeeks = true
      }
    })

    if (allComplete) {
      setCardIcon(<CheckCircle sx={{ color: 'white', mx: '15%', width: '70%' }} data-testid={`${orgKeyForIcon}allCompleteIcon`} />)
      setStatusColor('var(--green-500)')
      return
    }

    if (pastDue) {
      setCardIcon(<ErrorIcon sx={{ color: 'white', mx: '15%', width: '70%' }} data-testid={`${orgKeyForIcon}pastDueIcon`} />)
      setStatusColor('var(--red-500)')
      return
    }

    if (dueInTwoWeeks) {
      setCardIcon(<ErrorIcon sx={{ color: 'white', mx: '15%', width: '70%' }} data-testid={`${orgKeyForIcon}dueSoonIcon`} />)
      setStatusColor('var(--yellow-500)')
    } else {
      setCardIcon(<></>)
      setStatusColor('var(--green-500)')
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const accordionStyle: SxProps = { mx: '1em' }

  const getSummary = (): JSX.Element => {
    return <Box sx={{ ml: '1em', my: '.5em' }}>
      <Typography data-testid={`${orgKey}-orgTitle`} variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{props.organizationName}</Typography>
      <Typography sx={{ mt: '.5em', fontSize: '.875em' }}>Planning Progress:</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress data-testid={`${orgKey}-progressDisplay`} variant="determinate" value={percentProgress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography sx={{ fontSize: '1em', color: 'text.secondary' }}>
            {`${percentProgress}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  }

  const getTaskElements = (): JSX.Element => {
    return <Box>
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ fontWeight: '600' }}>Done</Typography>
        { pastDue.length === 0 && <Typography data-testid={`${orgKey}-nonePastDue`} sx={{ my: '1em' }} fontSize='1em' ml='1em'>No Tasks Past Due</Typography>}
        { pastDue.map((task) => (
          getTaskLine(task)
        ))}
      </Box>
      <Box>
        <Typography sx={{ fontWeight: '600' }}>Now</Typography>
        { dueSoon.length === 0 && <Typography data-testid={`${orgKey}-noneDueSoon`} sx={{ my: '1em' }} fontSize='1em' ml='1em'>No Tasks Due Within 2 Weeks</Typography>}
        { dueSoon.map((task) => (
          getTaskLine(task)
        ))}
      </Box>
      <Box>
        <Typography sx={{ fontWeight: '600' }}>Upcoming</Typography>
        { dueLater.length === 0 && <Typography data-testid={`${orgKey}-noneDueLater`} sx={{ my: '1em' }} fontSize='1em' ml='1em'>No Tasks Due In More Than 2 Weeks</Typography>}
        { dueLater.map((task) => (
          getTaskLine(task)
        ))}
      </Box>
    </Box>
  }

  const goToTaskDetails = (task: PlanOrganizationTask): void => {
    /// need to update this method when the task details screen is made?
    if (task.taskName === PlanOrganizationTaskName.MeetingSchedules) {
      props.changeTab(AnnualPlanTabs.MeetingSchedules)
    } else {
      toast.error('This function is not currently connected.')
    }
  }

  const sendReminder = async (taskId: number): Promise<void> => {
    await SendPlanOrgTaskReminder(taskId)
    toast.error('This function is not currently connected.')
  }

  const getTaskLine = (task: PlanOrganizationTask): JSX.Element => {
    const sendReminderOption: boolean = task.status === PlanOrganizationTaskStatus.Incomplete
    return <Box key={task.id} sx={{ display: 'flex', alignContent: 'center', my: '1em' }} >
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'left', flexDirection: 'row', width: '100%' }}>
        {taskIcon(task)}
        <Link
          data-testid={`${task.id}-taskLink`}
          onClick={() => { goToTaskDetails(task) }}
          sx={{
            color: 'var(--blue-700)',
            cursor: 'pointer',
            fontSize: '1em',
            alignSelf: 'center',
            marginLeft: '.625em',
            overflowWrap: 'break-word',
            whiteSpace: 'break-spaces'
          }}
        >{task.taskName}</Link>
      </Box>
      <Typography sx={{ fontSize: '1em', alignSelf: 'center', color: 'var(--blue-700)' }}>{new Date(task.dueDate).toLocaleDateString()}</Typography>
      <IconButton data-testid={`${task.id}-sendIcon`} sx={{ alignContent: 'center', ml: '.5em', visibility: `${sendReminderOption ? 'visible' : 'hidden'}` }} onClick={async () => { await sendReminder(task.id) }} ><SendIcon/></IconButton>
    </Box>
  }

  return <IconAccordion
    expandButtonKey={`${orgKey}-expandButton`}
    color={statusColor}
    icon={cardIcon}
    expanded={false}
    baseStyle={accordionStyle}
    accordionSummary={getSummary()}
    accordionDetails={getTaskElements()}
  />
}
