import { type ChangeEvent } from 'react'
import NavBar from '../navBar'

export interface HeaderProps {
  onSearchTextChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => void
}

export default function Header (props: HeaderProps): JSX.Element {
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'var(--white)',
      marginBottom: '.625em'
    }}
    >
      <NavBar />
    </div>
  )
}
