import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from '@mui/material'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppRoutes } from '../../Routes'
import { TabDisplay } from '@oaisd/michdev.components.react'
import { GetAnnualPlanAndOrgName, GetAnnualPlanStatus } from '../../../services/AnnualPlanService'
import LockIcon from '@mui/icons-material/Lock'
import { AnnualPlanDashboard } from './AnnualPlanDashboard'
import { PriorityActivitiesTab } from './PriorityActivitiesTab'
import { MeetingSchedules } from './MeetingSchedules'
import type { AnnualPlanNameAndOrgName } from '../../../models/requestObjects/AnnualPlanResponseObjects'
import { AnnualPlanGettingStarted } from './AnnualPlanGettingStarted'
import { AnnualPlanStatus } from '../../../models/AnnualPlanStatus'
import { toast } from 'react-toastify'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { type PlanOrganizationTask } from '../../../models/PlanOrganizationTask'
import { KeyboardArrowRight } from '@mui/icons-material'
import { usePriorityActivities } from '../../../hooks/use-priorityActivities'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'

export enum AnnualPlanTabs {
  GettingStarted = 0,
  Dashboard,
  MeetingSchedules,
  PriorityActivities,
  MiscExpenses,
  BudgetOverview,
  FinalizePlan
}

export function PlanningDetails (): JSX.Element {
  const nav = useNavigate()
  const { rcnId, planId, tab: tabString } = useParams()
  const tab = parseInt(tabString ?? '0')
  const [planName, setPlanName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [selectedTab, setSelectedTab] = useState<number>(AnnualPlanTabs.GettingStarted)
  const [isLoading, setIsLoading] = useState(true)
  const [originalOrgName, setOriginalOrgName] = useState('')
  const [annualPlanStatus, setAnnualPlanStatus] = useState<AnnualPlanStatus>(AnnualPlanStatus.NotStarted)
  const [selectedTask, setSelectedTask] = useState<PlanOrganizationTask | null>(null)
  const hasStarted = annualPlanStatus !== AnnualPlanStatus.NotStarted

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (planId == null || rcnId == null) return

      setIsLoading(true)

      const response: AnnualPlanNameAndOrgName = await GetAnnualPlanAndOrgName(planId, rcnId)
      setPlanName(response.annualPlanName)
      setOrganizationName(response.organizationName)
      setOriginalOrgName(response.organizationName)

      const annualPlanStatus = await GetAnnualPlanStatus(Number(planId))
      if (annualPlanStatus == null) {
        toast.error('Failed to get annual plan status')
      } else {
        setAnnualPlanStatus(annualPlanStatus)
        if (annualPlanStatus === AnnualPlanStatus.NotStarted) {
          setSelectedTab(Number(AnnualPlanTabs.GettingStarted))
        }
      }

      setIsLoading(false)
    }

    void fetchData()

    setSelectedTab(tab)
  }, [rcnId, planId])

  const handlePlanOrgChange = (orgName: string | null): void => {
    setOrganizationName(orgName ?? originalOrgName)
  }

  const {
    error: priorityActivitiesError,
    isLoading: priorityActivitiesIsLoading,
    selectedPlanOrg: priorityActivitiesSelectedPlanOrg,
    showPlanOrgSelection: priorityActivitiesShowPlanOrgSelection,
    userRole,
    handlePlanOrgSelected: priorityActivitiesHandlePlanOrgSelected,
    handleReviewOthers: priorityActivitiesHandleReviewOthers
  } = usePriorityActivities({ annualPlanId: Number(planId), onPlanChange: handlePlanOrgChange })

  const showReviewOthersButton = (userRole === AnnualPlanRole.PlanAdministrator || userRole === AnnualPlanRole.PlanReviewer) &&
    selectedTab === AnnualPlanTabs.PriorityActivities &&
    priorityActivitiesSelectedPlanOrg != null && selectedTask == null

  const onPlanStart = (): void => {
    setSelectedTab(Number(AnnualPlanTabs.Dashboard))
    setAnnualPlanStatus(AnnualPlanStatus.Drafting)
  }

  const goBack = (): void => {
    const route = generatePath(AppRoutes.PLANNING, {})
    nav(route)
  }

  const updateTab = (tab: number): void => {
    setSelectedTab(tab)

    if (tab !== AnnualPlanTabs.PriorityActivities) {
      setOrganizationName(originalOrgName)
    }
  }

  const getLockedLabel = (tabName: string): JSX.Element => {
    return <Box sx={{ display: 'flex' }}>
      <>{tabName}<LockIcon sx={{ marginLeft: '.5em', fontSize: '.875rem' }}/></>
    </Box>
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return <>
    <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack} data-testid='backToPlanning'>
        <ArrowBackIcon sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to Planning
      </Link>
      <Link
        sx={{ cursor: 'pointer' }}
        underline="hover"
        color="inherit"
        onClick={() => { if (rcnId != null) nav(generatePath(AppRoutes.PLANNING_INSTANCE, { rcnId })) }}
      >
        {originalOrgName}
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header'>{planName}</Typography>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant='h4' className='page-subheader' data-testid='subPageHeader' mt='.5em'>{organizationName}</Typography>

      {showReviewOthersButton &&
        <BlueButton onClick={priorityActivitiesHandleReviewOthers}>
          Review Other ISD Priorities <KeyboardArrowRight fontSize='small' sx={{ ml: '16px' }} />
        </BlueButton>}
    </Box>

    <Tabs value={selectedTab} onChange={(e, val) => { updateTab(val) }} sx={{ mb: '2em', mt: '1em' }}>
      <Tab label='Getting Started' value={AnnualPlanTabs.GettingStarted} sx={{ textTransform: 'none' }} data-testid='gettingStartedTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Dashboard' : getLockedLabel('Dashboard')} value={AnnualPlanTabs.Dashboard} sx={{ textTransform: 'none' }} data-testid='dashboardTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Meeting Schedules' : getLockedLabel('Meeting Schedules')} value={AnnualPlanTabs.MeetingSchedules} sx={{ textTransform: 'none' }} data-testid='meetingTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Priority Activities' : getLockedLabel('Priority Activities')} value={AnnualPlanTabs.PriorityActivities} sx={{ textTransform: 'none' }} data-testid='activitiesTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Misc Expenses' : getLockedLabel('Misc Expenses')} value={AnnualPlanTabs.MiscExpenses} sx={{ textTransform: 'none' }} data-testid='expensesTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Budget Overview' : getLockedLabel('Budget Overview')} value={AnnualPlanTabs.BudgetOverview} sx={{ textTransform: 'none' }} data-testid='budgetTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Finalize Plan' : getLockedLabel('Finalize Plan')} value={AnnualPlanTabs.FinalizePlan} sx={{ textTransform: 'none' }} data-testid='finalizeTab' />
    </Tabs>

    <TabDisplay index={AnnualPlanTabs.GettingStarted} value={selectedTab}>
      <AnnualPlanGettingStarted
        annualPlanId={Number(planId)}
        annualPlanStatus={annualPlanStatus}
        rcnId={Number(rcnId)}
        onStartPlan={onPlanStart}
      />
    </TabDisplay>
    <TabDisplay index={AnnualPlanTabs.Dashboard} value={selectedTab}>
      {hasStarted &&
        <AnnualPlanDashboard annualPlanId={Number(planId)} rcnId={Number(rcnId)} changeTab={updateTab}></AnnualPlanDashboard>
      }
    </TabDisplay>
    <TabDisplay index={AnnualPlanTabs.MeetingSchedules} value={selectedTab}>
      {hasStarted &&
        <MeetingSchedules annualPlanId={Number(planId ?? 0)} rcnId={Number(rcnId)}/>
      }
    </TabDisplay>
    <TabDisplay index={AnnualPlanTabs.PriorityActivities} value={selectedTab}>
      {hasStarted &&
        <PriorityActivitiesTab
          annualPlanId={Number(planId)}
          error={priorityActivitiesError}
          isLoading={priorityActivitiesIsLoading}
          selectedPlanOrg={priorityActivitiesSelectedPlanOrg}
          showPlanOrgSelection={priorityActivitiesShowPlanOrgSelection}
          userRole={userRole}
          handlePlanOrgSelected={priorityActivitiesHandlePlanOrgSelected}
          onSelectedTaskChange={setSelectedTask}
        />
      }
    </TabDisplay>
    <TabDisplay index={AnnualPlanTabs.MiscExpenses} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={AnnualPlanTabs.BudgetOverview} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={AnnualPlanTabs.FinalizePlan} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
  </>
}
