import { type Dispatch, type MouseEvent, type SetStateAction, useState } from 'react'
import { type AuthUser } from '../../../hooks/use-auth'
import { type CommentModel } from '../../../models/Comment'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { CheckCircleOutline } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { CommentField } from './CommentField'

interface CommentItemProps {
  comment: CommentModel
  currentUser: AuthUser
  orgId: number
  onDelete: (comment: CommentModel) => void
  onResolve: (comment: CommentModel) => void
  onEdit: (comment: CommentModel) => void
  isEditing: boolean
  setEditingCommentId: Dispatch<SetStateAction<number | null>>
  disabled?: boolean
}

export const CommentItem = (props: CommentItemProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const actionsMenuOpen = Boolean(anchorEl)
  const isCommentOwner = props.comment.userId === props.currentUser.id

  const handleEdit = (): void => {
    props.setEditingCommentId(props.comment.id)
    setAnchorEl(null)
  }

  const handleDelete = (): void => {
    props.onDelete(props.comment)
    setAnchorEl(null)
  }

  const handleResolve = (): void => {
    props.onResolve(props.comment)
    setAnchorEl(null)
  }

  const handleMenuClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleEditSubmit = (comment: CommentModel): void => {
    props.onEdit(comment)
    props.setEditingCommentId(null)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Avatar src={props.comment.user?.imageKey} />
          <Typography variant='subtitle1'>
            {props.comment.user?.firstName} {props.comment.user?.lastName}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {props.comment.isResolved && <CheckCircleOutline data-testid="resolved" />}

          <IconButton
            aria-controls={actionsMenuOpen ? 'comment-actions-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={actionsMenuOpen ? 'true' : undefined}
            data-testid='actionButton'
            onClick={handleMenuClick}
            disabled={props.isEditing || props.disabled}
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            id='comment-actions-menu'
            anchorEl={anchorEl}
            open={actionsMenuOpen}
            onClose={handleMenuClose}
            MenuListProps={{ 'aria-labelledby': 'comment-actions-button' }}
          >
            {isCommentOwner && <Box>
              <MenuItem data-testid="editButton" onClick={handleEdit}>
                Edit
              </MenuItem>
              <MenuItem data-testid="deleteButton" onClick={handleDelete}>
                Delete
              </MenuItem>
            </Box>}
            {!props.comment.isResolved &&
              <MenuItem data-testid="resolveButton" onClick={handleResolve}>
                Resolve
              </MenuItem>}
          </Menu>
        </Box>

      </Box>

      {props.isEditing
        ? <CommentField
            comment={props.comment}
            onSubmit={handleEditSubmit}
            onCancel={() => { props.setEditingCommentId(null) }}
            parentId={props.comment.parentId}
            parentType={props.comment.parentType}
        />
        : <Box data-testid="comment" sx={{
          backgroundColor: 'var(--white)',
          borderRadius: '8px',
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
        >
          <Typography dangerouslySetInnerHTML={{ __html: props.comment.commentText }} />

          <Typography variant='caption' sx={{ opacity: '60%' }}>
            {new Date(props.comment.dateStamp).toLocaleDateString()}
          </Typography>
        </Box>}
    </Box>
  )
}
