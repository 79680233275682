import { useEffect, useState } from 'react'
import { OnboardingSummary } from '../../../customComponents/organizations/OnboardingSummary'
import { CETATeamCard } from './CETATeamCard'
import { SnapshotsCard } from './SnapshotsCard'
import { type OnboardingTask } from '../../../../models/OnboardingTask'
import { Box, CircularProgress, Grid } from '@mui/material'
import { ArchiveClassroom, ContinueClassroom, FinalizeSnapshot, GetCETADashboardDetails, GetCETATeam, GetClassroomDetails, ToggleCETATask } from '../../../../services/CETAClassroomService'
import { useAuth } from '../../../../hooks/use-auth'
import { toast } from 'react-toastify'
import { type SnapshotDashboardModel } from '../../../../models/SnapshotDashboardModel'
import type { CETATeamMember } from '../../../../models/CETATeamMember'
import { PlanActionCard } from './PlanActionCard'
import type { CETAClassroom } from '../../../../models/CETAClassroom'
import { CETAClassroomStage } from '../../../../models/CETAClassroomStage'
import { CETAClassroomStatus } from '../../../../models/CETAClassroomStatus'
import { CETAOverviewTabs } from '../CETAOverview'
import { TranslateDate } from '../../../../utils/dateUtils'

interface Props {
  classroomId: string
  dashboardReadonly: boolean
  planLocked: boolean
  refreshData: () => void
  updateTab: (tab: number) => void
}

export const CETADashboard = (props: Props): JSX.Element => {
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tasks, setTasks] = useState<OnboardingTask[]>([])
  const [isClassroomLead, setIsClassroomLead] = useState<boolean>(false)
  const [activeSnapshot, setActiveSnapshot] = useState<SnapshotDashboardModel | null>(null)
  const [completedSnapshots, setCompletedSnapshots] = useState<SnapshotDashboardModel[]>([])
  const [team, setTeam] = useState<CETATeamMember[]>([])
  const [classroom, setClassroom] = useState<CETAClassroom | null>(null)
  const [cetaUpdateBegin, setCetaUpdateBegin] = useState<Date | null>(null)

  const refreshDashboard = async (): Promise<void> => {
    await fetchDashboardData()
    props.refreshData()
  }

  const fetchDashboardData = async (): Promise<void> => {
    setIsLoading(true)
    await Promise.all([
      fetchDashboardDetails(),
      fetchCETATeam(),
      fetchClassroomDetails()
    ])
    setIsLoading(false)
  }

  const fetchDashboardDetails = async (): Promise<void> => {
    const response = await GetCETADashboardDetails(props.classroomId)
    setTasks(response.tasks)
    setActiveSnapshot(response.activeSnapshot)
    setCompletedSnapshots(response.completedSnapshots)
    setCetaUpdateBegin(TranslateDate(response.cetaUpdateBegin))
    if (user != null) {
      setIsClassroomLead(response.classroomLeads.includes(user?.id))
    }
  }

  const fetchCETATeam = async (): Promise<void> => {
    const response = await GetCETATeam(props.classroomId)
    setTeam(response)
  }

  const fetchClassroomDetails = async (): Promise<void> => {
    setClassroom(await GetClassroomDetails(props.classroomId))
  }

  useEffect(() => {
    void fetchDashboardData()
  }, [])

  const toggleTask = async (taskKey: string): Promise<void> => {
    await ToggleCETATask(props.classroomId, taskKey)
    await fetchDashboardDetails()
  }

  const onTeamChangeSubmitted = async (): Promise<void> => {
    const response = await GetCETATeam(props.classroomId)
    setTeam(response)
    toast.success('CETA Team updated')
  }

  const viewPlan = (): void => {
    props.updateTab(Number(CETAOverviewTabs.Plan))
  }

  const continuePlan = async (): Promise<void> => {
    await ContinueClassroom(props.classroomId)
    await refreshDashboard()
  }

  const archivePlan = async (): Promise<void> => {
    await ArchiveClassroom(props.classroomId)
    await refreshDashboard()
  }

  const finalizeCurrentSnapshot = async (): Promise<void> => {
    await FinalizeSnapshot(activeSnapshot?.id.toString() ?? '', activeSnapshot?.isCETAUpdate ?? false)
    toast.success('Snapshot finalized')
    props.refreshData()
  }

  if (isLoading) {
    return <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
  }

  const showViewPlan = !props.planLocked
  const showArchiveAndContinuePlan = classroom === null ? false : (classroom?.stage === CETAClassroomStage.CETAUpdateCompleted && classroom?.status === CETAClassroomStatus.Active)
  const showPlanCard = showArchiveAndContinuePlan || showViewPlan

  return <Grid container spacing={4}>
    <Grid item>
      {showPlanCard &&
        <PlanActionCard
          showFinalizeSnapshot={activeSnapshot?.members.some(member => member.hasCompleted) ?? false}
          showViewPlan={showViewPlan}
          showArchivePlan={showArchiveAndContinuePlan}
          showContinuePlan={showArchiveAndContinuePlan}
          archivePlan={archivePlan}
          continuePlan={continuePlan}
          viewPlan={viewPlan}
          finalizeCurrentSnapshot={finalizeCurrentSnapshot}
        />
      }

      <SnapshotsCard
        readonly={props.dashboardReadonly}
        classroomId={props.classroomId}
        isLead={isClassroomLead}
        activeSnapshot={activeSnapshot}
        completedSnapshots={completedSnapshots}
        cetaUpdateBegin={cetaUpdateBegin ?? new Date()}
        refreshData={refreshDashboard}
      />
    </Grid>

    <Grid item>
      <OnboardingSummary
        readonly={!isClassroomLead}
        onboardingComplete={false}
        tasks={tasks}
        resources={[]}
        title='CETA Progress'
        subTitle='Track your CETA progress here:'
        minWidth='300px'
        borderColor='var(--border)'
        backgroundColor='#F3F3F380'
        titleColor='#021E40'
        toggleTask={toggleTask}
        bottomContent={<></>}
      />
    </Grid>

    <Grid item>
      <CETATeamCard
        readonly={!isClassroomLead || props.dashboardReadonly}
        team={team}
        classroomId={props.classroomId}
        onTeamChangeSubmitted={onTeamChangeSubmitted}
      />
    </Grid>
  </Grid>
}
