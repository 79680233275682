import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

interface Props {
  onBack?: () => void
}

export const OtherProfessionalDevelopmentResources = (props: Props): JSX.Element => {
  return <Box data-testid='otherProfessionalDevelopmentResources' sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  }}
  >
    <Button
      data-testid="backPdrsButton"
      onClick={props.onBack}
      sx={{
        padding: '5px 10px',
        backgroundColor: 'var(--grey-50)',
        color: 'var(--grey-500)',
        width: 'fit-content'
      }}
    >
      <ArrowBack sx={{ mr: '10px' }} />
      Professional Development & Resource Dissemination
    </Button>

    <Typography variant='h4' fontWeight='800'>Other Professional Development Resources</Typography>
    <p style={{ margin: '0' }}>Short description here...Bacon ipsum dolor amet bacon tongue strip steak, meatloaf drumstick porchetta doner ground round capicola boudin corned beef rump landjaeger chicken. Pork loin pork porchetta short loin flank short ribs pancetta shank bacon.</p>
  </Box>
}
