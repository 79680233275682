import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { type CommentModel, type UpdateCommentValues, type OrganizationCommentGroupModel } from '../../../models/Comment'
import { MoreHoriz } from '@mui/icons-material'
import { useState, type MouseEvent } from 'react'
import { UpdateComment } from '../../../services/CommentService'
import { toast } from 'react-toastify'

interface Props {
  organizationCommentModel: OrganizationCommentGroupModel
  onCommentUpdate: (updatedComment: CommentModel) => void
}

export const OrganizationTasksRecentComments = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const actionsMenuOpen = Boolean(anchorEl)

  const handleMenuClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleAddComment = (): void => {
    // need to update this method when the task details screen is made?
    // navigate to task details and open comments section
    toast.error('This function is not currently connected.')
    handleMenuClose()
  }

  const handleViewActivity = (): void => {
    // need to update this method when the task details screen is made?
    // navigate to task details
    toast.error('This function is not currently connected.')
    handleMenuClose()
  }

  const handleResolve = async (comment: CommentModel): Promise<void> => {
    const updateCommentValues: UpdateCommentValues = {
      id: comment.id,
      commentText: comment.commentText,
      isResolved: !comment.isResolved
    }

    const { success, errors } = await UpdateComment(updateCommentValues)

    if (!success) {
      errors.forEach((e) => toast.error(e))
      return
    }

    comment.isResolved = !comment.isResolved
    props.onCommentUpdate(comment)

    handleMenuClose()
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {props.organizationCommentModel.taskGroups.map(taskGroup => {
        return <Box key={taskGroup.taskId}>
          <Box sx={{ backgroundColor: 'var(--blue-50)', borderWidth: '1px 0 1px 0', borderColor: 'var(--blue-300)', borderStyle: 'solid', color: 'var(--grey-900)', textAlign: 'center' }}>
            <Typography variant='h6' padding='10px'>
              {props.organizationCommentModel.organizationName} - {taskGroup.taskName}
            </Typography>
          </Box>

          {taskGroup.comments.map(comment => {
            return <Box data-testid='orgTaskRecentComment' key={comment.id} sx={{ padding: '12px', display: 'flex', gap: '12px' }}>
              <Avatar src={comment.user?.imageKey} sx={{ width: '32px', height: '32px', marginTop: '10px' }} />

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {/* grey-900 */}
                  <Typography sx={{ color: 'var(--grey-900)' }} variant='subtitle1'>
                    {comment.user?.firstName} {comment.user?.lastName}
                  </Typography>

                  <IconButton
                    aria-controls={actionsMenuOpen ? 'comment-actions-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={actionsMenuOpen ? 'true' : undefined}
                    data-testid='actionButton'
                    onClick={handleMenuClick}
                  >
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    id='comment-actions-menu'
                    anchorEl={anchorEl}
                    open={actionsMenuOpen}
                    onClose={handleMenuClose}
                    MenuListProps={{ 'aria-labelledby': 'comment-actions-button' }}
                  >
                    <MenuItem data-testid="addComment" onClick={handleAddComment}>
                      Add Comment
                    </MenuItem>
                    <MenuItem data-testid="viewActivity" onClick={handleViewActivity}>
                      View Activity
                    </MenuItem>
                    <MenuItem data-testid="toggleResolve" onClick={async () => { await handleResolve(comment) }}>
                      Mark {comment.isResolved ? 'Unresolved' : 'Resolved'}
                    </MenuItem>
                  </Menu>
                </Box>

                {/* grey-500 */}
                <Typography sx={{ color: 'var(--grey-500)' }}>
                  {new Date(comment.dateStamp).toLocaleDateString(undefined, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </Typography>

                <Typography dangerouslySetInnerHTML={{ __html: comment.commentText }} />
              </Box>
            </Box>
          })}
        </Box>
      })}
    </Box>
  )
}
