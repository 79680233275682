import { createTheme } from '@mui/material/styles'

export const defaulTheme = createTheme({
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          border: '1px solid var(--border)',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          backgroundColor: 'var(--white)',
          '&:hover': {
            backgroundColor: 'var(--white)',
            border: '1px solid var(--black)'
          },
          '&.Mui-focused': {
            backgroundColor: 'var(--white)',
            border: '2px solid var(--blue-300)'
          }
        }
      }
    }
  }
})
