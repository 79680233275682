import { useState, type MouseEvent } from 'react'
import { Circle, KeyboardArrowDown } from '@mui/icons-material'
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { PlanOrganizationTaskStatus } from '../../../models/PlanOrganizationTask'

interface Props {
  currentStatus: PlanOrganizationTaskStatus
  onChange: (newStatus: PlanOrganizationTaskStatus) => void
  isReviewer: boolean
  disabled: boolean
}

export const PriorityStatusButton = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const getAvailableStatuses = (): PlanOrganizationTaskStatus[] => {
    if (props.isReviewer) {
      return props.currentStatus === PlanOrganizationTaskStatus.Needs_Revision ? [] : [PlanOrganizationTaskStatus.Needs_Revision]
    }

    return Object.values(PlanOrganizationTaskStatus)
      .filter((status) => status !== props.currentStatus && status !== PlanOrganizationTaskStatus.Needs_Revision)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleStatusChange = (newStatus: PlanOrganizationTaskStatus): void => {
    props.onChange(newStatus)
    handleClose()
  }

  const availableStatuses = getAvailableStatuses()

  return <>
    <Button
      data-testid="priorityStatusButton"
      onClick={handleClick}
      disabled={props.disabled}
      sx={{
        padding: '5px 10px',
        backgroundColor: 'var(--grey-50)',
        color: 'var(--grey-500)',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        width: 'fit-content'
      }}
    >
      <Circle sx={{ width: '10px', height: '10px' }} />
      {props.currentStatus}
      <KeyboardArrowDown />
    </Button>

    <Menu
      id="priority-status-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'priorityStatusButton'
      }}
    >
      {availableStatuses.length === 0 && <MenuItem disabled>No status options</MenuItem>}
      {availableStatuses.map(status => <MenuItem data-testid='statusOption' key={status} onClick={() => { handleStatusChange(status) }}>
        <ListItemIcon>
          <Circle sx={{ width: '10px', height: '10px' }} />
        </ListItemIcon>

        <ListItemText>{status}</ListItemText>
      </MenuItem>)}
    </Menu>
  </>
}
