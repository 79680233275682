import { Box, Button, Card, Typography } from '@mui/material'
import { AnnualPlanStatus } from '../../../models/AnnualPlanStatus'
import { useNavigate, generatePath } from 'react-router'
import { AppRoutes } from '../../Routes'
import { AddAnnualPlan } from '../../../services/AnnualPlanService'
import { toast } from 'react-toastify'
import type { AnnualPlanOption } from '../../../models/requestObjects/AnnualPlanResponseObjects'

interface AnnualPlanOptionCardProps {
  planOption: AnnualPlanOption
  rcnId: number
}

function getActionBtnText (status: AnnualPlanStatus): string {
  switch (status) {
    case AnnualPlanStatus.NotStarted:
      return 'Get Started'
    case AnnualPlanStatus.Drafting:
    case AnnualPlanStatus.Published:
    case AnnualPlanStatus.UnderReview:
    case AnnualPlanStatus.Accepted:
    case AnnualPlanStatus.Finialized:
    default:
      return 'Open'
  }
}

export const AnnualPlanOptionCard = ({ planOption, rcnId }: AnnualPlanOptionCardProps): JSX.Element => {
  const nav = useNavigate()

  const annualPlanStatusChip = (status: AnnualPlanStatus): JSX.Element => {
    const colors = ['var(--purple-500)', 'var(--red-500)', 'var(--green-500)']
    let backgroundColor = colors[2]

    if (status === AnnualPlanStatus.NotStarted) {
      backgroundColor = colors[0]
    } else if (status === AnnualPlanStatus.NoPlan) {
      backgroundColor = colors[1]
    }

    const statusText = planOption.id !== 0 && planOption.status == null
      ? AnnualPlanStatus.Drafting
      : planOption.status

    return <Box sx={{
      padding: '8px 32px',
      borderRadius: '60px',
      backgroundColor,
      color: 'var(--white)'
    }}
    >
      <Typography variant='subtitle2'>{statusText}</Typography>
    </Box>
  }

  const daysAgo = (date: string): string => {
    if (date == null || date === '') return ''

    const now = new Date()
    const createDate = new Date(date)

    // Check if same day
    if (now.getFullYear() === createDate.getFullYear() &&
        now.getMonth() === createDate.getMonth() &&
        now.getDate() === createDate.getDate()) {
      return 'Today'
    }

    const diffTime = Math.abs(now.getTime() - createDate.getTime())
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    return diffDays === 1 ? '1 day ago' : `${diffDays} days ago`
  }

  const formattedDate = planOption.createDate !== '' && planOption.createDate != null
    ? daysAgo(planOption.createDate)
    : ''

  const handleActionBtnClick = async (): Promise<void> => {
    if (planOption.status === AnnualPlanStatus.NotStarted && planOption.id === 0) {
      const { response, success, errors } = await AddAnnualPlan({ rcnId, systemTimeSpanId: planOption.timeSpanId })

      if (!success || response?.id === null) {
        errors.forEach((e) => toast.error(e))
        return
      }

      const annualPlanId = response.id

      const getStartedPath = generatePath(
        AppRoutes.PLANNING_DETAILS,
        { rcnId: rcnId.toString(), planId: annualPlanId, tab: String(0) }
      )
      nav(getStartedPath); return
    }

    const dashboardPath = generatePath(
      AppRoutes.PLANNING_DETAILS,
      { rcnId: rcnId.toString(), planId: planOption.id.toString(), tab: String(1) }
    )
    nav(dashboardPath)
  }

  return (
    <Card data-testid='annual-plan' sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '16px 32px',
      borderRadius: '16px',
      boxShadow: '0px 10px 10px -8px #3B353E4D'
    }}
    >
      <Box>
        <Typography variant='h5'>{planOption.name}</Typography>

        <Typography variant='subtitle1' color='#808394'>{formattedDate}</Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'wrap'
      }}
      >
        {annualPlanStatusChip(planOption.status)}

        <Button data-testid='action-btn' sx={{
          visibility: planOption.hasAccess ? 'visible' : 'hidden',
          border: '1px solid var(--grey-100)',
          borderRadius: '4px',
          padding: '12px 24px',
          color: 'var(--grey-700)'
        }}
          onClick={handleActionBtnClick}
        >
          {getActionBtnText(planOption.status)}
        </Button>
      </Box>
    </Card>
  )
}
