import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'
import { ToggleCommentsButton } from '../../customComponents/comments/ToggleCommentsButton'
import { useState } from 'react'
import { Comments } from '../../customComponents/comments/Comments'
import { CommentParentType } from '../../../models/Comment'
import { CoreActivityCard } from './CoreActivityCard'
import { PriorityStatusButton } from './PriorityStatusButton'
import { RCNProvidedProfessionalDevelopment } from './RCNProvidedProfessionalDevelopment'
import { STARTEventAttendancePlan } from './STARTEventAttendancePlan'
import { EPLITrainerDevelopmentPlan } from './EPLITrainerDevelopmentPlan'
import { OtherProfessionalDevelopmentResources } from './OtherProfessionalDevelopmentResources'
import { type PlanOrganizationTaskStatus, type PlanOrganizationTask } from '../../../models/PlanOrganizationTask'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'
import { StatusSelectionReminiderModal } from './StatusSelectionReminiderModal'
import { usePlanOrgTaskManagement } from '../../../hooks/use-planOrgTaskManagement'
import { type Priority } from '../../../models/Priority'

interface Props {
  onBack?: () => void
  planOrganizationTask: PlanOrganizationTask
  userRole: AnnualPlanRole
  onPlanOrgTaskUpdate?: (updatedTask: PlanOrganizationTask) => void
}

export const PriorityOneOverview = (props: Props): JSX.Element => {
  const [showComments, setShowComments] = useState(false)
  const [selectedPriority, setSelectedPriority] = useState<Priority | null>(null)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const { isUpdating, task: planOrganizationTask, updatePlanOrgTaskStatus, hasChanges, setHasChanges } = usePlanOrgTaskManagement({ intitialPlanOrgTask: props.planOrganizationTask, onPlanOrgTaskUpdate: props.onPlanOrgTaskUpdate })

  const isReviewer = props.userRole === AnnualPlanRole.PlanReviewer

  const handleNavigation = (navigationCallback: () => void): void => {
    if (hasChanges) {
      setShowStatusModal(true)
    } else {
      navigationCallback()
    }
  }

  const handlePriorityClick = (priority: Priority): void => {
    setHasChanges(true)
    setSelectedPriority(priority)
  }

  const handleShowComments = (): void => {
    setShowComments((show) => !show)
  }

  const handleBackToPriorityActivities = (): void => {
    handleNavigation(() => props.onBack?.())
  }

  const handleBackToOverview = (): void => {
    setSelectedPriority(null)
  }

  const handleModalClose = (): void => {
    setHasChanges(false)
    setShowStatusModal(false)
  }

  const handleModalConfirm = async (newStatus: PlanOrganizationTaskStatus): Promise<void> => {
    updatePlanOrgTaskStatus(newStatus)
    setShowStatusModal(false)
    setHasChanges(false)
    props.onBack?.()
  }

  const DisplaySelectedPriority = (props: { priority: Priority, rcnId: number }): JSX.Element => {
    switch (props.priority.priorityNumber) {
      case 1:
        return <RCNProvidedProfessionalDevelopment
          onBack={handleBackToOverview}
          rcnId={props.rcnId}
          priorityId={props.priority.id}
        />
      case 2:
        return <STARTEventAttendancePlan onBack={handleBackToOverview} />
      case 3:
        return <EPLITrainerDevelopmentPlan onBack={handleBackToOverview} />
      case 4:
        return <OtherProfessionalDevelopmentResources onBack={handleBackToOverview} />
      default: return <Typography>Unknown Priority</Typography>
    }
  }

  return (
    <Card sx={{
      backgroundColor: 'var(--blue-50)',
      borderRadius: '12px',
      padding: '20px 40px 40px 40px',
      display: 'flex',
      flexDirection: 'column',
      gap: '40px'
    }}
    >
      {selectedPriority != null
        ? <DisplaySelectedPriority priority={selectedPriority} rcnId={props.planOrganizationTask.planOrganizationId} />
        : <>
          <Box data-testid='priorityOneOverview' sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                data-testid="priorityActivitiesButton"
                onClick={handleBackToPriorityActivities}
                sx={{
                  padding: '5px 10px',
                  backgroundColor: 'var(--grey-50)',
                  color: 'var(--grey-500)'
                }}
              >
                <ArrowBack sx={{ mr: '10px' }} />
                Priority Activities
              </Button>

              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '13px'
              }}
              >
                <PriorityStatusButton
                  currentStatus={planOrganizationTask.status}
                  onChange={updatePlanOrgTaskStatus}
                  isReviewer={isReviewer}
                  disabled={isUpdating}
                />

                <ToggleCommentsButton onClick={handleShowComments} />
                {showComments &&
                  <Comments
                    onClose={handleShowComments}
                    parentType={CommentParentType.PlanOrganizationTask}
                    parentId={props.planOrganizationTask.id}
                  />}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Box sx={{
                backgroundColor: 'var(--blue-500)',
                color: 'var(--white)',
                minWidth: '91px',
                minHeight: '91px',
                display: 'grid',
                placeContent: 'center',
                fontSize: '40px',
                borderRadius: '12px'
              }}
              >01</Box>

              <Typography variant="h4" fontWeight='800' color='var(--grey-900)'>
                Professional Development & Resource Dissemination
              </Typography>
            </Box>
          </Box>

          <Box sx={{ backgroundColor: 'var(--white)', border: '1px solid var(--blue-300)', borderRadius: '8px', padding: '20px' }}>
            <Typography variant="h5" color='var(--blue-700)'>Overall Goals</Typography>
            <p style={{ margin: '10px 0' }}>Bacon ipsum dolor amet bacon tongue strip steak, meatloaf drumstick porchetta doner ground round capicola boudin corned beef rump landjaeger chicken:</p>
            <ul style={{ margin: '0' }}>
              <li>Develop and implement a PD plan in collaboration with START trainers and informed by relevant mapping data.</li>
              <li>Deliver PD using START module content, materials, and resources.</li>
              <li>Include coaching follow up in the PD plan to ensure implementation of training content.</li>
            </ul>
          </Box>

          <Typography variant="h4" fontWeight='700'>Core Activities</Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '26px' }}>
            {props.planOrganizationTask.priorities.map(priority =>
              <CoreActivityCard key={priority.priorityNumber} name={priority.compellingWhy} onClick={() => { handlePriorityClick(priority) }} />
            )}
          </Box>
        </>}

      <StatusSelectionReminiderModal
        open={showStatusModal}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        currentStatus={planOrganizationTask.status}
        isReviewer={isReviewer}
        disabled={isUpdating}
      />
    </Card>
  )
}
