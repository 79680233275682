import { Button, Card, Typography } from '@mui/material'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { ArrowForward } from '@mui/icons-material'
import { type MouseEventHandler } from 'react'

interface Props {
  name: string
  imageKey?: string
  buttonText: string
  onClick: MouseEventHandler<HTMLButtonElement>
  dataTestId?: string
  buttonDataTestId?: string
}
const cardColorsList = ['var(--red-50)', 'var(--yellow-50)', 'var(--green-50)', 'var(--blue-50)', 'var(--purple-50)']
const avatarColorsList = ['var(--red-500)', 'var(--yellow-500)', 'var(--green-600)', 'var(--blue-500)', 'var(--purple-500)']

export const OrgCard = (props: Props): JSX.Element => {
  const backgroundColor = cardColorsList[props.name.charCodeAt(0) % cardColorsList.length]
  const avatarColor = avatarColorsList[props.name.charCodeAt(0) % avatarColorsList.length]

  return (
    <Card data-testid={props?.dataTestId ?? 'org-card'} sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '13.5px',
      alignItems: 'center',
      maxWidth: '285px',
      width: '100%',
      height: '270px',
      borderRadius: '16px',
      padding: '24px',
      backgroundColor
    }}
    >
      <Typography
        variant='h6'
        width='100%'
        textOverflow='ellipsis'
        overflow='hidden'
        whiteSpace='nowrap'
        title={props.name}
      >{props.name}</Typography>

      <ProfileImage
        width='7.5em'
        height='7.5em'
        backgroundColor={avatarColor}
        name={props.name}
        imageKey={props.imageKey}
      />

      <Button
        data-testid={props?.buttonDataTestId ?? 'org-card-button'}
        onClick={props.onClick}
        sx={{
          padding: '12px 24px',
          backgroundColor: 'var(--white)',
          color: 'var(--grey-700)'
        }}
      >
        {props.buttonText} <ArrowForward sx={{ ml: '10px' }} />
      </Button>
    </Card>
  )
}
