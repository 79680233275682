import { type Dispatch, type RefObject, type SetStateAction, type SyntheticEvent, useEffect, useState } from 'react'
import { GetComments } from '../services/CommentService'
import { type CommentParentType, type CommentModel } from '../models/Comment'
import { type User } from '../models/User'
import { type UserSearchValues } from '../pages/customComponents/user/Search'
import { GetUsersFromSearchCriteria } from '../services/UserManagementService'

interface CommentType {
  comments: CommentModel[]
  setComments: Dispatch<SetStateAction<CommentModel[]>>
  loading: boolean
}

export const useComments = (parentId: number, parentType: CommentParentType): CommentType => {
  const [loading, setLoading] = useState<boolean>(true)
  const [comments, setComments] = useState<CommentModel[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const comments = await GetComments(parentId, parentType)
      setComments(Array.isArray(comments) ? comments : [])
      setLoading(false)
    }

    void fetchData()
  }, [parentId, parent])

  const sortedComments = comments.sort((a, b) => new Date(b.dateStamp).getTime() - new Date(a.dateStamp).getTime())

  return { comments: sortedComments, setComments, loading }
}

interface TaggingType {
  loading: boolean
  suggestions: User[]
  tagging: boolean
  handleSuggestionClick: (user: User) => void
  handleInput: (e: SyntheticEvent) => void
}
export const useTagging = (
  orgId: number,
  contentEditableRef: RefObject<HTMLDivElement>,
  setContent: Dispatch<SetStateAction<string>>
): TaggingType => {
  const [loading, setLoading] = useState<boolean>(false)
  const [tagQuery, setTagQuery] = useState<string>('')
  const [suggestions, setSuggestions] = useState<User[]>([])
  const [tagging, setTagging] = useState(false)

  const getUserSearchValues = (query: string): UserSearchValues => {
    return {
      primaryOrgId: orgId,
      secondaryOrgId: null,
      building: null,
      accessStatus: null,
      name: query
    }
  }

  useEffect(() => {
    const fetchSuggestions = async (): Promise<void> => {
      if (tagQuery.length > 2) {
        setLoading(true)
        const searchValues = getUserSearchValues(tagQuery)
        const users = await GetUsersFromSearchCriteria(searchValues)
        setSuggestions(users)
      } else {
        setSuggestions([])
      }
      setLoading(false)
    }

    let fetchSuggestionsTimeoutId: NodeJS.Timeout | null = null
    if (tagging) {
      clearTimeout(fetchSuggestionsTimeoutId ?? undefined) // Clear previous timeouts
      fetchSuggestionsTimeoutId = setTimeout(() => {
        void fetchSuggestions()
      }, 300) // Adjust delay as needed
    }

    return () => { clearTimeout(fetchSuggestionsTimeoutId ?? undefined) }
  }, [tagging, tagQuery, orgId])

  const handleSuggestionClick = (user: User): void => {
    const selection = window.getSelection()
    const range = selection?.getRangeAt(0)
    if ((contentEditableRef.current != null) && (range != null)) {
      // Calculate start of tag query
      const tagStart = range.startOffset - tagQuery.length - 1 // -1 includes the "@"

      // Create a new range to cover the entire tag query
      const tagRange = document.createRange()
      tagRange.setStart(range.startContainer, tagStart)
      tagRange.setEnd(range.endContainer, range.endOffset)

      // Delete the entire tag query
      tagRange.deleteContents()

      // create span element for the tag color and user id
      const tagSpan = document.createElement('span')
      tagSpan.textContent = `@${user.firstName}${user.lastName}`
      tagSpan.style.color = 'var(--blue-300)'
      tagSpan.dataset.userEmail = user.email

      // Insert the tag span
      range.insertNode(tagSpan)

      // Move the caret to right after the placeholder
      const placeholder = document.createTextNode('\u200B') // Zero-width space
      tagSpan.parentNode?.appendChild(placeholder)
      range.setStartAfter(placeholder)

      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)

      setTagging(false)
      setSuggestions([])

      const newText = contentEditableRef.current.innerHTML ?? ''
      setContent(newText)
    }
  }

  const handleInput = (e: SyntheticEvent): void => {
    e.preventDefault()
    const value = e.currentTarget.innerHTML ?? ''
    setContent(value)

    // Strip any trailing </div> tag from the value before checking for "@"
    const strippedValue = value.replace(/<\/div>\s*$/, '')

    const lastAtIndex = strippedValue.lastIndexOf('@')

    if (lastAtIndex !== -1) {
      const query = strippedValue.substring(lastAtIndex + 1)
      if (query.length > 2 && !/\s|[.,!?;]/.test(query)) {
        setLoading(true)
        setTagging(true)
        setTagQuery(query)
      } else {
        setTagging(false)
        setTagQuery('')
        setSuggestions([])
      }
    } else {
      setTagging(false)
      setTagQuery('')
      setSuggestions([])
    }
  }

  return { loading, suggestions, tagging, handleSuggestionClick, handleInput }
}

export const extractTaggedUserEmails = (text: string): string[] => {
  const userEmails: string[] = []
  const tempDiv = document.createElement('div') // create temp div to query
  tempDiv.innerHTML = text

  const tagSpans = tempDiv.querySelectorAll('span[data-user-email]')

  tagSpans.forEach(span => {
    const userEmail = (span.getAttribute('data-user-email') ?? '').trim()
    if (userEmail !== '') {
      userEmails.push(userEmail)
    }
  })

  return Array.from(new Set(userEmails)) // remove duplicates
}
