import { Box, type SxProps, Typography } from '@mui/material'

interface DateEvent {
  title: string
  date: Date
  startTime?: Date
  endTime?: Date
}

interface Props {
  event: DateEvent
  eventBoxStyle?: SxProps
  hideDateLine?: boolean
  description?: string
}

export const EventDisplay = (props: Props): JSX.Element => {
  const getDateLine = (event: DateEvent): string => {
    let dateLine = new Date(event.date).toLocaleDateString([], { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' })
    if (event.startTime != null) {
      dateLine += ` - ${event.startTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
    }
    if (event.endTime != null) {
      if (event.startTime != null) {
        dateLine += '-'
      } else {
        dateLine += ' - '
      }
      dateLine += event.endTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
    }
    return dateLine
  }

  const getShortDate = (): string => {
    return new Date(props.event.date).toLocaleDateString([], { month: 'short' })
  }

  const getNumericDay = (): string => {
    return new Date(props.event.date).toLocaleDateString([], { day: 'numeric' })
  }

  return <Box sx={{ display: 'flex' }}>
    <Box sx={{ color: 'white', backgroundColor: 'var(--purple-500)', px: '.5em', py: '.125em', alignContent: 'center', borderRadius: '.25em', width: '1.5em', height: '2.25em', ...props.eventBoxStyle }}>
      <Typography sx={{ textAlign: 'center', fontSize: '.75em' }}>{getShortDate()}</Typography>
      <Typography sx={{ textAlign: 'center', fontSize: '1.25em', lineHeight: 1 }}>{getNumericDay()}</Typography>
    </Box>

    <Box sx={{ ml: '1em', alignContent: 'center' }}>
      <Typography sx={{ fontWeight: '600' }}>{props.event.title}</Typography>
      {props.hideDateLine !== true && <Typography>{getDateLine(props.event)}</Typography>}
      {props.description != null && <Typography sx={{ fontSize: '.75em' }}>{props.description}</Typography> }
    </Box>
  </Box>
}
