import { Button, type SxProps } from '@mui/material'
import { type MouseEvent } from 'react'

interface BlockButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  backgroundColor?: string
  color?: string
  hoverBackgroundColor?: string
  hoverColor?: string
  hover?: {
    background?: string
    color?: string
  }
  disabledSx?: {
    color?: string
  }
  sx?: SxProps
  dataTestId?: string
  children?: React.ReactNode
}

export const BlockButton = (props: BlockButtonProps): JSX.Element => {
  return <Button
    variant='contained'
    onClick={props.onClick}
    disabled={props.disabled}
    data-testid={props.dataTestId ?? 'block-button'}
    sx={{
      ...props.sx,
      textTransform: 'none',
      borderRadius: '.25em',
      backgroundColor: props.backgroundColor ?? 'white',
      color: props.color ?? 'black',
      border: '1px solid var(--border)',
      boxShadow: 'none',
      '&:hover': props.hover ?? {
        backgroundColor: props.hoverBackgroundColor ?? 'var(--grey-100)',
        color: props.hoverColor ?? 'black'
      },
      '&:disabled': {
        color: props.color ?? 'black',
        ...props.disabledSx
      }
    }}
  >
    {props.children}
  </Button>
}
