import { Box, Card, CircularProgress, Menu, MenuItem, Typography } from '@mui/material'
import { useEffect, useState, type MouseEvent } from 'react'
import { type SelectOption } from '../../customComponents/SelectWithLabel'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { AddMeetingModal } from './AddMeetingModal'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { FormSelect } from '../../customComponents/FormSelect'
import type { AnnualPlanMeeting } from '../../../models/AnnualPlanMeeting'
import { ChangeTaskStatus, DeleteMeeting, GetAnnualPlanMeetings, GetAnnualPlanTeam, GetPlanOrganizationTask } from '../../../services/AnnualPlanService'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { CommentParentType } from '../../../models/Comment'
import { Comments } from '../../customComponents/comments/Comments'
import { ToggleCommentsButton } from '../../customComponents/comments/ToggleCommentsButton'
import { PlanOrganizationTaskName, PlanOrganizationTaskStatus } from '../../../models/PlanOrganizationTask'
import { UserDetailsView } from '../../customComponents/user/DetailsView'
import { LocationOnOutlined } from '@mui/icons-material'
import { IconWithLabel } from '../../customComponents/IconWithLabel'
import type { Address } from '../../../models/Address'
import { EventDisplay } from '../../customComponents/EventDisplay'
import IconAccordion from '../../customComponents/IconAccordion'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import type { AddOrEditAnnualPlanMeetingRequest } from '../../../models/requestObjects/AnnualPlanRequestObjects'
import type { AnnualPlanTeamMember } from '../../../models/AnnualPlanTeamMember'
import { useAuth } from '../../../hooks/use-auth'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'
import { toast } from 'react-toastify'
import { Modal } from '../../customComponents/Modal'

interface Props {
  annualPlanId: number
  rcnId: number
}

export function MeetingSchedules (props: Props): JSX.Element {
  const statusOptions: Array<SelectOption<string>> = Object.values(PlanOrganizationTaskStatus).map((key) => ({ id: key, name: key }))
  const [selectedStatus, setSelectedStatus] = useState<PlanOrganizationTaskStatus>()
  const [showAddMeeting, setShowAddMeeting] = useState(false)
  const [meetings, setMeetings] = useState<AnnualPlanMeeting[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showComments, setShowComments] = useState(false)
  const [planOrganizationTaskId, setPlanOrganizationTaskId] = useState<number>()
  const [isMeetingMenuOpen, setIsMeetingMenuOpen] = useState(false)
  const [meetingEllipses, setMeetingEllipses] = useState<SVGSVGElement | null>(null)
  const [editingMeeting, setEditingMeeting] = useState<AddOrEditAnnualPlanMeetingRequest | undefined>(undefined)
  const [menuPlanMeeting, setMenuPlanMeeting] = useState<AnnualPlanMeeting>()
  const [teamMembers, setTeamMembers] = useState<AnnualPlanTeamMember[]>([])
  const auth = useAuth()
  const [readOnly, setReadOnly] = useState<boolean>(true)
  const [statusPromptShown, setStatusPromptShown] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const openMeetingMenu = (e: MouseEvent<SVGSVGElement>, meeting: AnnualPlanMeeting): void => {
    setMenuPlanMeeting(meeting)
    setMeetingEllipses(e.currentTarget)
    setIsMeetingMenuOpen(true)
  }

  const fetchMeetings = async (): Promise<void> => {
    const tmpMeetings = await GetAnnualPlanMeetings(props.annualPlanId, props.rcnId)
    setMeetings(tmpMeetings)
  }

  const fetchTeam = async (): Promise<void> => {
    const team = await GetAnnualPlanTeam(props.annualPlanId)
    setTeamMembers(team)
    const currentMember = team.find(tm => tm.userId === auth.user?.id)
    if (currentMember?.annualPlanRole === AnnualPlanRole.PlanAdministrator) {
      setReadOnly(false)
    }
  }

  const fetchTaskAndMeetings = async (): Promise<void> => {
    const task = await GetPlanOrganizationTask(props.annualPlanId, props.rcnId, PlanOrganizationTaskName.MeetingSchedules)
    setPlanOrganizationTaskId(task.id)
    const status = task.status
    setSelectedStatus(status)
    const tmpMeetings = await GetAnnualPlanMeetings(props.annualPlanId, props.rcnId)
    setMeetings(tmpMeetings)
    if (tmpMeetings.length > 0 && status === PlanOrganizationTaskStatus.Not_Started) {
      if (!statusPromptShown) {
        toast.warning('A meeting exists, please update the status from not started.')
        setStatusPromptShown(true)
      }
    }
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await Promise.all([
        fetchTeam(),
        fetchTaskAndMeetings()
      ])
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const handleStatusChange = async (event: any): Promise<void> => {
    const status = event.target.value as PlanOrganizationTaskStatus
    await ChangeTaskStatus(props.annualPlanId, props.rcnId, PlanOrganizationTaskName.MeetingSchedules, status)
    setSelectedStatus(status)
  }

  const addMeetingClick = (): void => {
    setEditingMeeting(undefined)
    setShowAddMeeting(true)
  }

  const updateMeetings = async (closeReason: string): Promise<void> => {
    if (closeReason === 'canceled') {
      setShowAddMeeting(false)
      return
    }
    await fetchMeetings()
    setShowAddMeeting(false)
    if (closeReason === 'saved') {
      toast.success('Meeting has been saved.')
    } else if (closeReason === 'updated') {
      toast.success('Meeting has been updated.')
    } else {
      toast.success('Meeting has been deleted.')
    }
  }

  const handleShowComments = (): void => {
    setShowComments((show) => !show)
  }

  const editMeeting = async (): Promise<void> => {
    if (menuPlanMeeting?.id != null) {
      setIsMeetingMenuOpen(false)
      const editMeeting: AddOrEditAnnualPlanMeetingRequest = {
        budgetRequestAmount: menuPlanMeeting.budgetRequestAmount?.toString() ?? '',
        id: Number(menuPlanMeeting.id),
        location: menuPlanMeeting.address,
        title: menuPlanMeeting.title,
        description: menuPlanMeeting.description,
        meetingDate: menuPlanMeeting.meetingDate,
        primaryContactId: menuPlanMeeting.primaryContact?.id,
        orgId: props.rcnId,
        annualPlanId: props.annualPlanId
      }
      setEditingMeeting(editMeeting)
      setShowAddMeeting(true)
    }
  }

  const startDeleteMeeting = async (): Promise<void> => {
    setShowConfirmDelete(true)
    setIsMeetingMenuOpen(false)
  }

  const continueDeleteMeeting = async (): Promise<void> => {
    setShowConfirmDelete(false)
    if (menuPlanMeeting?.id != null) {
      await DeleteMeeting(menuPlanMeeting.id ?? 0)
    }
    await updateMeetings('deleted')
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const stringFromAddress = (adr: Address): string => {
    const addressString = `${adr.street}${adr.apartment == null ? '' : ` ${adr.apartment}`} ${adr.city}, ${adr.state} ${adr.zip}`
    return addressString
  }

  const getComponentKey = (id: string, title: string, descriptor?: string): string => {
    return `${id}${title}${descriptor ?? ''}`
  }

  return <Card sx={{ backgroundColor: 'var(--grey-100)', p: '1em' }}>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'right', alignContent: 'center' }}>
        <Box>
          <FormSelect
            readOnly={readOnly}
            formControlProps={{ sx: { minWidth: '200px' } }}
            name='planStatusSelect'
            value={selectedStatus}
            options={statusOptions}
            onChange={handleStatusChange}
            hideLabel
            selectProps={{
              renderValue: (value: any) =>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {value}
                </Box>
            }}
          />
        </Box>
        <ToggleCommentsButton onClick={handleShowComments} iconButtonProps={{ sx: { height: '40px', width: '40px', alignSelf: 'center', ml: '.75em' } }} />
        {showComments &&
          <Comments
            onClose={handleShowComments}
            parentType={CommentParentType.PlanOrganizationTask}
            parentId={planOrganizationTaskId ?? 0}
          />}
      </Box>
    </Box>
    <Box sx={{ width: '100%' }}>
      { !readOnly &&
        <BlueButton onClick={addMeetingClick} dataTestId='addMeetingButton'>
          Add Meeting
          <AddCircleIcon sx={{ ml: '0.5em' }} />
        </BlueButton>
      }
      {/* TODO: Implement Things to Consider here */}
    </Box>
    <Box>

    </Box>
    {showAddMeeting && <AddMeetingModal teamMembers={teamMembers} editMeeting={editingMeeting} orgId={props.rcnId} annualPlanId={props.annualPlanId} onClose={updateMeetings}/>}
    <Card data-testid='cardContainer' sx={{ minHeight: '400px', width: '100%', mt: '1em' }}>
      { meetings.length === 0 &&
        <Box sx={{ justifySelf: 'center', alignSelf: 'center', width: '300px' }}>
          <Box sx={{ justifySelf: 'center', mb: '.75em' }}>
            <CalendarMonthIcon sx={{ color: 'var(--blue-300)', width: '150px', height: '150px' }} />
            <Typography variant='body1'>No Events Added</Typography>
          </Box>
          <Typography variant='body2'>Use the Add New button to add meetings and events you plan to host or attend to your Annual Plan.</Typography>
        </Box>
      }
      { meetings.length > 0 && meetings.map((meeting) =>
        <Box data-testid={getComponentKey(meeting.id?.toString() ?? '', meeting.title)} key={getComponentKey(meeting.id?.toString() ?? '', meeting.title)} sx={{ width: '60%', m: '1em' }}>
          <IconAccordion
            dataTestId={getComponentKey(meeting.id?.toString() ?? '', meeting.title, 'accordion')}
            expanded={false}
            expandButtonKey={getComponentKey(meeting.id?.toString() ?? '', meeting.title, 'expand')}
            accordionSummary={<Box sx={{ display: 'grid', alignItems: 'center' }}>
              <EventDisplay eventBoxStyle={{ width: '2.25em', height: '3.375em', my: '.5em' }} event={{ title: meeting.title, date: meeting.meetingDate }} description={meeting.description} />
              <Typography sx={{ fontSize: '.75em' }}>{}</Typography>
            </Box>
            }
            showOptions
            optionsElement={<Box>
              <MoreHorizIcon
                aria-label='menu'
                data-testid={`${meeting.id ?? 0}meetingEllipses`}
                sx={{ cursor: 'pointer', ml: '1em' }}
                onClick={(e) => { openMeetingMenu(e, meeting) }}
              />
              <Menu open={isMeetingMenuOpen} anchorEl={meetingEllipses} onClose={() => { setIsMeetingMenuOpen(false) }}>
                <MenuItem
                  data-testid='editMeeting'
                  key={`${meeting.title}`}
                  onClick={editMeeting}
                >
                  <Typography data-testid={`${meeting.id ?? 0}editMenuItem`}>Edit</Typography>
                </MenuItem>
                <MenuItem
                  data-testid='deleteMeeting'
                  onClick={startDeleteMeeting}
                >
                  Remove
                </MenuItem>
              </Menu>
            </Box>}
            accordionDetails={<Box sx={{ ml: '2em' }}>
              <IconWithLabel
                icon={<LocationOnOutlined />}
                whiteSpace='normal'
                labelText={stringFromAddress(meeting.address)}
                dataTestId='address'
              />
              { meeting.primaryContact !== null ? <Box sx={{ width: '50%', mt: '.5em' }}><UserDetailsView user={meeting.primaryContact} allowEdit={false} /></Box> : <></> }
            </Box>
            }
          />
        </Box>
      )}
      { showConfirmDelete &&
        <Modal
          title='Confirm Meeting Delete'
          onClose={() => { setShowConfirmDelete(false) }}
          open={true} onConfirm={continueDeleteMeeting}
          bodyContent='Are you sure you would like to delete this meeting?'
          confirmButtonText='Yes'
          cancelButtonText='No'
        />
      }
    </Card>
  </Card>
}
