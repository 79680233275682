import { Close } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { type MouseEventHandler } from 'react'
import { useAuth } from '../../../hooks/use-auth'
import { useComments } from '../../../hooks/use-comments'
import { CommentsList } from './CommentsList'
import { type CommentParentType } from '../../../models/Comment'

interface CommentsProps {
  onClose: MouseEventHandler<HTMLButtonElement>
  parentId: number
  parentType: CommentParentType
}

export const Comments = (props: CommentsProps): JSX.Element => {
  const auth = useAuth()
  const { comments, setComments, loading } = useComments(props.parentId, props.parentType)

  if (auth.user === null) return <></>

  const sortedComments = comments.sort((a, b) => new Date(b.dateStamp).getTime() - new Date(a.dateStamp).getTime())

  return <Box sx={{
    borderRadius: '12px 0 0 12px',
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    backgroundColor: 'var(--grey-100)',
    maxWidth: '450px',
    width: '100%',
    padding: '22px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '34px',
    margin: '95px 0 29px 0',
    boxShadow: '0px 4px 4px 0px #00000040'
  }}
  >
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
    >
      <Typography variant='h3' className='page-header'> Comments </Typography>

      <IconButton onClick={props.onClose}>
        <Close />
      </IconButton>
    </Box>

    {loading
      ? <CircularProgress />
      : <CommentsList
          comments={sortedComments}
          setComments={setComments}
          currentUser={auth.user}
          parentId={props.parentId}
          parentType={props.parentType}
      />}
  </Box>
}
