import { useState } from 'react'
import { type UpdateTrainingFormValues } from '../pages/pageComponents/planning/AddEditTrainingModal'
import { type RCNPD } from '../models/RCNPD'
import { DeleteRCNPD } from '../services/AnnualPlanService'
import { toast } from 'react-toastify'
import { useTrainingsContext } from '../contextProviders/TrainingProvider'

interface UseTrainingsType {
  handleSavedTraining: () => void
  isLoading: boolean
  seriesTrainings: RCNPD[]
  singleTrainings: RCNPD[]
}
export const useTrainings = (): UseTrainingsType => {
  const { trainings, isLoading, reloadTrainings } = useTrainingsContext()

  const handleSavedTraining = (): void => {
    void reloadTrainings()
  }

  const seriesTrainings = trainings.filter(pd => pd.isSeries)
  const singleTrainings = trainings.filter(pd => !pd.isSeries)

  return {
    handleSavedTraining,
    isLoading,
    seriesTrainings,
    singleTrainings
  }
}

interface UseTrainingModalType {
  showTrainingModal: boolean
  initialValues: UpdateTrainingFormValues | undefined
  handleEditTraining: (training: RCNPD) => void
  handleCloseModal: () => void
  handleAddTraining: () => void
}
export const useTrainingModal = (): UseTrainingModalType => {
  const [showTrainingModal, setShowTrainingModal] = useState(false)
  const [initialValues, setInitialValues] = useState<UpdateTrainingFormValues>()

  const handleEditTraining = (training: RCNPD): void => {
    const initialValues: UpdateTrainingFormValues = {
      id: training.id,
      priorityId: training.priorityId,
      title: training.title,
      organizationIds: training.organizations.map(o => o.id),
      trainingFormat: training.trainingFormat,
      scope: training.scope,
      open: training.open ? 'true' : 'false',
      resourcesNeeded: training.resourcesNeeded,
      budgetRequest: training.budgetRequest.replace('$', ''),
      isSeries: training.isSeries
    }
    setInitialValues(initialValues)
    setShowTrainingModal(true)
  }

  const handleCloseModal = (): void => {
    setShowTrainingModal(false)
  }

  const handleAddTraining = (): void => {
    setInitialValues(undefined)
    setShowTrainingModal(true)
  }

  return {
    showTrainingModal,
    initialValues,
    handleEditTraining,
    handleCloseModal,
    handleAddTraining
  }
}

interface UseTrainingDeletionType {
  trainingToDelete: RCNPD | null
  setTrainingToDelete: (training: RCNPD | null) => void
  handleDeleteTraining: (training: RCNPD) => Promise<void>
}
export const useTrainingDeletion = (): UseTrainingDeletionType => {
  const { reloadTrainings } = useTrainingsContext()
  const [trainingToDelete, setTrainingToDelete] = useState<RCNPD | null>(null)

  const handleDeleteTraining = async (training: RCNPD): Promise<void> => {
    const { success, errors } = await DeleteRCNPD(training.id)

    if (!success) {
      errors.forEach(e => toast.error(e))
      return
    }

    await reloadTrainings()
    toast.success(`Training ${training.isSeries ? 'Series' : 'Event'} Deleted`)
    setTrainingToDelete(null)
  }

  return {
    trainingToDelete,
    setTrainingToDelete,
    handleDeleteTraining
  }
}
