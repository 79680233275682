import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { OrgCard } from './OrgCard'
import { GetPlanOrganizationsForAnnualPlan } from '../../../services/AnnualPlanService'
import { type PlanOrganization } from '../../../models/PlanOrganization'

interface Props {
  annualPlanId: number
  onPlanOrgSelect: (planOrg: PlanOrganization) => void
}

export const PlanOrganizationSelection = (props: Props): JSX.Element => {
  const [planOrgs, setPlanOrgs] = useState<PlanOrganization[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getPlanOrgs = async (): Promise<void> => {
      setIsLoading(true)

      const planOrgs = await GetPlanOrganizationsForAnnualPlan(props.annualPlanId)

      setPlanOrgs(planOrgs)
      setIsLoading(false)
    }

    void getPlanOrgs()
  }, [props.annualPlanId])

  if (isLoading) {
    return <CircularProgress />
  }

  return <Box data-testid="planOrgSelection" sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  }}
  >
    <Typography variant='h6'>ISDs</Typography>
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '33px'
    }}
    >
      {planOrgs.map((planOrg) =>
        <OrgCard
          key={planOrg.organizationId}
          name={planOrg.organization.name}
          imageKey={planOrg.organization.imageKey}
          buttonText='View Activities'
          onClick={() => { props.onPlanOrgSelect(planOrg) }}
          dataTestId='planOrgCard'
          buttonDataTestId='viewActivitiesButton'
        />)}
    </Box>
  </Box>
}
