import { type Priority } from './Priority'

export interface PlanOrganizationTask {
  id: number
  taskName: string
  status: PlanOrganizationTaskStatus
  organizationName: string
  planOrganizationId: number
  organizationId: number
  annualPlanId: number
  dueDate: Date
  priorities: Priority[]
}

export enum PlanOrganizationTaskStatus {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
  Not_Started = 'Not Started',
  In_Progress = 'In Progress',
  Submit_For_Review = 'Submit for Review',
  Needs_Revision = 'Needs Revision'
}

export enum PlanOrganizationTaskName {
  PriorityOne = 'Priority One',
  PriorityTwo = 'Priority Two',
  PriorityThree = 'Priority Three',
  PriorityFour = 'Priority Four',
  MeetingSchedules = 'Meeting Schedules',
}
