import { useEffect, useState } from 'react'
import { AnnualPlanRole } from '../models/AnnualPlanRole'
import { type PlanOrganization } from '../models/PlanOrganization'
import { useAuth } from './use-auth'
import { GetAnnualPlanTeam, GetPlanOrganizationsForAnnualPlan } from '../services/AnnualPlanService'

interface UsePriorityActivitiesProps {
  annualPlanId: number
  onPlanChange?: (orgName: string | null) => void
}

interface PriorityActivitiesType {
  error: string | null
  isLoading: boolean
  selectedPlanOrg: PlanOrganization | undefined
  showPlanOrgSelection: boolean
  userRole: AnnualPlanRole | undefined
  handlePlanOrgSelected: (planOrg: PlanOrganization) => void
  handleReviewOthers: () => void
}

export const usePriorityActivities = ({
  annualPlanId,
  onPlanChange
}: UsePriorityActivitiesProps): PriorityActivitiesType => {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPlanOrg, setSelectedPlanOrg] = useState<PlanOrganization>()
  const [showPlanOrgSelection, setShowPlanOrgSelection] = useState(false)
  const [userRole, setUserRole] = useState<AnnualPlanRole>()
  const { user } = useAuth()

  useEffect(() => {
    const checkAccess = async (): Promise<void> => {
      if (user == null) return

      try {
        const planMembers = await GetAnnualPlanTeam(annualPlanId)
        const member = planMembers.find(member => member.userId === user.id)

        if (member == null) {
          setError('User is not a member of this plan')
          return
        }

        setUserRole(member.annualPlanRole as AnnualPlanRole)

        if (member.annualPlanRole === AnnualPlanRole.PlanAdministrator ||
          member.annualPlanRole === AnnualPlanRole.PlanReviewer) {
          setShowPlanOrgSelection(true)
        } else if (member.annualPlanRole === AnnualPlanRole.PlanContributor) {
          const planOrgs = await GetPlanOrganizationsForAnnualPlan(annualPlanId)
          const planOrg = planOrgs.find(po => po.organizationId === member.organizatonId)
          setSelectedPlanOrg(planOrg)
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred')
      } finally {
        setIsLoading(false)
      }
    }

    void checkAccess()
  }, [annualPlanId, user])

  const handlePlanOrgSelected = (planOrg: PlanOrganization): void => {
    setSelectedPlanOrg(planOrg)
    setShowPlanOrgSelection(false)
    onPlanChange?.(planOrg.organization.name)
  }

  const handleReviewOthers = (): void => {
    setShowPlanOrgSelection(true)
    onPlanChange?.(null)
  }

  return {
    error,
    isLoading,
    selectedPlanOrg,
    showPlanOrgSelection,
    userRole,
    handlePlanOrgSelected,
    handleReviewOthers
  }
}
