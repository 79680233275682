import { Box, Typography, type SelectChangeEvent } from '@mui/material'
import { Modal, TextFieldWithLabel } from '@oaisd/michdev.components.react'
import { type ChangeEvent, useState, useEffect, useContext } from 'react'
import { WarningCard } from '../../../customComponents/WarningCard'
import { SelectWithLabel } from '../../../customComponents/SelectWithLabel'
import { type FieldError } from '../../../../hooks/use-fetch'
import { toast } from 'react-toastify'
import { AddAdminContact, GetUserOptionsForOrg } from '../../../../services/UserManagementService'
import { type SelectableOption } from '../../../../utils/genericTypes'
import { BannerModal } from '../../../customComponents/BannerModal'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../../Routes'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'
import { OrgTabs } from '../OrganizationDetails'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../../../contextProviders/BreadcrumbProvider'

export interface AddContactForm {
  userId?: number | ''
  organizationId: number
  firstName: string
  lastName: string
  email: string
}

interface Props {
  primaryOrg: OrganizationDetailsModel
  onClose: () => void
  onSave: () => void
}

export function AddContactModal (props: Props): JSX.Element {
  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const [duplicateUserId, setDuplicateUserId] = useState<number | null>(null)
  const [showDuplicateUserModal, setShowDuplicateUserModal] = useState<boolean>(false)
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [userOptions, setUserOptions] = useState<SelectableOption[]>([])
  const [formValues, setFormValues] = useState<AddContactForm>({
    organizationId: props.primaryOrg.id,
    firstName: '',
    lastName: '',
    email: ''
  })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const options = await GetUserOptionsForOrg(props.primaryOrg.id)
      setUserOptions(options)
    }

    void fetchData()
  }, [])

  const handleSave = async (): Promise<void> => {
    const { response, success, errors } = await AddAdminContact(formValues)
    if (!success) {
      if (response.id != null) {
        setDuplicateUserId(response.id)
        setShowDuplicateUserModal(true)
        return
      } else {
        setFieldErrors(response.fieldErrors)
        errors.forEach((error) => {
          toast.error(error)
        })
        return
      }
    }

    toast.success('Admin Contact was added')
    props.onClose()
    props.onSave()
  }

  const handleFieldUpdate = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleUserSelect = (e: SelectChangeEvent<number>): void => {
    const userId = e.target.value as number
    setFormValues({ ...formValues, userId, firstName: '', lastName: '', email: '' })
  }

  const goToProfile = (userId: number): void => {
    const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.Overview })
    setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

    const profileRoute = generatePath(AppRoutes.USER_DETAILS, { userId: userId.toString(), orgId: null })
    nav(profileRoute)
  }

  const content = <Box>
    <SelectWithLabel
      name='userId'
      label='Select Contact'
      value={formValues.userId ?? ''}
      options={userOptions}
      width='100%'
      includeAllOption={true}
      allOptionText='New User'
      onChange={handleUserSelect}
    />

    {(formValues.userId == null || formValues.userId === '') && <>
      <TextFieldWithLabel
        name='firstName'
        label='First Name'
        value={formValues.firstName}
        required={fieldErrors.some((error) => error.fieldName === 'firstName')}
        showRequiredText={fieldErrors.some((error) => error.fieldName === 'firstName')}
        error={fieldErrors.some((error) => error.fieldName === 'firstName')}
        dataTestId='contactFirstName'
        textFieldClassName='pb-0'
        onChange={handleFieldUpdate}
      />
      <TextFieldWithLabel
        name='lastName'
        label='Last Name'
        value={formValues.lastName}
        required={fieldErrors.some((error) => error.fieldName === 'lastName')}
        showRequiredText={fieldErrors.some((error) => error.fieldName === 'lastName')}
        error={fieldErrors.some((error) => error.fieldName === 'lastName')}
        dataTestId='contactLastName'
        textFieldClassName='pb-0'
        onChange={handleFieldUpdate}
      />
      <TextFieldWithLabel
        name='email'
        label='Email'
        value={formValues.email}
        required={fieldErrors.some((error) => error.fieldName === 'email')}
        showRequiredText={fieldErrors.some((error) => error.fieldName === 'email')}
        error={fieldErrors.some((error) => error.fieldName === 'email')}
        dataTestId='contactEmail'
        textFieldClassName='pb-0'
        onChange={handleFieldUpdate}
      />
    </>}
    <WarningCard message='This user will become an administrator for this organization.' />

    {showDuplicateUserModal &&
      <BannerModal
        title='User Already Exists'
        cancelButtonText='Close'
        confirmButtonText='View Profile'
        bodyContent={<Typography>This user already exists. Would you like to see their profile?</Typography>}
        dataTestId='duplicate-user-modal'
        onClose={() => { setShowDuplicateUserModal(false) }}
        onConfirm={() => { goToProfile(duplicateUserId ?? 0) }}
      />}
  </Box>

  return <Modal
    open={true}
    title='Add Admin Contact'
    confirmButtonText='Save Contact'
    cancelButtonText='Cancel'
    confirmationContent={content}
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    contentSx={{ borderBottom: '1px solid var(--border)' }}
    onClose={props.onClose}
    onConfirm={handleSave}
  />
}
