import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Box, CircularProgress, Typography } from '@mui/material'
import { BreadcrumbNav } from '../../customComponents/BreadcrumbNav'
import { GetOrganization } from '../../../services/OrganizationService'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'
import { AnnualPlanList } from './AnnualPlanList'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useAuth } from '../../../hooks/use-auth'
import { AuthFunctions, hasPermission } from '../../../utils/permissions'
import { AppRoutes } from '../../Routes'

export const PlanningInstance = (): JSX.Element => {
  const { rcnId } = useParams()
  const { user } = useAuth()
  const nav = useNavigate()

  const showOtherRCNPlansBtn = user != null &&
    (hasPermission(user, AuthFunctions.ANNUAL_PLANNING_REVIEW) ||
    hasPermission(user, AuthFunctions.ANNUAL_PLANNING_REVIEW_EDIT))

  const [rcn, setRcn] = useState<OrganizationDetailsModel | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (rcnId == null) {
        return
      }

      const rcn = await GetOrganization(rcnId)
      setRcn(rcn)
    }

    void fetchData()
  }, [rcnId])

  if (rcn == null) {
    return <CircularProgress />
  }

  const handleOtherRcnPlansBtnClick = (): void => {
    if (showOtherRCNPlansBtn) nav(AppRoutes.PLANNING)
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
      }}
      >
        <BreadcrumbNav />
        <Typography variant='h3' className='page-header'> Annual Planning </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
      }}
      >
        <Typography variant='h6'>{rcn.name}</Typography>

        {showOtherRCNPlansBtn && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BlueButton
            dataTestId="other-rcn-plans-btn"
            sx={{ ml: 'auto', padding: '10px 20px' }}
            onClick={handleOtherRcnPlansBtnClick}
          >
            View Other RCN Plans <KeyboardArrowRight fontSize='small' />
          </BlueButton>
        </Box>}

        <AnnualPlanList rcnId={rcn.id} />
      </Box>
    </Box>
  )
}
