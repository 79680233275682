import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SelectProps,
  type FormControlProps,
  Box
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export interface SelectOption<T> { id: T, name: string }

interface Props<T extends string | number | boolean> {
  options: Array<SelectOption<T>>
  value: '' | T | undefined
  onChange: (event: SelectChangeEvent<T>) => void
  name: string
  label?: string
  labelContent?: JSX.Element
  selectProps?: Omit<SelectProps<T>, 'onChange' | 'value' | 'name' | 'required'>
  formControlProps?: FormControlProps
  required?: boolean
  error?: boolean
  hideLabel?: boolean
  readOnly?: boolean
}

export function FormSelect<T extends string | number | boolean> (props: Props<T>): JSX.Element {
  return (
    <FormControl { ...props.formControlProps}>
      { props.hideLabel !== true && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <InputLabel
          id={`${props.name}-label`}
          required={props.required === true}
          error={props.error}
        >
          {props.labelContent ?? props.label}
        </InputLabel>
      </Box>}
      <Select
        disabled={props.readOnly}
        data-testid={`${props.name}-select`}
        id={`${props.name}-select`}
        value={props.value}
        variant={ props.hideLabel === true ? 'outlined' : undefined}
        labelId={ props.hideLabel === true ? undefined : `${props.name}-label`}
        onChange={props.onChange}
        IconComponent={KeyboardArrowDownIcon}
        sx={{ backgroundColor: 'var(--white)', height: '40px' }}
        { ...props.selectProps }
      >
        {props.options?.map((opt) => (
          <MenuItem key={opt.id.toString()} value={opt.id.toString()} role="option" data-testid={'select-item-' + opt.id.toString()}>
            {opt.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
