import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { PriorityOneOverview } from './PriorityOneOverview'
import { type PlanOrganization } from '../../../models/PlanOrganization'
import { PlanOrganizationTaskName, type PlanOrganizationTask } from '../../../models/PlanOrganizationTask'
import { type AnnualPlanRole } from '../../../models/AnnualPlanRole'
import { PriorityActivityCard } from './PriorityActivityCard'

const priorityActivities: Record<number, { taskName: string, name: string, items: string[] }> = {
  1: {
    taskName: PlanOrganizationTaskName.PriorityOne,
    name: 'Professional Development & Resource Dissemination',
    items: [
      'RCN Provided Professional Development',
      'START Event Attendance',
      'EPLI Plan',
      'Other Professional Development Resources'
    ]
  },
  2: {
    taskName: PlanOrganizationTaskName.PriorityTwo,
    name: 'Coaching Structure',
    items: [
      'Development Checklist',
      'Activity Plan'
    ]
  },
  3: {
    taskName: PlanOrganizationTaskName.PriorityThree,
    name: 'Coaching for Implementation of Evidence-based practices',
    items: [
      'CETA Classrooms',
      'Focus Students'
    ]
  },
  4: {
    taskName: PlanOrganizationTaskName.PriorityFour,
    name: 'Peer to Peer Programs',
    items: [
      'Focus Buildings',
      'Program Enrichment'
    ]
  }
}

interface Props {
  planOrganization: PlanOrganization
  userRole: AnnualPlanRole
  onSelectedTaskChange: (selectedTask: PlanOrganizationTask | null) => void
}

export const PriorityActivityOverview = (props: Props): JSX.Element => {
  const [selectedTask, setSelectedTask] = useState<PlanOrganizationTask | null>(null)

  useEffect(() => {
    props.onSelectedTaskChange(selectedTask)
  }, [selectedTask])

  const DisplaySelectedPriority = (props: { planOrganizationTask: PlanOrganizationTask, userRole: AnnualPlanRole }): JSX.Element => {
    const taskName = props.planOrganizationTask.taskName

    switch (taskName) {
      case PlanOrganizationTaskName.PriorityOne:
        return <PriorityOneOverview
          userRole={props.userRole}
          planOrganizationTask={props.planOrganizationTask}
          onBack={() => { setSelectedTask(null) }}
          onPlanOrgTaskUpdate={handleTaskUpdate}
        />
      case PlanOrganizationTaskName.PriorityTwo:
        return <Typography>Priority Two Content</Typography>
      default:
        return <Typography>Unknown Task</Typography>
    }
  }

  const handleTaskUpdate = (task: PlanOrganizationTask): void => {
    const updatedTasks = props.planOrganization.planOrganizationTasks.map(t => t.id === task.id ? task : t)
    props.planOrganization.planOrganizationTasks = updatedTasks

    setSelectedTask(task)
  }

  const handleSelectedTask = (taskName: string): void => {
    const task = props.planOrganization.planOrganizationTasks.find(task => task.taskName === taskName)
    if (task == null) {
      console.error(`Task with id ${taskName} not found`)
      return
    }

    setSelectedTask(task)
  }

  return (
    <Box
      data-testid='activitiesOverview'
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '33px'
      }}
    >
      {selectedTask != null
        ? <DisplaySelectedPriority
            planOrganizationTask={selectedTask}
            userRole={props.userRole}
        />
        : <>
          {Object.keys(priorityActivities).map((key, index) =>
            <PriorityActivityCard
              key={key}
              num={index + 1}
              taskName={priorityActivities[Number(key)].taskName}
              title={priorityActivities[Number(key)].name}
              items={priorityActivities[Number(key)].items}
              handlePriorityContinue={handleSelectedTask}
            />)}
        </>}
    </Box>
  )
}
