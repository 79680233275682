import { ArrowForward } from '@mui/icons-material'
import { Card, Typography, Box } from '@mui/material'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'

interface Props {
  showViewPlan: boolean
  showContinuePlan: boolean
  showArchivePlan: boolean
  showFinalizeSnapshot: boolean
  viewPlan: () => void
  continuePlan: () => void
  archivePlan: () => void
  finalizeCurrentSnapshot: () => void
}

export const PlanActionCard = (props: Props): JSX.Element => {
  return <Card sx={{ backgroundColor: '#F3F3F380', minHeight: '3em', width: '300px', border: '1px solid var(--border)', p: '1em', mb: '1em' }}>
    <Box sx={{ display: 'flex', mb: '1em', justifyContent: 'center' }}>
      <Typography variant='h5' color='#021E40' textAlign='center' fontWeight='600'>
        Plan Actions
      </Typography>
    </Box>

    <Box sx={{ mx: '1em' }}>
      {props.showViewPlan &&
        <BlueButton
          onClick={props.viewPlan}
          sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
          dataTestId='viewPlan'
        >
          View Plan
          <ArrowForward />
        </BlueButton>
      }
      {props.showContinuePlan &&
        <BlueButton
          onClick={props.continuePlan}
          sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: '.5em' }}
          dataTestId='continuePlan'
        >
          Continue Plan
          <ArrowForward />
        </BlueButton>
      }
      {props.showArchivePlan &&
        <BlueButton
          onClick={props.archivePlan}
          sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: '.5em' }}
          dataTestId='archivePlan'
        >
          Archive Plan
          <ArrowForward />
        </BlueButton>
      }
      {props.showFinalizeSnapshot &&
        <BlueButton
          onClick={props.finalizeCurrentSnapshot}
          sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: '.5em' }}
          dataTestId='finalizeCurrentSnapshot'
        >
          Finalize Current Snapshot
          <ArrowForward />
        </BlueButton>
      }
    </Box>
    {/* <Accordion data-testid='actionAccordion'>
      <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ color: 'white', bgcolor: '#726F74', width: '1.125em', height: '1.125em', borderRadius: '50%' }} data-testid='expand' />}>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {props.showViewPlan &&
            <BlueButton
              onClick={props.viewPlan}
              sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
              dataTestId='viewPlan'
            >
              View Plan
              <ArrowForward />
            </BlueButton>
          }
          {props.showContinuePlan &&
            <BlueButton
              onClick={props.continuePlan}
              sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: '.5em' }}
              dataTestId='continuePlan'
            >
              Continue Plan
              <ArrowForward />
            </BlueButton>
          }
          {props.showArchivePlan &&
            <BlueButton
              onClick={props.archivePlan}
              sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: '.5em' }}
              dataTestId='archivePlan'
            >
              Archive Plan
              <ArrowForward />
            </BlueButton>
          }
          {props.showFinalizeSnapshot &&
            <BlueButton
              onClick={props.finalizeCurrentSnapshot}
              sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: '.5em' }}
              dataTestId='finalizeCurrentSnapshot'
            >
              Finalize Current Snapshot
              <ArrowForward />
            </BlueButton>
          }
        </Box>
      </AccordionDetails>
    </Accordion> */}
  </Card>
}
