import { Box, FormControl, type FormControlProps, InputLabel, type SxProps } from '@mui/material'
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { type Dayjs } from 'dayjs'

interface Props {
  separateLabel?: boolean
  value: Date | null
  minDate?: Date
  name: string
  label?: string
  labelContent?: JSX.Element
  FormControlProps?: FormControlProps
  disablePast?: boolean
  disabled?: boolean
  required?: boolean
  showRequiredText?: boolean
  includeTime?: boolean
  error?: boolean
  dataTestId?: string
  sx?: SxProps
  onChange: (newValue: Date | null) => void
  onBlur?: (e: any) => void
}

export const DatePickerWithLabel = (props: Props): JSX.Element => {
  let date = null
  if (props.value != null) {
    date = dayjs(props.value)
  }

  const handleChange = (newValue: Dayjs | null): void => {
    if (newValue == null) {
      props.onChange(null)
    } else {
      const newDate = new Date(newValue.year(), newValue.month(), newValue.date())
      props.onChange(newDate)
    }
  }

  return <Box>
    { props.separateLabel === true && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <InputLabel
        sx={{ color: 'black' }}
        required={props.required === true && props.showRequiredText !== true}
      >
        {props.labelContent ?? props.label}
      </InputLabel>

      {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
      {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}
    </Box>}

    { props.separateLabel !== true && props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: 'var(-error)' }}>Required</Box>}

    <Box>
      <FormControl id={`${props.name}FormControl`} variant='filled' aria-labelledby={props.name} sx={{ width: '100%' }} {...props.FormControlProps}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          { props.includeTime === true &&
            <DesktopDateTimePicker
              label={props.separateLabel === true ? undefined : props.label}
              minDate={props.minDate != null ? dayjs(props.minDate) : undefined }
              disablePast={props.disablePast}
              data-testid={props.dataTestId}
              disableOpenPicker={props.disabled}
              disabled={props.disabled}
              value={date}
              onChange={handleChange}
              slotProps={{
                textField: {
                  inputProps: {
                    'data-testid': props.dataTestId
                  },
                  name: props.name,
                  required: props.required,
                  error: props.error,
                  onBlur: props.onBlur ?? (() => {})
                }
              }}
            />
          }
          { props.includeTime !== true &&
            <DesktopDatePicker
              label={props.separateLabel === true ? undefined : props.label}
              minDate={props.minDate != null ? dayjs(props.minDate) : undefined }
              disablePast={props.disablePast}
              data-testid={props.dataTestId}
              disableOpenPicker={props.disabled}
              disabled={props.disabled}
              value={date}
              onChange={handleChange}
              slotProps={{
                textField: {
                  inputProps: {
                    'data-testid': props.dataTestId
                  },
                  name: props.name,
                  required: props.required,
                  error: props.error,
                  onBlur: props.onBlur ?? (() => {})
                }
              }}
            />
          }
        </LocalizationProvider>
      </FormControl>
    </Box>
  </Box>
}
