import { createContext, useContext, useEffect, useState } from 'react'
import { type RCNPD } from '../models/RCNPD'
import { GetRCNPDsForPriority } from '../services/AnnualPlanService'

interface TrainingsContextProps {
  priorityId: number
  children: JSX.Element
}

interface TrainingsContextType {
  trainings: RCNPD[]
  isLoading: boolean
  reloadTrainings: () => Promise<void>
}

const TrainingsContext = createContext<TrainingsContextType | undefined>(undefined)

export const TrainingsProvider = (props: TrainingsContextProps): JSX.Element => {
  const [trainings, setTrainings] = useState<RCNPD[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const loadTrainings = async (): Promise<void> => {
    setIsLoading(true)
    const trainings = await GetRCNPDsForPriority(props.priorityId)

    setTrainings(trainings)
    setIsLoading(false)
  }

  useEffect(() => {
    void loadTrainings()
  }, [props.priorityId])

  return <TrainingsContext.Provider value={{ trainings, isLoading, reloadTrainings: loadTrainings }}>
    {props.children}
  </TrainingsContext.Provider>
}

export const useTrainingsContext = (): TrainingsContextType => {
  const context = useContext(TrainingsContext)
  if (context === undefined) {
    throw new Error('useTrainingsContext must be used within a TrainingsProvider')
  }
  return context
}
