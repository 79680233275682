import { Avatar, Box, Button, CircularProgress, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { BlueButton } from '../buttons/BlueButton'
import { type CommentParentType, type CommentModel } from '../../../models/Comment'
import { type AuthUser, useAuth } from '../../../hooks/use-auth'
import './CommentField.css'
import { extractTaggedUserEmails, useTagging } from '../../../hooks/use-comments'

interface CommentFieldProps {
  onSubmit: (comment: CommentModel, taggedUserEmails: string[]) => void
  comment?: CommentModel
  onCancel?: () => void // this also determines if the button is displayed
  disabled?: boolean
  parentId: number
  parentType: CommentParentType
}

export const CommentField = (props: CommentFieldProps): JSX.Element => {
  const [content, setContent] = useState(props.comment?.commentText ?? '')
  const [isFocused, setIsFocused] = useState(false)
  const { user: currentUser } = useAuth()
  const orgId = currentUser?.currentOrgId ?? 0
  const contentEditableRef = useRef<HTMLDivElement>(null)

  const { loading, suggestions, tagging, handleSuggestionClick, handleInput } = useTagging(orgId, contentEditableRef, setContent)

  useEffect(() => {
    // set initial content if editing a comment
    if (props.comment?.commentText != null && (contentEditableRef.current != null)) {
      contentEditableRef.current.innerHTML = props.comment.commentText
    }
  }, [props.comment?.commentText])

  const createCommentModel = (
    commentId: number,
    user: AuthUser,
    content: string,
    isResolved: boolean
  ): CommentModel => {
    return {
      id: commentId,
      userId: user.id,
      commentText: content,
      dateStamp: new Date().toISOString().replace('Z', ''),
      isResolved,
      parentId: props.parentId,
      parentType: props.parentType,
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        imageKey: user.imageKey
      }
    }
  }

  const handleSubmit = (): void => {
    if (currentUser == null) return

    const comment: CommentModel = createCommentModel(
      props.comment?.id ?? 0,
      currentUser,
      content,
      props.comment?.isResolved ?? false
    )

    const taggedUserEmails = extractTaggedUserEmails(content)

    props.onSubmit(comment, taggedUserEmails)
    setContent('')
    if (contentEditableRef.current != null) contentEditableRef.current.innerHTML = ''
  }

  return (
    <Box>
      <div
        data-testid='commentField'
        contentEditable={props.disabled !== true}
        ref={contentEditableRef}
        onInput={handleInput}
        onFocus={() => { setIsFocused(true) }}
        onBlur={() => { if (!tagging) setIsFocused(false) }}
        style={{
          padding: '20px',
          borderRadius: '12px',
          backgroundColor: 'var(--white)',
          border: '1px solid var(--border)',
          minHeight: isFocused ? '80px' : '40px',
          transition: 'min-height 0.3s ease'
        }}
        id='commentField'
        data-placeholder="Please share your thoughts..."
      />

      {tagging && <List dense={true} sx={{ backgroundColor: 'var(--white)', border: '1px solid var(--border)', borderRadius: '12px' }}>
        {loading && <CircularProgress />}
        {!loading && suggestions.length === 0 && <Typography sx={{ p: '8px' }}>No users found matching the search criteria</Typography>}
        {!loading && suggestions.length > 0 && suggestions.map(user => (
          <ListItemButton
            data-testid="userSuggestion"
            key={user.id}
            onClick={() => { handleSuggestionClick(user) }}
            sx={{ ':hover': { backgroundColor: 'var(--blue-50)' } }}
          >
            <ListItemAvatar>
              <Avatar />
            </ListItemAvatar>

            <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
          </ListItemButton>
        ))}
      </List>}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {(props.onCancel != null) &&
          <Button variant='outlined' onClick={props.onCancel}>Cancel</Button>}

        {content !== '' &&
          <BlueButton sx={{ mt: '8px' }} onClick={handleSubmit} disabled={props.disabled}>
            {props.onCancel != null ? 'Save' : 'Submit'}
          </BlueButton>}
      </Box>

    </Box>
  )
}
