import { useEffect, useState } from 'react'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { Modal } from '../../customComponents/Modal'
import { Chip } from '@mui/material'
import { Close } from '@mui/icons-material'
import { type SelectableOption } from '../../../utils/genericTypes'
import { GetBuildingOptionsForOrg, UpdateUserBuildings } from '../../../services/UserManagementService'
import { type User } from '../../../models/User'
import { toast } from 'react-toastify'

interface Props {
  buildings: SelectableOption[]
  user: User
  orgId: number
  onClose: () => void
  onSubmit: () => void
}

export const EditBuildingsModal = (props: Props): JSX.Element => {
  const [refreshToken, setRefreshToken] = useState<number>(0)
  const [buildings, setBuildings] = useState<SelectableOption[]>(props.buildings)
  const [availableBuildings, setAvailableBuildings] = useState<SelectableOption[]>([])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      let buildingsResponse = await GetBuildingOptionsForOrg(props.orgId)
      buildingsResponse = buildingsResponse.filter(b => !props.buildings.some(b2 => b2.id === b.id))
      setAvailableBuildings(buildingsResponse)
    }

    void loadData()
  }, [props.orgId, props.buildings])

  const handleSubmit = async (): Promise<void> => {
    await UpdateUserBuildings(props.user.id.toString(), props.orgId.toString(), buildings.map(b => b.id))

    toast.success('Buildings updated')
    props.onClose()
    props.onSubmit()
  }

  const onBuildingSelected = (selectedBuilding: SelectableOption): void => {
    const tmpBuildings = [...buildings, selectedBuilding].sort((a, b) => a.name.localeCompare(b.name))
    setBuildings(tmpBuildings)

    const tmpBuildings2 = [...availableBuildings].filter(b => b.id !== selectedBuilding?.id)
    setAvailableBuildings(tmpBuildings2)

    setRefreshToken(refreshToken + 1)
  }

  const onBuildingRemoved = (removedBuilding: SelectableOption): void => {
    const tmpBuildings = [...buildings].filter(b => b.id !== removedBuilding.id)
    setBuildings(tmpBuildings)

    const tmpBuildings2 = [...availableBuildings, removedBuilding].sort((a, b) => a.name.localeCompare(b.name))
    setAvailableBuildings(tmpBuildings2)
  }

  return <Modal
    title='Edit Buildings'
    open={true}
    confirmButtonText='Save Buildings'
    onClose={props.onClose}
    onConfirm={handleSubmit}
    bodyContent={
      <>
        <MUIAutoComplete
          key={refreshToken} // Force the field to clear after selecting a building
          label='Buildings'
          name='buildings'
          separateLabel={true}
          placeholder='Select A Building'
          options={availableBuildings}
          onChange={(_e, value) => { onBuildingSelected(value as SelectableOption) }}
        />

        {buildings.map((building) => (
          <Chip
            key={building.id}
            label={building.name}
            sx={{ color: 'black', backgroundColor: 'var(--blue-300)', fontSize: '14px', my: '.25em', mr: '.25em' }}
            onDelete={() => { onBuildingRemoved(building) }}
            deleteIcon={<Close sx={{ fill: 'black' }} data-testid={`remove-${building.name}`} />}
          />
        ))}
      </>
    }
  />
}
