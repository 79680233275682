import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, type SelectChangeEvent, TextField, Typography } from '@mui/material'
import { Modal } from '../../customComponents/Modal'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { AttachMoney } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { AddRCNPD, GetIsdsAndDistrictsForPlanOrganization, UpdateRCNPD } from '../../../services/AnnualPlanService'
import { type OrganizationOption } from '../../../models/requestObjects/CommonObjects'

enum TrainingFormatOptions {
  InPerson = 'In-Person',
  Virtual = 'Virtual',
  Hybrid = 'Hybrid',
  OnDemand = 'On-Demand'
}

export interface AddTrainingFormValues {
  priorityId: number
  title: string
  organizationIds: number[]
  trainingFormat: string | undefined
  scope: string
  open: string
  resourcesNeeded: string
  budgetRequest: string
  isSeries: boolean
}

export interface UpdateTrainingFormValues extends AddTrainingFormValues {
  id: number
}

const MENU_ITEM_HEIGHT = 48
const MENU_ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const useFormValues = (initialValues?: UpdateTrainingFormValues, priorityId?: number, isSeries?: boolean): [AddTrainingFormValues, Dispatch<SetStateAction<AddTrainingFormValues>>, boolean] => {
  const [formValues, setFormValues] = useState<AddTrainingFormValues>({
    priorityId: priorityId ?? 0,
    title: '',
    organizationIds: [],
    trainingFormat: undefined,
    scope: '',
    open: '',
    resourcesNeeded: '',
    budgetRequest: '',
    isSeries: isSeries ?? false
  })

  useEffect(() => {
    if (initialValues != null) {
      setFormValues(initialValues)
    }
  }, [initialValues])

  const hasInvalidFormValues = Object.values(formValues).some((value) => value === '' || value == null)

  return [formValues, setFormValues, hasInvalidFormValues]
}

const useOrganizationOptions = (rcnId: number): OrganizationOption[] => {
  const [isdDistrictOptions, setIsdDistrictOptions] = useState<OrganizationOption[]>([])

  useEffect(() => {
    const getOrgOptions = async (): Promise<void> => {
      const isdDistrictOptions = await GetIsdsAndDistrictsForPlanOrganization(rcnId)
      setIsdDistrictOptions(isdDistrictOptions)
    }

    void getOrgOptions()
  }, [rcnId])

  return isdDistrictOptions
}

interface Props {
  isSeries?: boolean
  open: boolean
  onClose: () => void
  onSaved: () => void
  initialValues?: UpdateTrainingFormValues
  rcnId: number
  priorityId: number
}

export const AddEditTrainingModal = (props: Props): JSX.Element => {
  const [formValues, setFormValues, hasInvalidFormValues] = useFormValues(props.initialValues, props.priorityId, props.isSeries)
  const isdDistrictOptions = useOrganizationOptions(props.rcnId)
  const [submitted, setSubmitted] = useState(false)

  const handleFieldUpdate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleAutoCompleteUpdate = (e: React.SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    if (value != null) {
      setFormValues({ ...formValues, trainingFormat: value.name })
    }
  }

  const handleSubmit = async (): Promise<void> => {
    setSubmitted(true)
    if (hasInvalidFormValues) {
      toast.error('Please fill out required fields')
      return
    }
    if (props.initialValues != null) {
      const { success, errors } = await UpdateRCNPD({ ...formValues, id: props.initialValues.id })
      if (!success) {
        errors.forEach((error) => toast.error(error))
        return
      }
    } else {
      const { success, errors } = await AddRCNPD(formValues)
      if (!success) {
        errors.forEach((error) => toast.error(error))
        return
      }

      toast.success(props?.isSeries === true ? 'Training Series Saved' : 'Single Training Event Saved')
    }

    props.onClose()

    props.onSaved()
  }

  const renderOrgNames = (orgIds: number[]): string[] => {
    return orgIds.map((orgId) => {
      const org = isdDistrictOptions.find((option) => option.id === orgId)
      return org?.name ?? ''
    })
  }

  return <Modal
    title={`${((props?.initialValues) != null) ? 'Edit' : 'Add'} ${props?.isSeries === true ? 'Training Series' : 'Single Training Event'}`}
    open={props.open}
    data-testid={props?.initialValues != null ? 'editTrainingModal' : 'addTrainingModal'}
    maxWidth="xs"
    confirmButtonText="Save"
    cancelButtonText="Cancel"
    onClose={props.onClose}
    onConfirm={handleSubmit}
    bodyContent={
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography fontWeight={600}>Training Info</Typography>

        <TextField
          name='title'
          label={props?.isSeries === true ? 'Series Title' : 'Training Title'}
          value={formValues.title}
          data-testid='title'
          onChange={handleFieldUpdate}
          variant="outlined"
          required={formValues.title === ''}
          error={submitted && formValues.title === ''}
        />

        <FormControl
          required={formValues.organizationIds.length === 0}
          error={submitted && formValues.organizationIds.length === 0}
        >
          <InputLabel id="isdDistrictLabel">ISD/District</InputLabel>
          <Select
            labelId="isdDistrictLabel"
            id="isdDistrict"
            name='organizationIds'
            multiple
            value={formValues.organizationIds}
            onChange={handleFieldUpdate as (e: SelectChangeEvent<typeof formValues.organizationIds>) => void}
            input={<OutlinedInput label="ISD/District" />}
            renderValue={renderOrgNames}
            MenuProps={MenuProps}
            data-testid='isdDistrict'
          >
            {isdDistrictOptions.length === 0 && <Typography textAlign={'center'}>No options</Typography>}
            {isdDistrictOptions.length > 0 && isdDistrictOptions.map((org) => (
              <MenuItem key={org.id} value={org.id} data-testid='isdDistrictOption'>
                <Checkbox checked={formValues.organizationIds.includes(org.id)} />
                <ListItemText primary={org.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <MUIAutoComplete
          dataTestId='trainingFormat'
          options={Object.values(TrainingFormatOptions).map((value) => ({ id: value, name: value }))}
          value={formValues.trainingFormat != null ? { id: formValues.trainingFormat, name: formValues.trainingFormat } : undefined}
          onChange={handleAutoCompleteUpdate}
          name='trainingFormat'
          label='Training Format'
          required={formValues.trainingFormat == null}
          error={submitted && formValues.trainingFormat == null}
        />

        <FormControl
          data-testid='scope'
          required={formValues.scope === ''}
          error={submitted && formValues.scope === ''}
        >
          <FormLabel>Will this be an individual or team-based training?</FormLabel>
          <RadioGroup
            aria-labelledby="scope-radio-buttons-group"
            name="scope"
            value={formValues.scope}
            onChange={handleFieldUpdate}
          >
            <FormControlLabel data-testid='scopeIndividual' value="Individual" control={<Radio />} label="Individual" />
            <FormControlLabel data-testid='scopeTeamBased' value="Team-based" control={<Radio />} label="Team-based" />
          </RadioGroup>
        </FormControl>

        <FormControl
          data-testid='open'
          required={formValues.open === ''}
          error={submitted && formValues.open === ''}
        >
          <FormLabel>If this training is open to other participants may we share it on the START website?</FormLabel>
          <RadioGroup
            aria-labelledby="open-radio-buttons-group"
            name="open"
            value={formValues.open}
            onChange={handleFieldUpdate}
          >
            <FormControlLabel data-testid='openYes' value={true} control={<Radio />} label="Yes" />
            <FormControlLabel data-testid='openNo' value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <TextField
          name='resourcesNeeded'
          label='Resources Needed'
          value={formValues.resourcesNeeded}
          data-testid='resourcesNeeded'
          onChange={handleFieldUpdate}
          variant="outlined"
          multiline={true}
          rows={4}
          required={formValues.resourcesNeeded === ''}
          error={submitted && formValues.resourcesNeeded === ''}
        />

        <FormControl sx={{ width: 'fit-content' }} variant="outlined">
          <InputLabel htmlFor="budget-request">Budget Request</InputLabel>
          <OutlinedInput
            id="budget-request"
            startAdornment={
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            }
            name='budgetRequest'
            label="Budget Request"
            value={formValues.budgetRequest}
            data-testid='budgetRequest'
            onChange={handleFieldUpdate}
            required={formValues.budgetRequest === ''}
            error={submitted && formValues.budgetRequest === ''}
          />
        </FormControl>
      </Box>
    }
  />
}
