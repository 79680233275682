import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, ListItemText, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useEffect, useState } from 'react'

export interface WorkflowStep {
  id: number
  name: string
  skipped?: boolean
  steps?: WorkflowStep[]
}

interface Props {
  initialStep?: number
  steps: WorkflowStep[]
  onStepClick: (id: number) => void
}

export const WorkflowNavigation = (props: Props): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(props.initialStep)
  const [activeSubIndex, setActiveSubIndex] = useState<number | undefined>()

  useEffect(() => {
    setActiveIndex(props.initialStep)
  }, [props.initialStep])

  const handleStepClick = (id: number, index: number, subStepIndex?: number): void => {
    setActiveIndex(index)
    setActiveSubIndex(subStepIndex)
    props.onStepClick(id)
  }

  return <Box>
    {props.steps.map((step, index) => (
      <Box key={index}>
        {(step.steps?.length ?? 0) > 0 &&
          <Accordion
            sx={{
              boxShadow: 'none',
              '&:before': { display: 'none' },
              backgroundColor: activeIndex === index ? 'var(--blue-50)' : ''
            }}
          >
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
              <Typography sx={{ fontWeight: activeIndex === index ? '600' : '' }}>{step.name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Box>
                {step.steps?.map((subStep, subStepIndex) => (
                  <ListItem
                    key={subStepIndex}
                    onClick={() => { handleStepClick(subStep.id, index, subStepIndex) }}
                    sx={{
                      borderRadius: '0.25em',
                      cursor: 'pointer',
                      backgroundColor: activeIndex === index && activeSubIndex === subStepIndex ? 'var(--blue-700)' : '',
                      color: activeIndex === index && activeSubIndex === subStepIndex ? 'white' : ''
                    }}
                  >
                    <ListItemText primary={subStep.name} />
                  </ListItem>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        }

        {(step.steps?.length ?? 0) === 0 &&
          <AccordionSummary
            data-testid={`workflowStep-${index}`}
            onClick={() => { handleStepClick(step.id, index) }}
            sx={{ backgroundColor: activeIndex === index ? 'var(--blue-50)' : '' }}
          >
            <Typography sx={{ textDecoration: step.skipped === true ? 'line-through' : '' }}>{step.name}</Typography>
          </AccordionSummary>
        }
      </Box>
    ))}
  </Box>
}
