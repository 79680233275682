import DataTable from '../../customComponents/dataTable/DataTable'
import { Column, ColumnSort, ColumnType } from '../../customComponents/dataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { useEffect, useState } from 'react'
import { type MiDXStaffModel } from '../../../models/MiDXStaffModel'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { Box, Chip, Container, Grid, Typography } from '@mui/material'
import { IconWithLabel } from '../../customComponents/IconWithLabel'
import { Autorenew } from '@mui/icons-material'
import { Colors } from '../../../utils/colors'
import { GetMiDXStaff, GetOnboardingDetails, VerifyMiDXConnection } from '../../../services/MiDXService'
import { OnboardingSummary } from '../../customComponents/organizations/OnboardingSummary'
import { type OnboardingTask } from '../../../models/OnboardingTask'
import { ToggleOnboardingTask } from '../../../services/OrganizationService'
import { Modal } from '../../customComponents/Modal'
import { toast } from 'react-toastify'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const nameColumn = new Column('name', 'Staff Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = (row: MiDXStaffModel) => {
  return <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <ProfileImage name={row.name} />
    <Box sx={{ ml: '.5em' }}>{row.name}</Box>
  </Box>
}

const associationsColumn = new Column('hasAssociations', 'Associations', ColumnType.CUSTOM)
associationsColumn.customCellGenerator = (row: MiDXStaffModel) => {
  const color = row.hasAssociations ? Colors.ACTIVE_TEXT : Colors.INACTIVE_TEXT
  const backgroundColor = row.hasAssociations ? Colors.ACTIVE_BACKGROUND : Colors.INACTIVE_BACKGROUND
  const border = `1px solid ${row.hasAssociations ? Colors.ACTIVE_BORDER : Colors.INACTIVE_BORDER}`

  return <Chip label={row.hasAssociations ? 'Present' : 'Missing'} sx={{ color, backgroundColor, border, fontWeight: 'bold' }} />
}

const emailColumn = new Column('email', 'Email', ColumnType.CUSTOM)
emailColumn.customCellGenerator = (row: MiDXStaffModel) => {
  if (row.email == null || row.email === '') {
    return <IconWithLabel icon={<ErrorOutlineIcon />} labelText='Not Present' />
  }

  return <>{row.email}</>
}

const columns = [nameColumn, associationsColumn, emailColumn]

interface Props {
  orgId: number
  onMidxVerified: () => void
}

export const OrganizationConfiguration = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [staff, setStaff] = useState<MiDXStaffModel[]>([])
  const [lastChecked, setLastChecked] = useState<Date>(new Date())
  const [onboardingTasks, setOnboardingTasks] = useState<OnboardingTask[]>([])
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false)
  const [isConfirmingVerification, setIsConfirmingVerification] = useState<boolean>(false)

  const paginationProps: PaginationProps<MiDXStaffModel> = {
    rows: staff
  }
  const pagination = usePagination(paginationProps)

  const fetchStaff = async (): Promise<void> => {
    setIsLoading(true)

    const staffResponse = await GetMiDXStaff(props.orgId.toString())
    setStaff(staffResponse)
    setLastChecked(new Date())

    setIsLoading(false)
  }

  const fetchOnboardingDetails = async (): Promise<void> => {
    const onboarding = await GetOnboardingDetails(props.orgId.toString())
    setOnboardingTasks(onboarding.tasks)
    setOnboardingComplete(onboarding.onboardingComplete)
  }

  const loadData = async (): Promise<void> => {
    await Promise.all([
      fetchOnboardingDetails(),
      fetchStaff()
    ])
  }

  useEffect(() => {
    void loadData()
  }, [])

  const verifyCompletion = async (): Promise<void> => {
    if (staff.length === 0) {
      setIsConfirmingVerification(true)
      return
    }

    await confirmVerification()
  }

  const confirmVerification = async (): Promise<void> => {
    await VerifyMiDXConnection(props.orgId)
    await fetchOnboardingDetails()
    toast.success('MiDX verification complete')
    setIsConfirmingVerification(false)
    props.onMidxVerified()
  }

  const toggleTask = async (taskKey: string): Promise<void> => {
    await ToggleOnboardingTask(props.orgId.toString(), taskKey)
    await fetchOnboardingDetails()
  }

  const lastCheckedString = new Date(lastChecked).toLocaleString([], { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })

  return <Container sx={{ m: 0 }}>
    <Grid container spacing={5}>
      <Grid item>
        <Box sx={{ display: 'flex', mb: '40px', alignItems: 'center' }}>
          <Typography variant='h6'>MiDX Data</Typography>
          <Autorenew sx={{ mx: '.5em', cursor: 'pointer' }} onClick={fetchStaff} />
          <Typography variant='body2'>Last Checked: {lastCheckedString}</Typography>
        </Box>

        <Box sx={{ width: 'fit-content' }}>
          <DataTable
            name='staff'
            rows={pagination.internalRows}
            columns={columns}
            page={pagination.page}
            totalRecords={pagination.recordCount}
            loading={isLoading}
            initialColumnSorts={[new ColumnSort('name')]}
            onSortChange={(col, sorts) => { pagination.onSortChange(col, sorts[0].order) }}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          />
        </Box>
      </Grid>

      <Grid item sx={{ minWidth: '300px' }}>
        <OnboardingSummary
          onboardingComplete={onboardingComplete}
          readonly={onboardingComplete}
          tasks={onboardingTasks}
          resources={[]}
          completeOnboardingText='Complete MiDX Verification'
          title='MiDX Onboarding Process'
          toggleTask={toggleTask}
          verifyCompletion={verifyCompletion}
        />
      </Grid>
    </Grid>

    {isConfirmingVerification &&
      <Modal
        open={true}
        title='No Staff'
        cancelButtonText='No, Cancel'
        confirmButtonText='Yes, Continue'
        bodyContent='There were not any staff that were successfully pulled from MiDX. Are you sure you want to complete verification?'
        onClose={() => { setIsConfirmingVerification(false) }}
        onConfirm={confirmVerification}
      />
    }
  </Container>
}
