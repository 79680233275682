import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { GetAnnualPlanOptions } from '../../../services/AnnualPlanService'
import { AnnualPlanOptionCard } from './AnnualPlanOptionCard'
import { CircularProgress } from '@mui/material'
import type { AnnualPlanOption } from '../../../models/requestObjects/AnnualPlanResponseObjects'
// import { useState } from 'react'
// import { ToggleCommentsButton } from '../../customComponents/comments/ToggleCommentsButton'
// import { Comments } from '../../customComponents/comments/Comments'
// import { CommentParentType } from '../../../models/Comment'

interface AnnualPlanListProps {
  rcnId: number
}

export function AnnualPlanList (props: AnnualPlanListProps): JSX.Element {
  const [loading, setLoading] = useState(true)
  // const [showComments, setShowComments] = useState(false)
  // function handleShowComments (): void {
  //   setShowComments((show) => !show)
  // }

  const [annualPlans, setAnnualPlans] = useState<AnnualPlanOption[]>([])

  useEffect(() => {
    if (props.rcnId == null) {
      return
    }

    const fetchData = async (): Promise<void> => {
      const plans = await GetAnnualPlanOptions(props.rcnId)
      setAnnualPlans(plans)
      setLoading(false)
    }

    void fetchData()
  }, [props.rcnId])

  if (loading) {
    return <CircularProgress />
  }

  return <Box>
    {annualPlans?.map((planOption) =>
      <AnnualPlanOptionCard key={planOption.id} rcnId={props.rcnId} planOption={planOption} />)}

    {/* <ToggleCommentsButton onClick={handleShowComments} />

    {showComments &&
      <Comments
        onClose={handleShowComments}
        parentType={CommentParentType.PlanOrganizationTask}
        parentId={1}
      />} */}
  </Box>
}
