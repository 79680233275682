export interface CommentModel {
  id: number
  userId: number
  commentText: string
  dateStamp: string
  isResolved: boolean
  parentId: number
  parentType: CommentParentType
  user?: CommentUser
}

export enum CommentParentType {
  PlanOrganizationTask = 'PlanOrganizationTask',
  Comment = 'Comment'
}

interface CommentUser {
  id: number
  firstName: string
  lastName: string
  imageKey?: string
}

export interface NewCommentValues {
  parentId: number
  parentType: CommentParentType
  commentText: string
  taggedUserEmails: string[]
}

export interface NewPlanOrgTaskCommentValues {
  planOrganizationTaskId: number
  commentText: string
  taggedUserEmails: string[]
}

export interface UpdateCommentValues {
  id: number
  commentText: string
  isResolved: boolean
}

export interface TaskCommentGroupModel {
  taskId: number
  taskName: string
  comments: CommentModel[]
}

export interface OrganizationCommentGroupModel {
  organizationId: number
  organizationName: string
  taskGroups: TaskCommentGroupModel[]
}

export interface RecentCommentsResponseModel {
  annualPlanId: number
  organizations: OrganizationCommentGroupModel[]
}
