import { useEffect, useState } from 'react'
import type { SelectableOption } from '../../utils/genericTypes'
import { Box, Chip, type SxProps } from '@mui/material'
import MUIAutoComplete from './MUIAutoComplete'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  availableOptions: SelectableOption[]
  currentSelection: SelectableOption[]
  labelNoneSelected?: string | undefined
  labelWithSelected?: string | undefined
  separateLabel?: boolean
  label?: string
  labelContent?: JSX.Element
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  name: string
  placeholder?: string
  autoCompletSX?: SxProps
  chipSX?: SxProps
  disabled?: boolean
  dataTestId?: string
  onUpdateSelection: (selection: SelectableOption[]) => void
}

export default function MultiSelectWithChip (props: Props): JSX.Element {
  const [options, setOptions] = useState<SelectableOption[]>(props.availableOptions)
  const [selectedOptions, setSelectedOptions] = useState<SelectableOption[]>(props.currentSelection)
  const [label, setLabel] = useState(props.label)

  useEffect(() => {
    const currentIds: number[] = props.currentSelection.map((item) => { return item.id })
    const availOptions: SelectableOption[] = props.availableOptions.filter(element => !currentIds.includes(element.id))
    setOptions(availOptions)
    if (currentIds.length > 0 && props.labelWithSelected != null) {
      setLabel(props.labelWithSelected)
    } else if (currentIds.length === 0 && props.labelNoneSelected != null) {
      setLabel(props.labelNoneSelected)
    }
  }, [props.availableOptions, props.currentSelection])

  const onOptionSelected = (selectedItem: SelectableOption): void => {
    const selectedOptionsCopy = [...selectedOptions, selectedItem].sort((a, b) => a.name.localeCompare(b.name))
    setSelectedOptions(selectedOptionsCopy)

    const availableOptionsCopy = [...options].filter(planAdmin => planAdmin.id !== selectedItem?.id)
    setOptions(availableOptionsCopy)

    props.onUpdateSelection(selectedOptionsCopy)
  }

  const onOptionRemoved = (removedItem: SelectableOption): void => {
    const selectedOptionsCopy = [...selectedOptions].filter(isd => isd.id !== removedItem.id)
    setSelectedOptions(selectedOptionsCopy)

    const availableContributorsLeft = [...options, removedItem].sort((a, b) => a.name.localeCompare(b.name))
    setOptions(availableContributorsLeft)

    props.onUpdateSelection(selectedOptionsCopy)
  }

  return <Box>
    <MUIAutoComplete
      disabled={props.disabled}
      dataTestId={props.dataTestId}
      required={props.required}
      showRequiredText={props.showRequiredText}
      label={label}
      labelContent={props.labelContent}
      error={props.error}
      key={`ac-${selectedOptions.length}`}
      showLabelAndPlaceHolder={true}
      name={props.name}
      sx={{ my: '.5em', mr: '.25em', ...props.autoCompletSX }}
      separateLabel={props.separateLabel}
      placeholder={props.placeholder }
      options={options}
      onChange={(_e, value) => { onOptionSelected(value as SelectableOption) }}
    />

    {selectedOptions.map((option) => (
      <Chip
        key={option.id}
        data-testid={`${option.id}chip-${props.dataTestId ?? ''}`}
        label={option.name}
        disabled={props.disabled}
        sx={{ color: 'white', backgroundColor: 'var(--blue-500)', fontSize: '14px', my: '.5em', mr: '.25em', ...props.chipSX }}
        onDelete={() => { onOptionRemoved(option) }}
        deleteIcon={<CloseIcon sx={{ fill: 'white' }} data-testid={`remove-${option.name}`} />}
      />
    ))}
  </Box>
}
