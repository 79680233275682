import { type SxProps } from '@mui/material'
import { BlockButton } from './BlockButton'

interface Props {
  disabled?: boolean
  sx?: SxProps
  dataTestId?: string
  children: React.ReactNode
  onClick: () => void
}

export const PurpleButton = (props: Props): JSX.Element => {
  return <BlockButton
    disabled={props.disabled}
    onClick={props.onClick}
    dataTestId={props.dataTestId ?? 'block-button'}
    color='white'
    hover={{
      color: 'white',
      background: 'linear-gradient(96.34deg, #120918 0%, #13091a 7.81%, #4e2e63 100%)'
    }}
    sx={{
      ...props.sx,
      background: 'linear-gradient(96.34deg, #23112F 0%, #291436 7.81%, var(--purple-500) 100%)'
    }}
    disabledSx={{ color: '#dddddd' }}
  >
    {props.children}
  </BlockButton>
}
