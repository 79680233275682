import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { AddCircle, CalendarMonth, ExpandCircleDown } from '@mui/icons-material'
import { AddEditTrainingModal } from './AddEditTrainingModal'
import { toast } from 'react-toastify'
import { BannerModal } from '../../customComponents/BannerModal'
import { BasicTable, type BasicTableColumn } from './BasicTable'
import { useTrainingDeletion, useTrainingModal, useTrainings } from '../../../hooks/use-trainings'

interface Props {
  rcnId: number
  priorityId: number
}

export const TrainingSeriesList = (props: Props): JSX.Element => {
  const {
    handleSavedTraining,
    isLoading,
    seriesTrainings
  } = useTrainings()
  const {
    trainingToDelete,
    setTrainingToDelete,
    handleDeleteTraining
  } = useTrainingDeletion()

  const {
    showTrainingModal,
    initialValues,
    handleEditTraining,
    handleCloseModal,
    handleAddTraining
  } = useTrainingModal()

  const handleThingsToConsider = (): void => {
    toast.error('This function is not currently connected')
  }

  const columns: BasicTableColumn[] = [
    { id: 'title', label: 'Title', minWidth: 175 },
    { id: 'organizationNames', label: 'ISD/District', minWidth: 175 },
    { id: 'trainingFormat', label: 'Format', minWidth: 90 },
    { id: 'scope', label: 'Scope', minWidth: 90 },
    { id: 'open', label: 'Open', minWidth: 60 },
    { id: 'resourcesNeeded', label: 'Resources', minWidth: 250 },
    { id: 'budgetRequest', label: 'Budget', minWidth: 90 }
  ]

  const rows = seriesTrainings.map(pd => ({
    ...pd, // Pass the entire object for editing
    id: pd.id,
    title: pd.title,
    organizationNames: pd.organizations.map(o => o.name).join(', '),
    trainingFormat: pd.trainingFormat,
    scope: pd.scope,
    open: pd.open ? 'Yes' : 'No',
    resourcesNeeded: pd.resourcesNeeded,
    budgetRequest: `$${pd.budgetRequest}`
  }))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Typography variant='h6'>Training Series</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <BlueButton onClick={handleAddTraining} dataTestId='addTrainingSeriesButton'>
          Add Training Series <AddCircle sx={{ ml: '10px' }} fontSize='small' />
        </BlueButton>

        <Button sx={{ backgroundColor: 'var(--white)', color: 'var(--blue-700)', padding: '10px 24px' }} onClick={handleThingsToConsider}>
          Things to Consider <ExpandCircleDown sx={{ ml: '10px', color: 'var(--grey-700)' }} fontSize='small' />
        </Button>
      </Box>

      <Box sx={{
        border: '1px solid var(--blue-300)',
        backgroundColor: 'var(--white)',
        borderRadius: '8px',
        padding: seriesTrainings.length === 0 ? '24px' : '0',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 10px 10px -8px #3B353E4D'
      }}
      >
        {isLoading
          ? <CircularProgress sx={{ alignSelf: 'center' }} />
          : <>
            {seriesTrainings.length === 0
              ? <><CalendarMonth sx={{ color: 'var(--blue-300)', width: '144px', height: '144px', alignSelf: 'center' }} />

                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                  <Typography variant='h6'>No Training Series Added</Typography>
                  <p>Use the Add New button to add trainings and events you plan to host or attend to your Annual Plan.</p>
                </Box></>
              : <BasicTable
                  dataTestId='trainingSeriesTable'
                  columns={columns}
                  rows={rows}
                  isLoading={isLoading}
                  onEdit={handleEditTraining}
                  onDelete={setTrainingToDelete}
              />}
          </>}
      </Box>

      {showTrainingModal &&
        <AddEditTrainingModal
          isSeries={true}
          open={showTrainingModal}
          onClose={handleCloseModal}
          onSaved={handleSavedTraining}
          rcnId={props.rcnId}
          priorityId={props.priorityId}
          initialValues={initialValues}
        />}

      {trainingToDelete != null &&
        <BannerModal
          title='Are you sure?'
          cancelButtonText='No, Cancel'
          confirmButtonText='Yes, Delete'
          dataTestId='confirmDeleteTrainingModal'
          bodyContent={'Are you sure you want to delete this item?'}
          onClose={() => { setTrainingToDelete(null) }}
          onConfirm={() => { void handleDeleteTraining(trainingToDelete) }}
          maxWidth='xs'
        />}
    </Box>
  )
}
