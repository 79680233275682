import { Textsms } from '@mui/icons-material'
import { IconButton, type IconButtonProps } from '@mui/material'

interface ToggleCommentsProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  iconButtonProps?: IconButtonProps
}

export const ToggleCommentsButton = (props: ToggleCommentsProps): JSX.Element => {
  return (
    <IconButton
      {...props.iconButtonProps}
      data-testid="commentsToggleButton"
      onClick={props.onClick}
      sx={{ backgroundColor: 'var(--white)', ...props.iconButtonProps?.sx }}
    >
      <Textsms sx={{ color: 'var(--blue-700)' }} />
    </IconButton>
  )
}
