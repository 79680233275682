import { Box, Card, Typography } from '@mui/material'
import { UserDetailsView } from '../../../customComponents/user/DetailsView'
import { type User } from '../../../../models/User'
import { WarningCard } from '../../../customComponents/WarningCard'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useState } from 'react'
import { AddContactModal } from './AddContactModal'
import { Modal } from '@oaisd/michdev.components.react'
import { RemoveAdminContact } from '../../../../services/UserManagementService'
import { toast } from 'react-toastify'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'

interface Props {
  primaryOrg: OrganizationDetailsModel
  adminContacts: User[]
  onSave: () => void
}

export const AdminContactCard = (props: Props): JSX.Element => {
  const [showAddContact, setShowAddContact] = useState<boolean>(false)
  const [showDeleteContact, setShowDeleteContact] = useState<boolean>(false)
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null)

  const deleteContact = async (): Promise<void> => {
    if (selectedContactId == null) {
      return
    }

    await RemoveAdminContact(selectedContactId, props.primaryOrg.id)
    toast.success('Admin Contact was removed')
    setShowDeleteContact(false)
    props.onSave()
  }

  const handleDelete = (userId: number): void => {
    setSelectedContactId(userId)
    setShowDeleteContact(true)
  }

  return <>
    <Card sx={{ p: '1.25em', backgroundColor: '#F3F3F380', border: '1px solid var(--border)' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h4' sx={{ fontWeight: '600', fontSize: '1.5em' }}>Admin Contacts</Typography>
        <AddCircleIcon
          sx={{ fontSize: '2em', color: 'var(--blue-700)', cursor: 'pointer', ml: '1em' }}
          onClick={() => { setShowAddContact(true) }}
          data-testid='addAdminContact'
        />
      </Box>

      {props.adminContacts.map(contact => (
        <Box key={contact.id} data-testid='adminContactCardItem' sx={{ my: '.625em' }}>
          <UserDetailsView
            user={contact}
            allowEdit={false}
            allowDelete={true}
            updateUser={() => {}}
            deleteUser={() => { handleDelete(contact.id) }}
          />
        </Box>
      ))}

      {props.adminContacts.length === 0 && <WarningCard message='No active Administrator Contact' />}
    </Card>

    {showAddContact && <AddContactModal
      primaryOrg={props.primaryOrg}
      onClose={() => { setShowAddContact(false) }}
      onSave={props.onSave}
    />}

    {showDeleteContact && <Modal
      open={true}
      title='Remove Admin Contact'
      confirmButtonText='Yes, Remove'
      cancelButtonText='No, Cancel'
      confirmationContent={<Typography>Are you sure you want to remove this contact?</Typography>}
      buttonClassName='modal-button'
      confirmButtonClassName='modal-confirm-button'
      contentSx={{ borderBottom: '1px solid var(--border)' }}
      onClose={() => { setShowDeleteContact(false) }}
      onConfirm={deleteContact}
    />}
  </>
}
