import { Box, Card, CardActions, CardContent, Collapse, IconButton, type SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface AccordionProps {
  color?: string
  icon?: JSX.Element
  expanded: boolean
  expandButtonKey: string
  baseStyle?: SxProps
  accordionSummary: JSX.Element
  accordionDetails: JSX.Element
  showOptions?: boolean
  optionsElement?: JSX.Element
  dataTestId?: string
}

export default function IconAccordion (props: AccordionProps): JSX.Element {
  const [expanded, setExpanded] = useState(false)

  const style = {
    transform: expanded ? 'rotate(180deg)' : '',
    transition: 'transform 150ms ease'
  }

  useEffect(() => {
  }, [])

  const rotate = (): void => {
    setExpanded(!expanded)
  }

  return <Card data-testid={props.dataTestId} sx={{ display: 'flex', ...props.baseStyle }}>
    { props.icon !== null &&
      <Box sx={{ width: '40px', backgroundColor: `${props.color ?? 'var(--white)'}`, alignContent: 'center', justifyContent: 'center' }}>
        { props.icon }
      </Box>
    }
    <Card sx={{ border: 'none', boxShadow: 'none', width: '100%' }}>
      <Box sx={{ margin: '0px', alignContent: 'center' }}>
        <CardContent sx={{ padding: '0 !important', ml: '1em' }}>
          <Box display='flex' sx={{ alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '90%' }}>
              {props.accordionSummary}
            </Box>
            <CardActions sx={{ justifySelf: 'right' }}>
              {props.showOptions === true && props.optionsElement !== null && props.optionsElement}
              <IconButton data-testid={props.expandButtonKey} onClick={rotate}><ExpandMoreIcon style={style}/></IconButton>
            </CardActions>
          </Box>
        </CardContent>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            {props.accordionDetails}
          </CardContent>
        </Collapse>
      </Box>
    </Card>
  </Card>
}
